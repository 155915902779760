import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../utils/ContextProvider";
import GlobalSVG from "../../utils/GlobalSVG";
import LoaderWheel from "../LoaderWheel";
import GlobalFuncs from "../../utils/GlobalFuncs";
import API from "../../utils/API";
import Swal from "sweetalert2";


const ImpactLine = (props) => {

    const context = useContext(AppContext);
    const [edit, setEdit] = useState(props.create ? true : false)
    const [saving, setSaving] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [deviceChannelCatDict, setDeviceChannelCatDict] = useState(false)
    const [filteredDeviceChannelCatDict, setFilteredDeviceChannelCatDict] = useState(false)
    const [impact, setImpact] = useState({ conservation_id: props.activity })
    const [source, setSource] = useState()
    const unitDict = { kwh: "kWh", kva: "kVA", gas: "ft³", water: "L" }
    const typeDict = { kwh: "e_sources", kva: "e_sources", gas: "g_sources", water: "w_sources" }
    const days1 = [{ "1": 'MON' }, { "2": 'TUE' }, { "3": 'WED' }, { "4": 'THU' }]
    const days2 = [{ "5": 'FRI' }, { "6": 'SAT' }, { "0": 'SUN' }]
    const months1 = [{ "0": 'JAN' }, { "1": 'FEB' }, { "2": 'MAR' }, { "3": 'APR' }, { "4": 'MAY' }, { "5": 'JUN' }]
    const months2 = [{ "6": 'JUL' }, { "7": 'AUG' }, { "8": 'SEP' }, { "9": 'OCT' }, { "10": 'NOV' }, { "11": 'DEC' }]

    useEffect(() => {
        if (!context.getBuildingInfo || !props.impact) return
        let tempImpact = null
        if (!props.create) {
            tempImpact = JSON.parse(JSON.stringify(props.impacts.find((e) => e.id === props.impact)))
            if (tempImpact.start_date) tempImpact.start_date = tempImpact.start_date.split("T")[0]
            if (tempImpact.end_date) tempImpact.end_date = tempImpact.end_date.split("T")[0]
            setImpact(tempImpact)
        }

        let tempDeviceChannelCatDict = []
        for (let channel of context.getBuildingInfo.e_channels) {
            tempDeviceChannelCatDict.push({
                type: 'e',
                device_id: channel.device_id,
                channel_name: channel.channel_name,
                channel_id: channel.id,
                category: channel.category
            })
        }
        for (let channel of context.getBuildingInfo.g_channels) {
            tempDeviceChannelCatDict.push({
                type: 'g',
                device_id: channel.device_id,
                channel_name: channel.channel_name,
                channel_id: channel.id,
                category: channel.category
            })
        }
        for (let channel of context.getBuildingInfo.w_channels) {
            tempDeviceChannelCatDict.push({
                type: 'w',
                device_id: channel.device_id,
                channel_name: channel.channel_name,
                channel_id: channel.id,
                category: channel.category
            })
        }
        setDeviceChannelCatDict(tempDeviceChannelCatDict)
        if (tempImpact) {
            let tempSource = context.getBuildingInfo[typeDict[tempImpact.type]].find(e => e.circuit_name && e.circuit_name.trim() === tempImpact['[source]'])
            if (tempSource) setSource(tempSource)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.impact, props.impacts, props.create, context.getBuildingInfo, props.activities])

    useEffect(() => {
        if (!deviceChannelCatDict) return
        let tempList = JSON.parse(JSON.stringify(deviceChannelCatDict))
        if (['kva', 'kwh'].includes(impact.type)) {
            tempList = tempList.filter(e => e.type === 'e')
        } else if (impact.type === 'gas') {
            tempList = tempList.filter(e => e.type === 'g')
        } else if (impact.type === 'water') {
            tempList = tempList.filter(e => e.type === 'w')
        }
        if (impact.device_id) {
            tempList = tempList.filter(e => e.device_id.trim() === impact.device_id.trim())
        }
        if (impact.channel_name) {
            tempList = tempList.filter(e => e.channel_name.trim() === impact.channel_name.trim())
        }
        if (impact.device_id && impact.channel_name) {
            tempList = tempList.filter(e => e.channel_name.trim() === impact.channel_name.trim())
        }
        const seen = {};
        const uniqueArray = [];

        tempList.forEach(item => {
            const key = JSON.stringify(item);
            if (!seen[key]) {
                seen[key] = true;
                uniqueArray.push(item);
            }
        });

        setFilteredDeviceChannelCatDict(uniqueArray)
    }, [deviceChannelCatDict, impact])

    const handleDelete = () => {
        
        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateImpact({ deleted: true }, impact.id).then(async res => {
                    let tempActivities = JSON.parse(JSON.stringify(props.activities))
                    let updatedActivities = tempActivities.map(e => {
                        if (e.id === props.activity) {
                            let updatedImpacts = e.impacts.filter(i => i.id !== impact.id);
                            return { ...e, impacts: JSON.parse(JSON.stringify(updatedImpacts)) };
                        }
                        return e;
                    });
                    await GlobalFuncs.getCalculatedValues(context.getBuildingInfo, updatedActivities)
                    props.setActivities(updatedActivities)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(false)
            }
        });
    }

    const updateImpact = (event) => {
        let value
        if (event.target.name === 'cap_ex') {
            value = parseInt(event.target.value)
        } else if (event.target.name === 'reduction') {
            value = parseFloat(event.target.value)
        } else if (event.target.name === 'type') {
            value = event.target.value
            if (value === 'kva') {
                setImpact(e => ({ ...e, [event.target.name]: event.target.value, frequency: 'Monthly' }))
                return
            }
        } else if (event.target.name === 'days') {
            let tempDays = impact.days ? impact.days.split(",") : []
            if (tempDays.includes(event.target.value)) value = tempDays.filter(e => e !== event.target.value)
            else {
                tempDays.push(event.target.value)
                value = tempDays
            }
            value = value.join(",")
        } else if (event.target.name === 'months') {
            let tempMonths = impact.months ? impact.months.split(",") : []
            if (tempMonths.includes(event.target.value)) value = tempMonths.filter(e => e !== event.target.value)
            else {
                tempMonths.push(event.target.value)
                value = tempMonths
            }
            value = value.join(",")
        } else if (['channel_name'].includes(event.target.name) && event.target.value) {
            let filtered = filteredDeviceChannelCatDict.filter(e => e[event.target.name].trim() === event.target.value.trim())
            if (filtered.length === 1) {
                setImpact(e => ({ ...e, [event.target.name]: event.target.value, category: filtered[0].category, channel_id: filtered[0].channel_id }))
                return
            }
        } else if (event.target.name === 'end_date') {
            if (event.target.value.trim() === '') value = null
            else value = event.target.value.trim()
        } else {
            value = event.target.value.trim()
        }
        setImpact(e => ({ ...e, [event.target.name]: value }))
    }

    const handleSave = () => {
        
        setSaving(true)

        let payload = JSON.parse(JSON.stringify(impact))
        for (let key in payload) {
            if (payload[key] === null  && key !=='end_date') delete payload[key]
        }

        if (props.create) {
            API.createImpact(impact).then(async res => {
                let tempActivities = JSON.parse(JSON.stringify(props.activities))
                let updatedActivities = tempActivities.map(e => {
                    if (e.id === props.activity) {
                        let impacts = e.impacts || []
                        impacts.push(res.data)
                        return { ...e, impacts: JSON.parse(JSON.stringify(impacts)) };
                    }
                    return e;
                });
                await GlobalFuncs.getCalculatedValues(context.getBuildingInfo, tempActivities)
                props.setActivities(updatedActivities);
                setImpact(res.data)
                setEdit(false)
                props.setAdding(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        } else {
            let allowed = ["type", "category", "start_date", "end_date", "area", "cap_ex", "reduction", "frequency", "days", "months", "equipment_info", "observations", "notes", "assigned_to", "[source]", "channel_id"]
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateImpact(payload, impact.id).then(async res => {
                let tempActivities = JSON.parse(JSON.stringify(props.activities))
                let updatedActivities = tempActivities.map(e => {
                    if (e.id === props.activity) {
                        let updatedImpacts = e.impacts.filter(i => i.id !== impact.id);
                        updatedImpacts.push(impact);
                        updatedImpacts.sort((a, b) => a.id - b.id);
                        return { ...e, impacts: JSON.parse(JSON.stringify(updatedImpacts)) };
                    }
                    return e;
                });
                await GlobalFuncs.getCalculatedValues(context.getBuildingInfo, updatedActivities)
                props.setActivities(updatedActivities)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        }
    }

    return (
        <div className="bg-[#ededed] rounded-md w-full p-[12px] mt-[10px]">
            <div className="flex flex-nowrap justify-start items-center relative p-2">
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Type</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background'
                        disabled={!edit}
                        name="type"
                        value={impact.type ? impact.type : ""}>
                        <option value=""> - </option>
                        <option value="kwh">kWh</option>
                        <option value="kva">kVA</option>
                        <option value="gas">Gas</option>
                        <option value="water">Water</option>
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Circuit</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background w-[98px]'
                        disabled={!edit}
                        name="channel_name"
                        value={impact.channel_name ? impact.channel_name : ""}>
                        <option value=""> - </option>
                        {filteredDeviceChannelCatDict && [...new Set(filteredDeviceChannelCatDict.map(i => i.channel_name))].sort().map(name => (
                            <option key={name} value={name.trim()}>{name.trim()}</option>
                        ))}
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Device</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background w-[98px]'
                        disabled={!edit}
                        name="device_id"
                        value={impact.device_id ? impact.device_id : ""}>
                        <option value=""> - </option>
                        {filteredDeviceChannelCatDict && [...new Set(filteredDeviceChannelCatDict.map(i => i.device_id))].map(device => (
                            <option key={device} value={device.trim()}>{device.trim()}</option>
                        ))}
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Category</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background w-[98px]'
                        disabled={!edit}
                        name="category"
                        value={impact.category ? impact.category : ""}>
                        <option value=""> - </option>
                        {filteredDeviceChannelCatDict && [...new Set(filteredDeviceChannelCatDict.map(i => i.category))].map(category => (
                            <option key={category} value={category && category.trim()}>{category && category.trim()}</option>
                        ))}
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Source</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background w-[98px]'
                        disabled={!edit}
                        name="[source]"
                        value={impact["[source]"] ? impact["[source]"] : ""}>
                        <option value=""> - </option>
                        {context.getBuildingInfo && context.getBuildingInfo[typeDict[impact.type]] && context.getBuildingInfo[typeDict[impact.type]].map(e => (
                            <option key={e.circuit_name + "source"} value={e.circuit_name}>{e.circuit_name}</option>
                        ))}
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Area</p>
                    <input
                        type="text"
                        name="area"
                        disabled={!edit}
                        className="text-xs text-[#332D41] outline-none hide-input-background"
                        defaultValue={impact.area ? impact.area : ""}
                        onChange={updateImpact} />
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Start</p>
                    <input
                        type="date"
                        name="start_date"
                        disabled={!edit}
                        className='text-xs input-color outline-none hide-input-background w-[85px]'
                        defaultValue={impact.start_date ? impact.start_date.split('T')[0] : ""}
                        onChange={updateImpact} />
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>End</p>
                    <input
                        type="date"
                        name="end_date"
                        disabled={!edit}
                        min={impact.start_date ? impact.start_date.split('T')[0] : ""}
                        className='text-xs input-color outline-none hide-input-background w-[85px]'
                        defaultValue={impact.end_date ? impact.end_date.split('T')[0] : ""}
                        onInput={updateImpact} />
                </div>
                <div className="absolute top-[0px] right-[0px]">
                    <div className="flex flex-nowrap justify-end items-center">
                        {edit &&
                            (saving ?
                                <LoaderWheel /> :
                                <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                        }
                        {
                            !edit &&
                            <div className='cursor-pointer w-[25px]'>
                                {context.getUserInfo.update_buildings_and_operators &&
                                    (deleting ?
                                        <LoaderWheel /> :
                                        <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete()}>{GlobalSVG.delete()}</div>)
                                }
                            </div>
                        }
                        {
                            !edit &&
                            <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                {context.getUserInfo.update_buildings_and_operators && GlobalSVG.edit('black')}
                            </div>
                        }
                    </div>

                </div>
            </div>
            <div className="flex flex-nowrap justify-start items-start p-2">
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>CapEx</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background'
                        disabled={!edit}
                        name="cap_ex"
                        value={impact.hasOwnProperty('cap_ex') ? impact.cap_ex : ""}>
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{impact.type === 'kva' ? "Peak Reduction" : "Reduction"}</p>
                    <div className="flex flex-nowrap justify-start items-center">
                        <input
                            type="number"
                            name="reduction"
                            step="0.1"
                            disabled={!edit}
                            className="text-xs text-[#332D41] outline-none hide-input-background w-[50px]"
                            defaultValue={impact.reduction ? (edit ? impact.reduction : impact.reduction) : ""}
                            onChange={updateImpact} />
                        <p className="text-xs text-[#332D41] w-[25px] mr-[50px]">{unitDict[impact.type]}</p>
                    </div>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Frequency</p>
                    <select
                        onChange={updateImpact}
                        className='text-xs input-color outline-none hide-input-background'
                        disabled={!edit}
                        name="frequency"
                        value={impact.frequency ? impact.frequency : ""}>
                        <option value=""> - </option>
                        {impact.type !== 'kva' && <option value="Daily">Daily</option>}
                        <option value="Monthly">Monthly</option>
                    </select>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Days</p>
                    <div className="flex flex-nowrap justify-start items-center mb-1">
                        {
                            days1.map(e => (
                                <div key={'days1'+Object.keys(e)[0]} onClick={() => edit && impact.frequency === 'Daily' ? updateImpact({ target: { name: 'days', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${impact.frequency !== 'Daily' ? " bg-[#B5B5C34D] text-[#B8BBB9] border-[#B8BBB9]" : impact.days && impact.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                            ))
                        }
                    </div>
                    <div className="flex flex-nowrap justify-start items-center">
                        {
                            days2.map(e => (
                                <div key={'days2'+Object.keys(e)[0]} onClick={() => edit && impact.frequency === 'Daily' ? updateImpact({ target: { name: 'days', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${impact.frequency !== 'Daily' ? " bg-[#B5B5C34D] text-[#B8BBB9] border-[#B8BBB9]" : impact.days && impact.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Months</p>
                    <div className="flex flex-nowrap justify-start items-center mb-1">
                        {
                            months1.map(e => (
                                <div key={'months1'+Object.keys(e)[0]} onClick={() => edit ? updateImpact({ target: { name: 'months', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${impact.months && impact.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                            ))
                        }
                    </div>
                    <div className="flex flex-nowrap justify-start items-center">
                        {
                            months2.map(e => (
                                <div key={'months2'+Object.keys(e)[0]} onClick={() => edit ? updateImpact({ target: { name: 'months', value: Object.keys(e)[0] } }) : null} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${impact.months && impact.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="mr-[20px]">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#107048]'>Total Reductions</p>
                    <p className="text-[10px] font-semibold">{source ? (GlobalFuncs.getApplicableDates(impact.start_date, impact.end_date, impact.months, impact.days, impact.frequency, false, source, impact).reduction).toLocaleString() + " " + unitDict[impact.type] : "-"}</p>
                    <p className="text-[10px] font-semibold">${impact.priceTotal ? impact.priceTotal.toLocaleString() : 0}</p>
                </div>
            </div>
            <div className="flex flex-nowrap justify-start items-center p-2">
                <div className="mr-[20px] w-1/4">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Equipment Info</p>
                    <textarea
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        type="text"
                        name="equipment_info"
                        disabled={!edit}
                        className="text-xs text-[#332D41] outline-none hide-input-background w-full"
                        defaultValue={impact.equipment_info ? impact.equipment_info : ""}
                        onChange={updateImpact}></textarea>
                </div>
                <div className="mr-[20px] w-1/4">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Observations</p>
                    <textarea
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        type="text"
                        name="observations"
                        disabled={!edit}
                        className="text-xs text-[#332D41] outline-none hide-input-background w-full"
                        defaultValue={impact.observations ? impact.observations : ""}
                        onChange={updateImpact}></textarea>
                </div>
                <div className="mr-[20px] w-1/4">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Other Notes</p>
                    <textarea
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        type="text"
                        name="notes"
                        disabled={!edit}
                        className="text-xs text-[#332D41] outline-none hide-input-background w-full"
                        defaultValue={impact.notes ? impact.notes : ""}
                        onChange={updateImpact}></textarea>
                </div>
                <div className="mr-[20px] w-1/4">
                    <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Assigned To</p>
                    <textarea
                        data-gramm="false"
                        data-gramm_editor="false"
                        data-enable-grammarly="false"
                        type="text"
                        name="assigned_to"
                        disabled={!edit}
                        className="text-xs text-[#332D41] outline-none hide-input-background w-full"
                        defaultValue={impact.assigned_to ? impact.assigned_to : ""}
                        onChange={updateImpact}></textarea>
                </div>
            </div>
        </div>
    )
}

export default ImpactLine