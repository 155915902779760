import './style.css'
import { useContext, useEffect, useState, useRef } from 'react';
import GlobalSVG from '../../utils/GlobalSVG';
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { AppContext } from '../../utils/ContextProvider';
import Accordion2 from '../Accordion2';
import Tooltip from '../ToolTip';

export default function SideBar(props) {

    const location = useLocation();
    const [currentLocation, setCurrentLocation] = useState('login')
    const [hidden, setHidden] = useState(false)
    const [seeSettings, setSeeSettings] = useState(false)
    const [version, setVersion] = useState('0.0.0')
    const [versionsList, setVersionsList] = useState([])
    const [versionVisible, setVersionVisible] = useState(false)
    const [color, setColor] = useState('#ffffff');
    const context = useContext(AppContext);
    const divRef = useRef(null);
    const hiddenItems = ['map', 'settings', 'profile', 'report', 'device', '']
    const noSidebar = ['public', 'login', 'recover', 'passchange', 'unavailable']

    useEffect(() => {
        if (!context.getBuildingInfo) return
        let intervalId;
        if (context.getBuildingInfo.alerts  && (context.getBuildingInfo.alerts.find(e => e.severity === 2)?.count ?? 0) > 0) {
          intervalId = setInterval(() => {
            setColor(prevColor => (prevColor === '#ffffff' ? '#ff0000' : '#ffffff'));
          }, 500);
        } else {
          setColor('#ffffff');
        }
    
        return () => {
          clearInterval(intervalId);
        };
      }, [context.getBuildingInfo]);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities",
            "update_utilities", "access_users_list", "create_users", "update_users",
            "access_roles_list", "create_roles", "update_roles", "access_clients_list",
            "create_clients", "update_clients", "access_buildings_and_operators_list",
            "create_buildings_and_operators", "update_buildings_and_operators"]
        if (access.some(item => context.getUserInfo[item] === true)) {
            setSeeSettings(true)
        } else {
            setSeeSettings(false)
        }

        if (context.versionInfo) {
            let versionsArray = context.versionInfo
            let max = versionsArray[versionsArray.length - 1];
            setVersion(max.id)

            let vlist = context.versionInfo.map(e => ({ title: e.id, subtitle: e.release_date, content: e.content }))
            vlist = vlist.reverse();
            setVersionsList(vlist)
        }
    }, [context.getUserInfo, context.versionInfo])

    useEffect(() => {
        setCurrentLocation(location.pathname.split("/")[1])
        context.setSidebar(false)
        divRef.current.scrollTop = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        if (hiddenItems.includes(currentLocation)) setHidden(true)
        else setHidden(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation])

    return (

        <div className='flex flex-row flex-nowrap h-screen bg-orange-200 w-screen'>
            {versionVisible &&
                <div className="highest-z fixed w-screen h-screen flex flex-nowrap justify-center items-center">
                    <Accordion2 onClick={() => setVersionVisible(false)} content={versionsList} title="Version History" />
                </div>
            }
            {!noSidebar.includes(location.pathname.split("/")[1]) &&
                ((context.getMobile && context.getSidebar) || !context.getMobile) &&
                <div className='relative h-screen mobile:w-10 laptop:w-20 sidebar-width-limiter z-40'>
                    <div className={`${context.getSidebar ? 'mobile:w-screen tablet:w-80 light-green' : 'mobile:w-10 laptop:w-20 dark-green'} z-50 absolute transition-width duration-300 ease-in-out h-screen`}>
                        <div className='flex flex-col justify-between items-stretch h-full mobile:p-6 tablet:p-0'>
                            <div className='flex flex-col justify-between items-center'>
                                <div className={`flex flex-nowrap h-32 items-start justify-center w-full`}>
                                    {!context.getSidebar && <img className='w-full logo-max-width laptop:p-4 mobile:p-2 cursor-pointer mt-2' src={require('../../assets/images/logo_small.png')} alt="" onClick={() => context.setSidebar(true)} />}
                                    {context.getSidebar && <div className='flex flex-nowrap items-center justify-center w-full'>
                                        <img className='w-10/12 p-4' src={require('../../assets/images/logo_full.png')} alt="" />
                                        <div className='rounded-full p-2 v-light-green cursor-pointer' onClick={() => context.setSidebar(false)}>
                                            {GlobalSVG.close()}
                                        </div>
                                    </div>}
                                </div>
                                <div className='flex flex-col w-full'>
                                    <Tooltip text={hidden ? "Select a building to see the home page" : "Home"} show={!context.getSidebar}>
                                        {
                                            hidden ?
                                                <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                    <div className={`${currentLocation === 'home' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                        {GlobalSVG.home("#b3b3b3")}
                                                    </div>
                                                    {context.getSidebar && <p className='text-white ml-2 pb-2'>Home</p>}
                                                </div>
                                                :
                                                <Link to={`/home?building=${context.getBuilding}`} disabled={hidden}>
                                                    <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                        <div className={`${currentLocation === 'home' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                            {GlobalSVG.home('#fdfcf7')}
                                                        </div>
                                                        {context.getSidebar && <p className='text-white ml-2 pb-2'>Home</p>}
                                                    </div>
                                                </Link>
                                        }
                                    </Tooltip>
                                    <Tooltip text={hidden ? "Select a building to see the analysis page" : "Building Analysis"} show={!context.getSidebar}>
                                        {
                                            hidden ?
                                                <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                    <div className={`${currentLocation === 'distribution' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                        {GlobalSVG.distribution("#b3b3b3")}
                                                    </div>
                                                    {context.getSidebar && <p className='text-white ml-2 pb-2'>Building Analysis</p>}
                                                </div>
                                                :
                                                <Link to={`/distribution?building=${context.getBuilding}${currentLocation === 'reports' ? "&refresh=true" : ""}`}>
                                                    <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                        <div className={`${currentLocation === 'distribution' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                            {GlobalSVG.distribution('#fdfcf7')}
                                                        </div>
                                                        {context.getSidebar && <p className='text-white ml-2 pb-2'>Building Analysis</p>}
                                                    </div>
                                                </Link>
                                        }
                                    </Tooltip>
                                    <Tooltip text="Reports" show={!context.getSidebar}>
                                        <Link to={`/report${!hidden ? 's?building=' + context.getBuilding : ''}`}>
                                            <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                <div className={`${currentLocation === 'reports' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                    {GlobalSVG.report()}
                                                </div>
                                                {context.getSidebar && <p className='text-white ml-2 pb-2'>Reports</p>}
                                            </div>
                                        </Link>
                                    </Tooltip>
                                    <Tooltip text={"Conservation Tracker"} show={!context.getSidebar}>

                                        <Link to={`/conservation?building=${context.getBuilding ? context.getBuilding : 'all'}`}>
                                            <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                <div className={`${currentLocation === 'conservation' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                    {GlobalSVG.conservation('#fdfcf7')}
                                                </div>
                                                {context.getSidebar && <p className='text-white ml-2 pb-2'>Conservation Tracker</p>}
                                            </div>
                                        </Link>
                                    </Tooltip>
                                    <Tooltip text={hidden ? "Select a building to see the maintenance page" : "Maintenance Tracker"} show={!context.getSidebar}>
                                        {
                                            hidden ?
                                                <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                    <div className={`${currentLocation === 'maintenance' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                        {GlobalSVG.tools("#b3b3b3")}
                                                    </div>
                                                    {context.getSidebar && <p className='text-white ml-2 pb-2'>Maintenance Tracker</p>}
                                                </div>
                                                :
                                                <Link to={`/maintenance?building=${context.getBuilding}`} disabled={hidden}>
                                                    <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                        <div className={`${currentLocation === 'maintenance' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                            {GlobalSVG.tools('#fdfcf7')}
                                                        </div>
                                                        {context.getSidebar && <p className='text-white ml-2 pb-2'>Maintenance Tracker</p>}
                                                    </div>
                                                </Link>
                                        }
                                    </Tooltip>
                                    {context.getUserInfo && context.getUserInfo.view_alerts && context.isGwUser && <Tooltip text={hidden ? "Select a building to see the alerts page" : "Alerts Interface"} show={!context.getSidebar}>
                                        {
                                            hidden ?
                                                <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full relative`}>
                                                    <div className={`${currentLocation === 'alerts' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                        {GlobalSVG.bell(color)}
                                                    </div>
                                                    {context.getSidebar && <p className='text-white ml-2 pb-2'>Alerts Interface</p>}
                                                </div>
                                                :
                                                <Link to={`/alerts?building=${context.getBuilding}`} disabled={hidden}>
                                                    <div className={`${context.getSidebar ? 'justify-start ' : 'justify-center'} flex flex-nowrap items-center mobile:p-2 laptop:p-4 items-center w-full relative`}>
                                                        {context.getBuildingInfo && context.getBuildingInfo.alerts && !context.getSidebar &&
                                                            <div className="absolute top-[0px] flex flex-nowrap right-[17px] space-x-1">
                                                                <span className="bg-[#F75D5F] text-white text-[8px] font-bold rounded-full h-3 w-3 mt-1 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 2)?.count ?? 0}
                                                                </span>
                                                                <span className="bg-[#F9941E] text-white text-[8px] font-bold rounded-full h-3 w-3 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 1)?.count ?? 0}
                                                                </span>
                                                                <span className="bg-[#0538A0] text-white text-[8px] font-bold rounded-full h-3 w-3 mt-1 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 0)?.count ?? 0}
                                                                </span>
                                                            </div>
                                                        }
                                                        <div className={`${currentLocation === 'alerts' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                            {GlobalSVG.bell(color)}
                                                        </div>
                                                        {context.getSidebar &&
                                                            <div className="flex flex-nowrap top-[0px] flex flex-nowrap items-center right-[17px] space-x-1">
                                                                <p className='text-white mx-2 pb-2'>Alerts Interface</p>
                                                                <div className="bg-[#F75D5F] text-white text-[10px] font-bold rounded-full h-4 w-4 m-2 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 2)?.count ?? 0}
                                                                </div>
                                                                <div className="bg-[#F9941E] text-white text-[10px] font-bold rounded-full h-4 w-4 m-2 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 1)?.count ?? 0}
                                                                </div>
                                                                <div className="bg-[#0538A0] text-white text-[10px] font-bold rounded-full h-4 w-4 m-2 flex items-center justify-center">
                                                                    {context.getBuildingInfo.alerts.find(e => e.severity === 0)?.count ?? 0}
                                                                </div>
                                                            </div>}

                                                    </div>
                                                </Link>
                                        }
                                    </Tooltip>}
                                    <Tooltip text="Map" show={!context.getSidebar}>
                                        <Link to='/map'>
                                            <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-2 laptop:p-4 items-center w-full`}>
                                                <div className={`${currentLocation === 'map' ? 'active-logo' : 'passive-logo'} pb-2`}>
                                                    {GlobalSVG.mapIcon()}
                                                </div>
                                                {context.getSidebar && <p className='text-white ml-2 pb-2'>Map</p>}
                                            </div>
                                        </Link>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className='flex flex-col w-full'>
                                {seeSettings &&
                                    <Tooltip text="Settings" show={!context.getSidebar}>
                                        <Link to='/settings/costsfactors/electricity'>
                                            <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-1 mobile:p-2 laptop:p-4 items-center w-full`}>
                                                <div className={`${currentLocation === 'settings' ? 'active-logo' : 'passive-logo'} pb-2 w-9 flex justify-center`}>
                                                    {GlobalSVG.settings()}
                                                </div>
                                                {context.getSidebar && <p className='text-white ml-2 pb-2'>Settings</p>}
                                            </div>
                                        </Link>
                                    </Tooltip>
                                }
                                <Tooltip text="Profile" show={!context.getSidebar}>
                                    <Link to='/profile'>
                                        <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} flex flex-nowrap mobile:p-1 mobile:p-2 laptop:p-4 items-center w-full`}>
                                            <div className={`${currentLocation === 'profile' ? 'active-logo' : 'passive-logo'} ${context.getSidebar ? 'ml-[6px]' : ''} pb-2`}>
                                                <div className={`w-[24px] h-[24px] rounded-full`}>
                                                    <img className='navbar-image-limiter w-full h-full' src={require('../../assets/images/profile.png')} alt="" />
                                                </div>
                                            </div>
                                            {context.getSidebar && <p className='text-white ml-2 pb-2'>Profile</p>}
                                        </div>
                                    </Link>
                                </Tooltip>
                                <Tooltip text="Sign Out" show={!context.getSidebar}>
                                    <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} cursor-pointer flex flex-nowrap mobile:p-1 mobile:p-2 laptop:p-4 items-center w-full`} onClick={context.logout}>
                                        <div className='passive-logo w-9 flex justify-center'>
                                            {GlobalSVG.signout()}
                                        </div>
                                        {context.getSidebar && <p className='text-white ml-2'>Sign Out</p>}
                                    </div>
                                </Tooltip>
                                <Tooltip text="Version History" show={!context.getSidebar}>
                                    <div className={`${context.getSidebar ? 'justify-start' : 'justify-center'} cursor-pointer flex flex-nowrap mobile:p-1 mobile:p-2 laptop:p-4 items-center w-full`} onClick={() => setVersionVisible(!versionVisible)}>
                                        <div className='passive-logo flex-col justify-center items-center'>
                                            <div className="ml-[7px]">
                                                {GlobalSVG.info()}
                                            </div>
                                            <p className='text-xs font-bold text-center text-[#fff]'>{version}</p>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="flex-1 overflow-y-auto z-30" ref={divRef}>
                {props.children}
            </div>
        </div >
    );
}