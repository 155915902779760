import React, { useState, useRef, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import './style.css';
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import GlobalSVG from '../../utils/GlobalSVG';

function PassChange() {

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [token, setToken] = useState(null);
    const currPassword = useRef();
    const newPassword = useRef();
    const confPassword = useRef();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams && queryParams.get('token')) {
            setToken(queryParams.get('token'))
        }
    }, [location])

    const logIn = (e) => {
        e.preventDefault();
        setError('');
        if (!token && !currPassword.current.value) {
            setError('Missing values');
            return;
        }
        if (!newPassword.current.value) {
            setError('Missing values');
            return;
        }
        if (!confPassword.current.value) {
            setError('Missing values');
            return;
        }

        if (confPassword.current.value !== newPassword.current.value) {
            setError("Passwords don't match");
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        if (token) {
            const payload = { password: newPassword.current.value, token: token }
            API.recoverPut(cancelToken, payload).then(res => {
                setLoading(false);
                Swal.fire({
                    title: 'Updated!',
                    text: 'Password successfully updated.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
                navigate('/login', { replace: false });
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                setLoading(false);
                if (err.response && (err.response.status === 401 || err.response.status === 404)) {
                    setError(err.response.data.error);
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            });
        } else {
            
            const payload = { old_password: currPassword.current.value, password: newPassword.current.value }
            API.changePassword(cancelToken, payload).then(res => {
                setLoading(false);
                Swal.fire({
                    title: 'Updated!',
                    text: 'Password successfully updated.',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
                navigate('/profile', { replace: false });
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                setLoading(false);
                if (err.response && (err.response.status === 401 || err.response.status === 404)) {
                    setError('INVALID');
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            });
        }
        return () => {
            API.cancel(cancelToken);

        }
    }


    return (
        <div className="login-background fixed flex flex-nowrap items-center justify-center z-50 top-0 left-0 w-full h-full bg-white">
            <div className="form-container w-1/2 m-auto tablet:max-w-lg mobile:max-w-full mobile:w-full">
                <img className='w-80 p-4 mobile:visible mx-auto mb-5 tablet:invisible' src={require('../../assets/images/logo_full_green.png')} alt="" />
                <h1 className="font-bold text-center text-3xl mb-4">Log in to Greensense</h1>
                <p className="text-center mb-4 text-s">Don't have an account? <a href="mailto:info@greenwaveinnovations.ca" className="hyperlink cursor-pointer">Get in touch</a></p>
                <form className="px-8 lg:px-10 pt-12">

                    {token === null && <p className="text-xs text-base mb-2">Current Password<span className="text-red">*</span></p>}
                    {token === null && <div className={`flex flex-row flex-nowrap justify-between items-center rounded-3xl border-grey border w-full text-lg m-auto mb-0 ${showPass ? 'show-pass' : 'hide-pass'}`}>
                        <input ref={currPassword} type={showPass ? "text" : "password"} placeholder="Password" className="outline-none rounded-3xl w-full text-lg p-2.5 m-auto mb-0" />
                        <div className="mr-3 cursor-pointer" onClick={() => setShowPass(!showPass)}>
                            {showPass && GlobalSVG.hidePassword()}
                            {!showPass && GlobalSVG.showPassword()}
                        </div>
                    </div>}
                    <p className="text-xs text-base my-2">New Password<span className="text-red">*</span></p>
                    <div className={`flex flex-row flex-nowrap justify-between items-center rounded-3xl border-grey border w-full text-lg m-auto mb-0 ${showPass ? 'show-pass' : 'hide-pass'}`}>
                        <input ref={newPassword} type={showPass ? "text" : "password"} placeholder="New Password" className="outline-none rounded-3xl w-full text-lg p-2.5 m-auto mb-0" />
                        <div className="mr-3 cursor-pointer" onClick={() => setShowPass(!showPass)}>
                            {showPass && GlobalSVG.hidePassword()}
                            {!showPass && GlobalSVG.showPassword()}
                        </div>
                    </div>
                    <p className="text-xs text-base my-2">Confirm Password<span className="text-red">*</span></p>
                    <div className={`flex flex-row flex-nowrap justify-between items-center rounded-3xl border-grey border w-full text-lg m-auto mb-0 ${showPass ? 'show-pass' : 'hide-pass'}`}>
                        <input ref={confPassword} type={showPass ? "text" : "password"} placeholder="Confirm Password" className="outline-none rounded-3xl w-full text-lg p-2.5 m-auto mb-0" />
                        <div className="mr-3 cursor-pointer" onClick={() => setShowPass(!showPass)}>
                            {showPass && GlobalSVG.hidePassword()}
                            {!showPass && GlobalSVG.showPassword()}
                        </div>
                    </div>
                    <p className="forgotPass mt-2 text-right text-xs">
                        <Link className='text-blue-700 text-xs hyperlink' to="/recover">
                            Forgot your password?
                        </Link>
                    </p>
                    {
                        loading === true ?
                            <Loader light={true} />
                            :
                            <input type="submit" className="login-submit rounded-3xl mt-6 bg-blue-600 rounded text-white text-lg p-3 w-full m-auto mb-0 cursor-pointer" onClick={(e) => logIn(e)} value="Submit" />
                    }
                    <p className="text-red text-bold mt-2">
                        {error && error}
                    </p>
                </form>
            </div>
            <div className='login-background-2 h-screen flex flex-col flex-nowrap justify-end items-center mobile:w-0 tablet:w-1/2 py-2.5 text-white relative'>
                <div className="h-4/5">
                    <div className="flex flex-nowrap items-center justify-center h-2/3 p-6">
                        <img className='greensense-logo m-auto' src={require('../../assets/images/greensense-logo.png')} alt="" />
                    </div>
                    <div className="flex flex-col items-center justify-end p-3 h-1/3 text-[#92AC9C]">
                        <div className="ml-2 ">
                            Powered By
                        </div>
                        <img className='greenwave-logo mx-auto mt-2' src={require('../../assets/images/logo_full2.png')} alt="" />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default PassChange;