import React, { useEffect, useState } from 'react';
import './style.css'
import AlertActivitiesSection from '../AlertActivitiesSection';
import AlertLogsSection from '../AlertLogsSection';

const AssetAlerts = (props) => {

    const [showFilters, setShowFilters] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);

    return (
        <div>
            <div className='device tablet:p-8 mobile:p-3'>
                <div className='tablet:p-8 mobile:p-3 min-h-[80vh] mb-6'>
                    <AlertActivitiesSection byCircuit={true} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                    <div className='mb-[40px]'></div>
                    <AlertLogsSection byCircuit={true} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} channelName={props.channelName}/>
                </div>
            </div >
        </div>
    )
}


export default AssetAlerts;