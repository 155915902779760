import React, { useState, useRef, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import './style.css';
import API from "../../utils/API";
import { AppContext } from "../../utils/ContextProvider";
import Swal from "sweetalert2";
import Loader from "../../components/Loader";
import GlobalSVG from '../../utils/GlobalSVG';

function Login() {

    const context = useContext(AppContext);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const userName = useRef();
    const password = useRef();
    const navigate = useNavigate();

    useEffect(()=>{
        if(context.getUserInfo) navigate('/map')
    }, [context.getUserInfo, navigate])

    const logIn = (e) => {
        e.preventDefault();
        setError('');
        if (!userName.current.value) {
            setError('MISSING');
            return;
        }
        if (!password.current.value) {
            setError('MISSING');
            return;
        }
        setLoading(true);
        const cancelToken = API.cancelToken();
        API.logIn(cancelToken, userName.current.value, password.current.value).then(res => {
            setLoading(false);
            context.login(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            setLoading(false);
            if (err.response && (err.response.status === 401 || err.response.status === 404)) {
                setError('INVALID');
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        });
        return () => {
            API.cancel(cancelToken);
        }
    }


    return (
        <div className="login-background fixed flex flex-nowrap items-center justify-center z-50 top-0 left-0 w-full h-full bg-white">
            <div className="form-container w-1/2 m-auto tablet:max-w-lg mobile:max-w-full mobile:w-full">
                <img className='w-80 p-4 mobile:visible mx-auto mb-5 tablet:invisible' src={require('../../assets/images/logo_full_green.png')} alt="" />
                <h1 className="font-bold text-center text-3xl mb-4">Log in to Greensense</h1>
                <p className="text-center mb-4 text-s">Don't have an account? <a href="mailto:info@greenwaveinnovations.ca" className="hyperlink cursor-pointer">Get in touch</a></p>
                <form className="px-8 lg:px-10 pt-12">
                    <p className="text-xs text-base mb-2">Username <span className="text-red">*</span></p>
                    <input ref={userName} type="email" placeholder="example@email.com" className="outline-none rounded-3xl border-grey border w-full text-lg p-2.5 w-full m-auto mb-6" />
                    <p className="text-xs text-base mb-2">Password <span className="text-red">*</span></p>
                    <div className={`flex flex-row flex-nowrap justify-between items-center rounded-3xl border-grey border w-full text-lg m-auto mb-0 ${showPass ? 'show-pass' : 'hide-pass'}`}>
                        <input ref={password} type={showPass ? "text" : "password"} placeholder="Password" className="outline-none rounded-3xl w-full text-lg p-2.5 m-auto mb-0" />
                        <div className="mr-3 cursor-pointer" onClick={() => setShowPass(!showPass)}>
                            {showPass && GlobalSVG.hidePassword()}
                            {!showPass && GlobalSVG.showPassword()}
                        </div>
                    </div>
                    <p className="forgotPass mt-2 text-right text-xs">
                        <Link className='text-blue-700 text-xs hyperlink' to="/recover">
                            Forgot your password?
                        </Link>
                    </p>
                    <div className="login-checkbox flex flex-nowrap items-center justify-start mb-4">
                        <input type="checkbox" name="" />
                        <p className="ml-2">Remember Me</p>
                    </div>
                    <div className="login-checkbox flex flex-nowrap items-center justify-start">
                        <input type="checkbox" name="" />
                        <p className="ml-2">Keep me logged in</p>
                    </div>
                    {
                        loading === true ?
                            <Loader light={true} />
                            :
                            <input type="submit" className="login-submit rounded-3xl mt-6 bg-blue-600 rounded text-white text-lg p-3 w-full m-auto mb-0 cursor-pointer" onClick={(e) => logIn(e)} value="Log in" />
                    }
                    <p className="text-red text-bold mt-2">
                        {error && "Incorrect username and/or password"}
                    </p>
                </form>
            </div>
            <div className='login-background-2 h-screen flex flex-col flex-nowrap justify-end items-center mobile:w-0 tablet:w-1/2 py-2.5 text-white relative'>
                <div className="h-4/5">
                    <div className="flex flex-nowrap items-center justify-center h-2/3 p-6">
                        <img className='greensense-logo m-auto' src={require('../../assets/images/greensense-logo.png')} alt="" />
                    </div>
                    <div className="flex flex-col items-center justify-end p-3 h-1/3 text-[#92AC9C]">
                        <div className="ml-2 ">
                            Powered By
                        </div>
                        <img className='greenwave-logo mx-auto mt-2' src={require('../../assets/images/logo_full2.png')} alt="" />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Login;