import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import AlertSubscriberLine from "../AlertSubscriberLine";

const AlertSubscriptionsModal = (props) => {

    const context = useContext(AppContext);
    const [creating, setCreating] = useState(false)
    const [subscribers, setSubscribers] = useState()
    const [buildingUsers, setBuildingUsers] = useState([])

    useEffect(() => {
        if (!props.subscribers || !buildingUsers) return
        let tempSub = props.subscribers.map(subscriber => ({
            ...subscriber,
            alternate_email: buildingUsers.find(user => user.username === subscriber.subscriber)?.alternate_email,
            primary_tel: buildingUsers.find(user => user.username === subscriber.subscriber)?.primary_tel,
        }));
        setSubscribers(tempSub)
    }, [props.subscribers, buildingUsers])

    useEffect(() => {
        if (!context.getUserInfo || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getUserInfo.manage_others_alert_subscription) {
            ;
            API.getBuildingUsers(cancelToken, context.getBuildingInfo.building_id).then(res => {
                setBuildingUsers(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo, context.getBuildingInfo])

    return (
        <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center'>
            <div className='card w-[50vw] h-[60vh] min-w-[300px] m-2 laptop:p-[20px] tabled:p-[15px] mobile:p-[15px] pt-[0] background-white rounded-md overflow-auto' onClick={(e) => { e.stopPropagation() }}>
                <div className="w-full flex flex-nowrap items-center justify-end">
                    <div className='cursor-pointer' onClick={() => props.setShowSubscriptions(false)}>{GlobalSVG.closeXBig('black')}</div>
                </div>
                <div className="w-full h-[40vh] pt-[15px]">
                    <div className=''>
                        <div className='flex flex-nowrap justify-between p-[5px] w-full min-w-[300px]'>
                            <div className='flex flex-nowrap justify-start items-center w-[160px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>
                                <span>User</span>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[160px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>
                                <span>Email</span>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[90px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>
                                <span>Phone</span>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[90px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>
                                <span>Method</span>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[60px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>
                                <span>Frequency</span>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center w-[60px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'>

                            </div>
                        </div>
                        <div className="h-[40vh] overflow-y-auto">
                            <div className="">
                                {
                                    subscribers && subscribers.map((e, idx) => (
                                        <AlertSubscriberLine key={idx + "subscriber"} subscriber={e} groups={props.groups} groupId={props.groupId} setGroup={props.setGroup} setGroups={props.setGroups} />
                                    ))
                                }
                            </div>
                            <div className="">
                                {
                                    creating &&
                                    <AlertSubscriberLine subscriber={{ alert_group_id: props.groupId }} groups={props.groups} groupId={props.groupId} setGroup={props.setGroup} setGroups={props.setGroups} creating={creating} setCreating={setCreating} buildingUsers={buildingUsers} />
                                }
                            </div>
                        </div>
                        {!creating &&
                            <div className="w-full flex flex-nowrap items-center justify-end">
                                <div className="py-[2px] px-2 bg-[#385B46] text-[white] text-[12px] rounded-[4px] mr-[12px] cursor-pointer font-semibold" onClick={() => setCreating(true)}>+ ADD SUBSCRIBER</div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AlertSubscriptionsModal;