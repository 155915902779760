import React, { useMemo } from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import SlideToggle from '../SlideToggle';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalFuncs from '../../utils/GlobalFuncs';

const EquipmentTab = (props) => {

    const context = useContext(AppContext)
    const [devicePhotos, setDevicePhotos] = useState([])
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        if (!props.selectedChannel || !props.selectedChannel.id || !context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        API.getDevicePictures(cancelToken, props.selectedChannel.id, props.selectedChannel.type).then(res => {
            setDevicePhotos(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            let message = 'Please try again later.'
            if (err.response && err.response.data && err.response.data.includes('error')) {
                message = err.response.data.error
            }
            Swal.fire({
                title: 'Error.',
                text: message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })
    }, [props.selectedChannel, context.getUserInfo])

    const updateChannel = (e) => {
        let { name, value } = e.target;
        if (name && name.includes('phase')) {
            let phase = name.split("_")[1]
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    phases: { ...prevValue.phases, [phase]: value }
                }
            })
        } else if (name === 'images') {
            const selectedImages = e.target.files[0];
            if (selectedImages) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    if ('images' in props.selectedChannel) {
                        props.setSelectedChannel(prevValue => {
                            return {
                                ...prevValue,
                                images: [...prevValue.images, base64ImageData]
                            }
                        })
                    } else {
                        props.setSelectedChannel(prevValue => {
                            return {
                                ...prevValue,
                                images: [base64ImageData]
                            }
                        })
                    }
                };

                reader.readAsDataURL(selectedImages);
            }
        } else if (e.target.type === 'checkbox') {
            const { name, checked } = e.target;
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    [name]: checked
                }
            })
        } else {
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    [name]: value
                }
            })
        }
    }

    useEffect(() => {
        if (props.getPutPost === 'put') props.setSelectedChannel(prev => ({ ...prev, old_name: prev.channel_name }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getPutPost])

    const handleSelectedChannel = (e) => {
        let link = queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
            `/device?device=${props.deviceData.device_id}&tab=equipment&channel=${GlobalFuncs.removeSpecialCharacters(e.currentTarget.value)}&building=${queryParams.get('building')}` :
            `/device?device=${props.deviceData.device_id}&tab=equipment&channel=${GlobalFuncs.removeSpecialCharacters(e.currentTarget.value)}}`
        navigate(link)
    }

    return (
        <div className='device'>
            <div className='min-h-[80vh]'>
                <div className='card tablet:p-8 mobile:p-3 min-h-[70vh] mb-6'>
                    <div className='input-border pb-8 items-center mb-6'>
                        <div className='flex flex-wrap w-full items-center justify-between'>
                            {props.getPutPost !== "get" &&
                                <input
                                    placeholder='Device ID'
                                    className='p-1 text-3xl p-2 outline-none hide-input-background laptop:w-3/12 tablet:w-full mobile:w-full'
                                    type="text"
                                    name='device_id'
                                    disabled={true}
                                    value={props.selectedChannel && props.selectedChannel.channel_name ? props.selectedChannel.channel_name : ""} />
                            }
                            {props.getPutPost === "get" &&
                                <select
                                    className='p-1 laptop:w-4/12 tablet:w-1/2 mobile:w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    name="channel_name"
                                    value={props.selectedChannel && props.selectedChannel.channel_name ? props.selectedChannel.channel_name.trim() : ""}
                                    onChange={handleSelectedChannel}>
                                    <option value="null">No Selection</option>
                                    {props.channelOptions && props.channelOptions.map((e, idx) => (
                                        <option key={idx + 'channeldata'} value={e.channel_name.trim()}>{e.channel_name.trim()}</option>
                                    ))}
                                </select>
                            }
                            {
                                props.error && props.error.channel_name &&
                                <p className='text-red text-xs p-2'>{props.error.channel_name}</p>
                            }
                        </div>
                    </div>
                    {
                        props.loading &&
                        <Loader />
                    }
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap laptop:w-3/5 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='laptop:w-2/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Category</label>
                                {/* <input
                                    placeholder='Category'
                                    className='p-1 laptop:w-9/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                    type="text"
                                    name='category'
                                    onChange={updateChannel}
                                    disabled={props.getPutPost === 'get'}
                                    value={props.selectedChannel && props.selectedChannel.category ? props.selectedChannel.category : ""} /> */}

                                <select
                                    className='p-1 laptop:w-9/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                    name="category"
                                    disabled={props.getPutPost === 'get'}
                                    value={props.selectedChannel.category ? props.selectedChannel.category.trim() : ""}
                                    onChange={updateChannel}>
                                    <option value="null">No Selection</option>
                                    <option value="Cooling">Cooling</option>
                                    <option value="Ventilation">Ventilation</option>
                                    <option value="House Distribution">House Distribution</option>
                                    <option value="Lighting">Lighting</option>
                                    <option value="Source">Source</option>
                                    <option value="Slots & Gaming">Slots & Gaming</option>
                                    <option value="Heating">Heating</option>
                                    <option value="Risk Mitigation">Risk Mitigation</option>
                                    <option value="Distribution Point">Distribution Point</option>
                                    <option value="Riser Distribution">Riser Distribution</option>
                                    <option value="Revenue Stream">Revenue Stream</option>
                                    <option value="Refrigeration">Refrigeration</option>
                                    <option value="Tenant Power">Tenant Power</option>
                                    <option value="NGV Station">NGV Station</option>
                                    <option value="Water Supply & Conditioning">Water Supply & Conditioning</option>
                                    <option value="HVAC">HVAC</option>
                                    <option value="Mixed Use">Mixed Use</option>
                                    <option value="Plug Loads">Plug Loads</option>
                                    <option value="Capacitor Bank">Capacitor Bank</option>
                                    <option value=""></option>
                                    <option value="Building Distribution">Building Distribution</option>
                                    <option value="Industrial Equipment">Industrial Equipment</option>
                                    <option value="Building Level">Building Level</option>
                                </select>
                                {
                                    props.error && props.error.category &&
                                    <p className='text-red text-xs'>{props.error.category}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-2/5 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Circuit Type</label>
                                <select
                                    className='p-1 laptop:w-4/12 tablet:w-1/2 mobile:w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    name="circuit_type"
                                    disabled={props.getPutPost === 'get'}
                                    value={props.selectedChannel.circuit_type ? props.selectedChannel.circuit_type.trim() : ""}
                                    onChange={updateChannel}>
                                    <option value="null">No Selection</option>
                                    <option value="E">E</option>
                                    <option value="G">G</option>
                                    <option value="M">M</option>
                                    <option value="D">D</option>
                                    <option value="S">S</option>

                                </select>
                                {
                                    props.error && props.error.circuit_type &&
                                    <p className='text-red text-xs'>{props.error.circuit_type}</p>

                                }
                            </div>
                        </div>
                    </div>
                    {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                        <div className='flex flex-wrap'>
                            <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Sub-Category</label>
                                    <input
                                        placeholder='Sub-Category'
                                        className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                        type="text"
                                        name='sub_category'
                                        onChange={updateChannel}
                                        disabled={props.getPutPost === 'get'}
                                        value={props.selectedChannel.sub_category ? props.selectedChannel.sub_category : ""} />
                                    {
                                        props.error && props.error.sub_category &&
                                        <p className='text-red text-xs'>{props.error.sub_category}</p>

                                    }
                                </div>
                            </div>
                            <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Equipment Group</label>
                                    <input
                                        placeholder='Equipment Group'
                                        className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                        type="text"
                                        name='equipment_group'
                                        onChange={updateChannel}
                                        disabled={props.getPutPost === 'get'}
                                        value={props.selectedChannel.equipment_group ? props.selectedChannel.equipment_group : ""} />
                                    {
                                        props.error && props.error.equipment_group &&
                                        <p className='text-red text-xs'>{props.error.equipment_group}</p>

                                    }
                                </div>
                            </div>
                            <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Equipment Model</label>
                                    <input
                                        placeholder='Equipment Model'
                                        className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                        type="text"
                                        name='equipment_model'
                                        onChange={updateChannel}
                                        disabled={props.getPutPost === 'get'}
                                        value={props.selectedChannel.equipment_model ? props.selectedChannel.equipment_model : ""} />
                                    {
                                        props.error && props.error.equipment_model &&
                                        <p className='text-red text-xs'>{props.error.equipment_model}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    }
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='laptop:w-2/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color'>End Use Location</label>
                                <input
                                    placeholder='End Use Location'
                                    className='p-1 laptop:w-7/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                    type="text"
                                    name='location'
                                    onChange={updateChannel}
                                    disabled={props.getPutPost === 'get'}
                                    value={props.selectedChannel && props.selectedChannel.location ? props.selectedChannel.location : ""} />
                                {
                                    props.error && props.error.location &&
                                    <p className='text-red text-xs'>{props.error.location}</p>

                                }
                            </div>
                        </div>
                    </div>
                    {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                        <div className='flex flex-wrap'>
                            <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>

                                <div className='laptop:w-3/5 tablet:w-full mobile:w-full flex flex-wrap items-center'>
                                    <label className='w-2/5 text-base input-label-color' htmlFor="operating_load_threshold">Operating Load Threshold</label>
                                    <input
                                        placeholder='Operating Load Threshold'
                                        className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                        type="number"
                                        name="operating_load_threshold"
                                        value={props.selectedChannel.operating_load_threshold ? props.selectedChannel.operating_load_threshold : ""}
                                        disabled={props.getPutPost === 'get'}
                                        onChange={updateChannel} />
                                    {
                                        props.error && props.error.operating_load_threshold &&
                                        <p className='text-red text-xs'>{props.error.operating_load_threshold}</p>
                                    }
                                </div>
                                {/* <div className='laptop:w-2/5 tablet:w-full mobile:w-full flex flex-wrap items-center'>
                                    <label className='w-2/5 text-base input-label-color' htmlFor="average_operating_consumption">Average Operating Consumption</label>
                                    <input
                                        placeholder='Average Operating Consumption'
                                        className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                        type="number"
                                        name="average_operating_consumption"
                                        value={props.selectedChannel.average_operating_consumption ? props.selectedChannel.average_operating_consumption : ""}
                                        disabled={props.getPutPost === 'get'}
                                        onChange={updateChannel} />
                                    {
                                        props.error && props.error.average_operating_consumption &&
                                        <p className='text-red text-xs'>{props.error.average_operating_consumption}</p>
                                    }
                                </div> */}
                                <div className='laptop:w-1/5 tablet:w-full mobile:w-full flex flex-wrap items-center'>
                                    <SlideToggle titleClass="input-label-color" disabled={props.getPutPost === 'get'} setOptions={props.setSelectedChannel} options={props.selectedChannel} id="natural_gas_user" name="Natural Gas User" />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='w-1/12 text-base input-label-color' htmlFor="notes">Notes</label>
                                <input
                                    placeholder='Notes'
                                    className='p-1 laptop:w-10/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                    type="text"
                                    name="notes"
                                    value={props.selectedChannel && props.selectedChannel.notes ? props.selectedChannel.notes : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={updateChannel} />
                                {
                                    props.error && props.error.notes &&
                                    <p className='text-red text-xs'>{props.error.notes}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                            <label className='w-1/12 text-base input-label-color'>Images</label>
                            <div className='w-10/12 flex flex-wrap items-center'>
                                <div className='flex flex-wrap w-full gap-2.5'>
                                    {
                                        props.getPutPost === 'get' && devicePhotos && devicePhotos.map((e, idx) => (
                                            <img key={idx + 'deviceimages'} className='w-20' src={e ? e : require('../../assets/images/placeholder-image.png')} alt="" />
                                        ))

                                    }
                                </div>
                                {props.getPutPost !== 'get' &&
                                    <input
                                        className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="file"
                                        name="images"
                                        onChange={updateChannel} />
                                }
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </div >
    );
};

export default EquipmentTab;