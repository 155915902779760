import LoaderWheel from "../LoaderWheel"
import { useContext, useEffect, useState } from "react"
import { AppContext } from '../../utils/ContextProvider'
import API from "../../utils/API"
import Swal from 'sweetalert2'
import GlobalSVG from "../../utils/GlobalSVG"
import moment from 'moment'

const AlertWorkflowLine = (props) => {

    const context = useContext(AppContext)
    const [saving, setSaving] = useState(false)
    const [note, setNote] = useState("")
    const [edit, setEdit] = useState(false)
    const [workflow, setWorkflow] = useState({})

    useEffect(() => {
        if (!props.workflow) return
        setWorkflow(props.workflow)
    }, [props.workflow])

    const handleUpdate = (e) => {
        setNote(e.target.value)
    }

    const handleSave = (type) => {
        setSaving(true)
        ;
        API.updateWorkflow({ notes: note }, workflow.id).then(res => {
            let tempGroups = JSON.parse(JSON.stringify(props.groups))
            let updatedGroups = tempGroups.map(e => {
                if (e.id === props.log.alert_group_id) {
                    let updatedLogs = e.logs.map(l => {
                        if (l.id === props.log.id) {
                            const workflowIndex = l.workflow.findIndex(w => w.id === workflow.id);
                            if (workflowIndex !== -1) {
                                l.workflow[workflowIndex] = {
                                    ...l.workflow[workflowIndex],
                                    notes: `${l.workflow[workflowIndex].notes ? l.workflow[workflowIndex].notes + "\n" : ""}${context.getUserInfo.firstname} ${context.getUserInfo.lastname}: ${note}`
                                };
                            }
                            return { ...l, workflow: [...l.workflow] };
                        }
                        return l;
                    })
                    props.setGroup({ ...e, logs: updatedLogs })
                    return { ...e, logs: updatedLogs };
                }
                return e
            })
            props.setGroups(updatedGroups)
        }).catch(e => {
            console.log(e)
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            })
        }).finally(f => {
            setSaving(false)
            setEdit(false)
            setNote('')
        })
    }

    return (
        <div className="flex flex-nowrap items-center justify-start mx-[20px] py-[5px] mb-[10px] border-b border-[#B8BBB9]">
            <div className="mr-[20px] w-1/12 ">
                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Assigned To</p>
                <div
                    className='text-xs input-color outline-none'>
                    {workflow.assignee_name ? workflow.assignee_name : " - "}
                </div>
            </div>
            <div className="mr-[20px] w-1/12 ">
                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Assigned By</p>
                <div
                    className='text-xs input-color outline-none'>
                    {workflow.assigned_by_name ? workflow.assigned_by_name : " - "}
                </div>
            </div>
            <div className="mr-[20px] w-2/12 ">
                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Assigned At</p>
                <div
                    className='text-xs input-color outline-none'>
                    {workflow.assigned_at ? moment(workflow.assigned_at).format('MMM D, YYYY h:mma') : " - "}
                </div>
            </div>
            <div className="mr-[20px] w-8/12 ">
                <textarea
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false"
                    type="text"
                    name="description"
                    disabled={!edit}
                    placeholder="Notes"
                    className="text-sm text-[#332D41] w-full outline-none p-[10px] bg-[#F3F3F3]"
                    value={!edit ? workflow.notes : note}
                    onChange={handleUpdate}></textarea>
            </div>
            {edit &&
                (saving ?
                    <LoaderWheel /> :
                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
            }
            {
                !edit &&
                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                    {context.getUserInfo && context.getUserInfo.manage_alert_settings && GlobalSVG.edit('black')}
                </div>
            }
        </div>
    )
}
export default AlertWorkflowLine