import './style.css'
import { useState, useEffect, useRef, useContext } from 'react';
import Loader from "../Loader";
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import moment from 'moment';
import GraphFuncs from '../../utils/GraphFuncs';
import GlobalSVG from '../../utils/GlobalSVG';
import Tooltip from '../ToolTip';
import { AppContext } from "../../utils/ContextProvider";

export default function PortfolioGraph1(props) {

    const context = useContext(AppContext);
    const [options, setOptions] = useState()
    const [currentData, setCurrentData] = useState(null)
    const [totals, setTotals] = useState({ kwh: { e: 0, g: 0 }, co: { e: 0, g: 0 }, price: { e: 0, g: 0 }, eui: { e: 0, g: 0, t: 0 } })
    const [loading, setLoading] = useState(true)
    const chartRef = useRef(null);


    useEffect(() => {
        if (props.circuits === null) return
        setLoading(true)
        let tempSeriesE = []
        let tempSeriesG = []
        let tempSeriesEui = []
        let tempTotal = { kwh: { e: 0, g: 0, t: 0 }, co: { e: 0, g: 0, t: 0 }, price: { e: 0, g: 0, t: 0 }, eui: { e: 0, g: 0, t: 0 } }
        let sortedList = Object.keys(props.circuits)
        sortedList.sort(function (a, b) {
            var itemA = props.circuits[a];
            var itemB = props.circuits[b];

            switch (props.unit) {
                case "price": default:
                    itemA = parseFloat(itemA.price_e) + (itemA.price_g ? parseFloat(itemA.price_g) : 0)
                    itemB = parseFloat(itemB.price_e) + (itemB.price_g ? parseFloat(itemB.price_g) : 0)
                    break
                case "kwh":
                    itemA = parseFloat(itemA.kwh) / 277.7778 + (itemA.gas ? parseFloat(itemA.gas) / 26.853 : 0)
                    itemB = parseFloat(itemB.kwh) / 277.7778 + (itemB.gas ? parseFloat(itemB.gas) / 26.853 : 0)
                    break
                case "co":
                    itemA = parseFloat(itemA.co_e) + (itemA.co_g ? parseFloat(itemA.co_g) : 0)
                    itemB = parseFloat(itemB.co_e) + (itemB.co_g ? parseFloat(itemB.co_g) : 0)
                    break
                case "eui":
                    itemA = parseFloat(itemA.kwh) / 277.7778 + (itemA.gas ? parseFloat(itemA.gas) / 26.853 : 0)
                    itemB = parseFloat(itemB.kwh) / 277.7778 + (itemB.gas ? parseFloat(itemB.gas) / 26.853 : 0)
                    break
            }

            if (itemA > itemB) {
                return -1;
            }
            if (itemA < itemB) {
                return 1;
            }
            return 0;
        });

        for (let key of sortedList) {
            let item = props.circuits[key]
            switch (props.unit) {
                case "price": default:
                    tempSeriesE.push({ name: item.building, y: parseFloat(item.price_e) })
                    tempSeriesG.push({ name: item.building, y: item.price_g ? parseFloat(item.price_g) : 0 })
                    tempTotal[props.unit].e += parseFloat(item.price_e)
                    tempTotal[props.unit].g += item.price_g ? parseFloat(item.price_g) : 0
                    tempTotal[props.unit].t += ((item.price_g ? parseFloat(item.price_g) : 0) + parseFloat(item.price_e))
                    break
                case "kwh":
                    tempSeriesE.push({ name: item.building, y: parseFloat(item.kwh) / 277.7778 })
                    tempSeriesG.push({ name: item.building, y: (item.gas ? parseFloat(item.gas) / 26.853 : 0) })
                    tempTotal[props.unit].e += parseFloat(item.kwh) / 277.7778
                    tempTotal[props.unit].g += (item.gas ? parseFloat(item.gas) / 26.853 : 0)
                    tempTotal[props.unit].t += ((item.gas ? parseFloat(item.gas) / 26.853 : 0) + parseFloat(item.kwh) / 277.7778)
                    break
                case "co":
                    tempSeriesE.push({ name: item.building, y: parseFloat(item.co_e) })
                    tempSeriesG.push({ name: item.building, y: item.co_g ? parseFloat(item.co_g) : 0 })
                    tempTotal[props.unit].e += parseFloat(item.co_e)
                    tempTotal[props.unit].g += item.co_g ? parseFloat(item.co_g) : 0
                    tempTotal[props.unit].t += ((item.co_g ? parseFloat(item.co_g) : 0) + parseFloat(item.co_e))
                    break
                case "eui":
                    let sqft = context.getUserInfo.buildings.find(e => e.building === item.building)?.sq_footage
                    if (sqft) sqft *= 0.092903
                    tempSeriesEui.push({ name: item.building, y: sqft ? (((parseFloat(item.kwh) / 277.7778) / sqft) + (item.gas ?((parseFloat(item.gas) / 26.853)/sqft) : 0)) : null })
                    tempTotal[props.unit].e += (sqft ? (parseFloat(item.kwh) / 277.7778) / sqft : 0)
                    tempTotal[props.unit].g += (sqft ? (item.gas ? (parseFloat(item.gas) / 26.853) / sqft : 0) : 0)
                    tempTotal[props.unit].t += (sqft ? ((item.gas ? ((parseFloat(item.gas) / 26.853) / sqft) : 0) + ((parseFloat(item.kwh) / 277.7778) / sqft)) : 0)
                    break
            }
        }
        for (let key in tempTotal) {
            for (let type in tempTotal[key]) {
                tempTotal[key][type] = (key === 'price' ? '$' : '') + parseInt(tempTotal[key][type]).toLocaleString() + (key === 'kwh' ? ' GJ' : key === 'co' ? ' t' : '')
            }
        }
        let eSeries = {
            name: "Electricity",
            data: tempSeriesE,
            color: "#619E7B",
        }
        let gSeries = {
            name: "Natural Gas",
            data: tempSeriesG,
            color: "#DD6B20",
        }
        let euiSeries = {
            name: "EUI",
            data: tempSeriesEui,
            color: "#F9941E",
        }
        setTotals(tempTotal)
        setCurrentData([eSeries, gSeries, euiSeries])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits, props.unit])



    useEffect(() => {
        if (!currentData || currentData.length === 0) return
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                type: 'category',
                min: 0,
                max: currentData[1].data.length < 20 ? currentData[1].data.length - 1 : 20,
                accessibility: {
                    description: 'Building'
                },
                scrollbar: {
                    enabled: true
                },
            },
            yAxis: {
                title: {
                    align: 'high',
                    offset: 0,
                    text: '',
                    rotation: 0,
                    y: 10,
                },
                labels: {
                    formatter: function () {
                        return this.value
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + Math.round(this.y).toLocaleString() + '</b><br/>'
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    stacking: 'normal',
                },
            },
            series: props.unit === 'eui' ? [currentData[2]] : [currentData[1], currentData[0]],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                }
            }
        })
        setLoading(false)
    }, [currentData, props.unit])


    return (
        <div>
            <div className={`card tablet:p-8 mobile:p-4 rounded-lg relative mt-8`}>
                <div className='flex flex-nowrap justify-between items-center'>
                    <div>
                        <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">Portfolio Consumption</h2>
                        {props.startEnd && <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>}
                        {props.compare && props.compareStartEnd.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compareStartEnd[0]).format("MMM DD, YYYY")} - {moment(props.compareStartEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compareStartEnd[0], props.compareStartEnd[1])} days)</span></p>}
                    </div>
                    <div className="flex flex-nowrap jusitify-center items-center">
                        <select className='w-1/2 p-2 border border-1 rounded  border-[#C1C1C1] text-[#377745] w-[220px]' value={props.unit} onChange={props.setUnit}>
                            <option value="price">Cost ($)</option>
                            <option value="co">Emissions (t CO₂e)</option>
                            <option value="kwh">Energy (GJ)</option>
                            <option value="eui">Energy Use Intensity (EUI)</option>
                        </select>
                        {props.unit === 'eui' &&
                            <Tooltip
                                text="Buildings with no square footage info will not show values"
                                right="0"
                                bottom="100%"
                                left="-500%"
                                width="200px"
                                show={true}>
                                <div className="ml-2">
                                    {GlobalSVG.info('black', '15', '15')}
                                </div>
                            </Tooltip>}
                    </div>
                </div>
                <h2 className='mb-2 font-medium mt-4'>{props.compare ? "Portfolio Total Difference" : "Portfolio Total"}: {totals[props.unit].t}</h2>
                {props.unit !== 'eui' && <div className='flex flex-nowrap items-center'>
                    Electrical: {totals[props.unit].e}
                    {props.unit === 'kwh' &&
                        <Tooltip
                            text="1 GJ = 277.7778 kWh"
                            right="0"
                            left="400%"
                            width="300px"
                            show={true}>
                            <div className="ml-2">
                                {GlobalSVG.info('black', '15', '15')}
                            </div>
                        </Tooltip>}
                </div>}
               {props.unit !== 'eui' && <div className='flex flex-nowrap items-center'>
                    Natural Gas: {totals[props.unit].g}
                    {props.unit === 'kwh' &&
                        <Tooltip
                            text="1 GJ = 947.81714 cubic feet of natural gas<br />1 GJ = 26.853 cubic metres of natural gas"
                            right="0"
                            left="400%"
                            width="300px"
                            show={true}>
                            <div className="ml-2">
                                {GlobalSVG.info('black', '15', '15')}
                            </div>
                        </Tooltip>}
                </div>}
                <div className="flex flex-nowrap tablet:justify-end laptop:justify-start mobile:justify-end items-center rounded-lg min-h-[600px]">
                    <div className={`col-span-2 w-full`}>
                        {options && <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartRef}
                        />}
                        {loading && <Loader />}
                    </div>
                </div>
            </div >
        </div>
    )
};