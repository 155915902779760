import { useState, useEffect } from "react";
import "./style.css"
import API from '../../utils/API';
import Swal from 'sweetalert2';
import Loader from "../Loader";

function CostValueW(props) {
    const [edit, setEdit] = useState(false)
    const [wValue, setWValue] = useState({
        "model_id": props.modelId,
        "start_date": "",
        "base_rate": "",
        "consumption_rate": "",
        "sewage_ratio": "",
        "sewage_rate": "",
        "carbon_charge": "",
        "municipal_tax": "",
        "ps_tax": "",
        "gs_tax": ""
    })
    const [acceptable, setAcceptable] = useState(false)
    // const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        if (!props.value) return
        setWValue(props.value)
    }, [props])

    const updateValue = (e) => {
        const { name, value } = e.target;
        setWValue(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    useEffect(() => {
        let mandatory = ['start_date', 'base_rate', 'consumption_rate', 'sewage_ratio',
            'sewage_rate', 'municipal_tax', 'ps_tax', 'gs_tax'
        ]

        for (let item of mandatory) {
            if (wValue[item] !== '') continue
            setAcceptable(false)
            return
        }
        setAcceptable(true)

    }, [wValue])

    const postValue = async (e) => {

        let tempValue = { ...wValue }
        const toFormat = ['base_rate', 'consumption_rate', 'sewage_ratio',
            'sewage_rate',
            'municipal_tax', 'ps_tax', 'gs_tax'
        ]

        for (let item of toFormat) {
            tempValue[item] = tempValue[item] ? parseFloat(tempValue[item]).toFixed(6) : tempValue[item]
        }
        tempValue.start_date = tempValue.start_date.split(" ")[0]
        setLoading(true)
        const cancelToken = API.cancelToken();
        
        try {
            if (props.edit) {
                await API.postValueW(cancelToken, tempValue)
            } else if (edit) {
                await API.putValueW(cancelToken, tempValue)
            }
            if (props.edit) props.open(false)
            setEdit(false)
            props.setUpdate(!props.update)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('general' in err.response.data || 'message' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.general,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='w-full flex flex-wrap input-border p-4'>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Start Date</p>
                    <input
                        type="date"
                        name="start_date"
                        className='text-xs input-color outline-none w-full hide-input-background p-1'
                        defaultValue={wValue.start_date !== "" ? wValue.start_date.split(' ')[0] : ""}
                        onChange={updateValue} />
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Base Rates</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="base_rate"
                            defaultValue={wValue.base_rate !== "" ? wValue.base_rate : ""}
                            onChange={updateValue} />
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Consumption</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="consumption_rate"
                            defaultValue={wValue.consumption_rate !== "" ? wValue.consumption_rate : ""}
                            onChange={updateValue} />
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Sewage Ratio</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="sewage_ratio"
                            defaultValue={wValue.sewage_ratio !== "" ? wValue.sewage_ratio : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Sewage Rate</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="sewage_rate"
                            defaultValue={wValue.sewage_rate !== "" ? wValue.sewage_rate : ""}
                            onChange={updateValue} />
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                {/* <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Carbon Charge</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="carbon_charge"
                            defaultValue={wValue.carbon_charge !== "" ? wValue.carbon_charge : ""}
                            onChange={updateValue} />
                    </div>
                </div> */}
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Municipal Tax</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="municipal_tax"
                            defaultValue={wValue.municipal_tax !== "" ? wValue.municipal_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>PST</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="ps_tax"
                            defaultValue={wValue.ps_tax !== "" ? wValue.ps_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>

                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>GST</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="gs_tax"
                            defaultValue={wValue.gs_tax !== "" ? wValue.gs_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='flex flex-nowrap items-center justify-end h-full'>
                    {
                        edit && !loading &&
                        <div className='mr-8 border-light-green block text-sm text-green4 font-medium cursor-pointer' onClick={() => setEdit(false)}>
                            Cancel
                        </div>
                    }
                    {
                        (props.edit || edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={postValue}>
                            Save
                        </button>
                    }
                    {
                        (!props.edit && !edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={() => setEdit(true)}>
                            Edit
                        </button>
                    }
                    {
                        (props.edit || edit) && loading &&
                        <Loader />
                    }
                </div>
            </div>
        </div>
    )
}

export default CostValueW;
