import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';

const Clients = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext)
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_clients_list", "create_clients", "update_clients"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/users')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])


    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        API.getClients(cancelToken).then(res => {
            setLoading(false)
            setData(res.data)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A'
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])

    const sortOptions = (a, b) => {
        var titleA = a.client_name
        var titleB = b.client_name
        if (titleA > titleB) {
            return 1;
        }
        if (titleA < titleB) {
            return -1;
        }
        return 0;
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[90vh]'>
            <div className='card tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='h-5/6 w-full border-light-green mt-8'>
                    <div className='h-[60vh] overflow-y-auto'>
                        {
                            loading === true ?
                                <Loader />
                                :
                                data.sort((a, b) => sortOptions(a, b)).map((e, index) => (
                                    <Link key={index + "tableitem"} className='w-full p-4 input-border flex flex-wrap' to={`/client?client=${e.client_id}`}>
                                        <p>{e.client_name}</p>
                                    </Link>
                                ))
                        }
                    </div>
                </div>
                <Link to={`/client/create`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                    <span className='text-lg mr-2'>+</span>
                    Add new client
                </Link>
            </div>
        </div>
    );
};

export default Clients;