import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExporting from 'highcharts/modules/exporting';

export default function MaintenancePieChart(props) {

    const context = useContext(AppContext);
    const [currentData, setCurrentData] = useState(null)
    const [finalData, setFinalData] = useState(null)
    const [options, setOptions] = useState(null)
    const chartRef = useRef(null);
    let hcColors = ['#17AEEE', '#BFDA45', '#DD6B20', '#8f1ef8', '#638971', '#e5b9da']

    HighchartsAccessibility(Highcharts);
    HighchartsExporting(Highcharts);

    useEffect(() => {
        if (!props.groups) return
        if (context.getBuildingInfo.e_channels.length !== 0) {
            try {
                let data = {total: 0}
                props.groups.forEach(group=>{
                    data[group['type']] = data[group['type']] || 0
                    group.activities.forEach(activity=>{
                        data[group['type']] ++
                        data.total ++
                    })
                })
                let colorIndex = 0
                let tempPieOpt = []
                for (const key of Object.keys(data).sort()) {
                    if (key === 'total') continue
                    let tempObj = {}
                    tempObj.name = key
                    tempObj.amount = data[key].toFixed(2)
                    tempObj.y = parseFloat(((data[key] / data.total) * 100).toFixed(2))
                    tempObj.color = hcColors[colorIndex]
                    colorIndex++
                    if (colorIndex === hcColors.length) colorIndex = 0
                    tempPieOpt.push(tempObj)
                }
                setCurrentData(tempPieOpt)
            } catch (e) {
                if (e.message === 'cancelling') return
            }
        } else {
            setCurrentData(context.getFixed30DayData.e_pie)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.groups])

    useEffect(() => {
        if (!currentData) return
        let tempData = JSON.parse(JSON.stringify(currentData))
        let index = 0
        for (let item of tempData) {
            item.color = hcColors[index]
            index++
        }
        setFinalData(tempData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData])


    useEffect(() => {
        if (!finalData) return
        setOptions({
            chart: {
                height: 225,
                width: 355,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                formatter: function () {
                    return `<p style='font-size:12px'>${this.key}</p><br><p><span style='font-size:10px'>Amount:</span> ${parseInt(this.point.amount)} (${this.y}%)</p`

                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'middle'
              },
          
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function () {
                                return false
                            }
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                colorByPoint: true,
                data: finalData
            }],
            exporting: {
                enabled: false,
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                pointFormat: '<p>{point.name}</p>: <b>{point.percentage:.1f}%</b>'
                            },
                        },
                    },
                },
            },
        })
    }, [finalData])



    return (
        <div className='' >
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className="w-full">
                            {(Highcharts && currentData && chartRef && options) && <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />}
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}