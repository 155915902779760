import { useState, useEffect } from "react";
import "./style.css"
import API from '../../utils/API';
import Swal from 'sweetalert2';
import Loader from "../Loader";

function CostValueCo(props) {
    const [edit, setEdit] = useState(false)
    const [coValue, setCoValue] = useState({
        "model_id": props.modelId,
        "start_date": "",
        "factor": "",
    })
    const [acceptable, setAcceptable] = useState(false)
    // const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)

    useEffect(() => {
        if (!props.value) return
        setCoValue(props.value)
    }, [props])

    const updateValue = (e) => {
        const { name, value } = e.target;
        setCoValue(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    useEffect(() => {
        let mandatory = ['start_date', 'factor']

        for (let item of mandatory) {
            if (coValue[item] !== '') continue
            setAcceptable(false)
            return
        }
        setAcceptable(true)

    }, [coValue])

    const postValue = async (e) => {

        let tempValue = { ...coValue }
        const toFormat = ['factor']

        for (let item of toFormat) {
            tempValue[item] = tempValue[item] ? parseFloat(tempValue[item]).toFixed(6) : tempValue[item]
        }
        tempValue.start_date = tempValue.start_date.split(" ")[0]
        setLoading(true)
        const cancelToken = API.cancelToken();
        
        try {
            if (props.edit) {
                await API.postValueCo(cancelToken, tempValue)
            } else if (edit) {
                await API.putValueCo(cancelToken, tempValue)
            }
            if (props.edit) props.open(false)
            setEdit(false)
            props.setUpdate(!props.update)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('general' in err.response.data || 'message' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-row item-center justify-between input-border p-4">
            <div className='w-full flex flex-wrap'>
                <div className='tablet:w-2/12 mobile:w-full'>
                    <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                        <p className='text-xs mb-1'>Start Date</p>
                        <input
                            type="date"
                            name="start_date"
                            className='text-xs input-color outline-none w-full hide-input-background p-1'
                            defaultValue={coValue.start_date !== "" ? coValue.start_date.split(' ')[0] : ""}
                            onChange={updateValue} />
                    </div>
                </div>
                <div className='tablet:w-8/12 mobile:w-full'>
                    <div className='tablet:w-6/12 mobile:w-full min-w-[100px] mb-4'>
                        <p className='text-xs mb-1'>Factor</p>
                        <div className='flex flex-nowrap items-center justify-start'>
                            <input
                                placeholder='Price'
                                className='hide-input-background text-sm input-color outline-none w-3/12 p-1'
                                type="number"
                                step="0.1"
                                disabled={!props.edit && !edit}
                                name="factor"
                                defaultValue={coValue.factor !== "" ? coValue.factor : ""}
                                onChange={updateValue} />
                            <div>
                                <p className="text-xs mb-1">
                                    Kg CO₂e/m³ (Natural Gas)
                                </p>
                                <p className="text-xs mb-1">
                                    Kg CO₂e/kWh (Electrical)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tablet:w-3/12 mobile:w-full'>
                <div className='flex flex-nowrap items-center justify-end h-full'>
                    {
                        edit && !loading &&
                        <div className='mr-8 border-light-green block text-sm text-green4 font-medium cursor-pointer' onClick={() => setEdit(false)}>
                            Cancel
                        </div>
                    }
                    {
                        (props.edit || edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={postValue}>
                            Save
                        </button>
                    }
                    {
                        (!props.edit && !edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={() => setEdit(true)}>
                            Edit
                        </button>
                    }
                    {
                        (props.edit || edit) && loading &&
                        <Loader />
                    }
                </div>
            </div>
        </div>
    )
}

export default CostValueCo;
