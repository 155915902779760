import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRef, useEffect, useState } from 'react';
import Loader from "../Loader";
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExporting from 'highcharts/modules/exporting';

export default function AlertPieChart(props) {

    const [finalData, setFinalData] = useState(null)
    const [options, setOptions] = useState(null)
    const chartRef = useRef(null);
    let hcColors = ['#17AEEE', '#BFDA45', '#DD6B20', '#8f1ef8', '#638971', '#e5b9da']

    HighchartsAccessibility(Highcharts);
    HighchartsExporting(Highcharts);

    useEffect(() => {
        if (!props.data) return
        const sum = Object.values(props.data).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        let tempData = Object.keys(props.data).map((e, idx)=>(
            {
                name: e, 
                amount: props.data[e].toFixed(2), 
                y: parseFloat(((props.data[e] / sum) * 100).toFixed(2)),
                color: hcColors[idx]
            }
        ))
        setFinalData(tempData)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        if (!finalData) return
        setOptions({
            chart: {
                height: 225,
                width: 355,
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                formatter: function () {
                    return `<p style='font-size:12px'>${this.key}</p><br><p><span style='font-size:10px'>Amount:</span> ${parseInt(this.point.amount)} (${this.y}%)</p`

                }
            },
            legend: {
                layout: 'vertical',
                align: 'left',
                verticalAlign: 'middle'
              },
          
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function () {
                                return false
                            }
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                colorByPoint: true,
                data: finalData
            }],
            exporting: {
                enabled: false,
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                pointFormat: '<p>{point.name}</p>: <b>{point.percentage:.1f}%</b>'
                            },
                        },
                    },
                },
            },
        })
    }, [finalData])



    return (
        <div className='' >
            {
                finalData ?
                    <div className="w-full">
                        <div className="w-full">
                            {(Highcharts && finalData && chartRef && options) && <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />}
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}