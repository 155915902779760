import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExporting from 'highcharts/modules/exporting';

export default function ReportPie1(props) {

    const context = useContext(AppContext);
    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const [selectedCat, setSelectedCat] = useState(null)
    const chartRef = useRef(null);
    HighchartsAccessibility(Highcharts);
    HighchartsExporting(Highcharts);

    useEffect(() => {
        if (!props.circuits || !context.getBuildingInfo) return
        // let colors = ["#58826A", "#619E7B", "#75B08E", "#92C4A7", "#B2D8C3", "#377745", "#D2CECE", "#638971"]
        let colors = ['#58826A', '#DD6B20', '#A22', '#17AEEE', '#FFD233', '#BFDA45', '#F75D5F', '#A6ABAD']
        let colorIndex = 0
        let priceBreakdown = {base_dollar: "Monthly Basic", kwh_consumption_dollar: "kWh Cost", kva_consumption_dollar: 'kVA Cost', carbon_charge_dollar: 'Carbon Charge', municipal_tax_dollar: "Municipal Tax", pst_dollar: 'PST', gst_dollar: "GST"}
        let tempPieOpt = []

        let priceDict = {kwh_consumption_dollar: 0, kva_consumption_dollar: 0, carbon_charge_dollar: 0, municipal_tax_dollar: 0, pst_dollar: 0, gst_dollar: 0, total: 0, base_dollar: 0}
        for (const key in props.circuits) {
            if (key.includes('Cap Bank')) continue
            let item = props.circuits[key]
            priceDict.kwh_consumption_dollar += item.price.on.kwh_consumption_dollar + item.price.off.kwh_consumption_dollar
            priceDict.base_dollar += item.price.on.base_dollar + item.price.off.base_dollar
            priceDict.kva_consumption_dollar += item.price.on.kva_consumption_dollar + item.price.off.kva_consumption_dollar
            priceDict.carbon_charge_dollar += item.price.on.carbon_charge_dollar + item.price.off.carbon_charge_dollar
            priceDict.municipal_tax_dollar += item.price.on.municipal_tax_dollar + item.price.off.municipal_tax_dollar
            priceDict.pst_dollar += item.price.on.pst_dollar + item.price.off.pst_dollar
            priceDict.gst_dollar += item.price.on.gst_dollar + item.price.off.gst_dollar
            priceDict.total += item.price.on.total + item.price.off.total
        }

        // setTotal(priceDict.total)

        for (const key in priceBreakdown) {
            let tempObj = {}
            tempObj.name = priceBreakdown[key]
            tempObj.y = parseInt(priceDict[key])
            tempObj.color = colors[colorIndex]
            colorIndex++
            if (colorIndex === colors.length) colorIndex = 0
            tempPieOpt.push(tempObj)
        }

        setCurrentData(tempPieOpt)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits, context.getBuildingInfo])


    useEffect(() => {
        setOptions({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                formatter: function () {
                    const formattedY = Highcharts.numberFormat(this.point.y, 0, '.', ',');
                    return `<span>$<span/>${formattedY} (${parseInt(this.point.percentage)}%)`;
                }
        
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    point: {
                        events: {                      
                            click: function (event) {
                                if (event.point.state === 'select') {
                                    setSelectedCat(null)
                                } else {
                                    setSelectedCat({ [this.name]: this.y })
                                }
                            },
                        }
                    }
                },
            },
            series: [{
                name: "Price",
                colorByPoint: true,
                data: currentData
            }],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    const formattedY = Highcharts.numberFormat(this.point.y, 0, '.', ',');
                                    return `<span>$<span/>${formattedY} (${parseInt(this.point.percentage)}%)`;
                                }
                            },
                        },
                    },
                },
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData])

    return (
        <div className='' >
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <h2 className="capitalize w-full laptop:text-2xl tablet:text-xl font-bold text-[#332D41] md:decoration-dashed">Utility Bill Breakdown {props.compare && "(Comparative)"}</h2>
                        </div>
                        <p>Total : ${parseInt(props.totalPrice).toLocaleString()}</p>
                        {selectedCat && <p>{Object.keys(selectedCat)[0]} : ${parseInt(Object.values(selectedCat)[0]).toLocaleString()}</p>}
                        <div className="w-full">
                            {(Highcharts && currentData && chartRef) && <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />}
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}