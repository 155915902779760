import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import SlideToggle from '../../components/SlideToggle';

const User = () => {

    const location = useLocation();
    const [user, setUser] = useState(null)
    const [clients, setClients] = useState(null)
    const [roles, setRoles] = useState(null)
    const [buildings, setBuildings] = useState(null)
    const [manager, setManager] = useState(false)
    const [getPutPost, setGetPutPost] = useState()
    const [userData, setUserData] = useState({
        username: "",
        client_id: "",
        lastname: "",
        firstname: "",
        user_level: "",
        title: "",
        role: "",
        contact_name: "",
        primary_tel: "",
        primary_sms_network: "",
        alternate_tel: "",
        alternate_sms_network: "",
        email: "",
        alternate_email: "",
        password: "",
        is_active: "",
        buildings: []
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_users_list", "create_users", "update_users"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/operators')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/user/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setUser(queryParams.get('user'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && user !== null) {
            setLoading(true)
            
            API.getUserInfo(cancelToken, user).then(res => {
                let dataUser = res.data
                let tempUser = {
                    username: dataUser.username,
                    client_id: dataUser.client_id,
                    lastname: dataUser.lastname,
                    firstname: dataUser.firstname,
                    user_level: dataUser.user_level,
                    title: dataUser.title,
                    role: dataUser.role,
                    contact_name: dataUser.contact_name,
                    primary_tel: dataUser.primary_tel,
                    primary_sms_network: dataUser.primary_sms_network,
                    alternate_tel: dataUser.alternate_tel,
                    alternate_sms_network: dataUser.alternate_sms_network,
                    email: dataUser.email,
                    alternate_email: dataUser.alternate_email,
                    password: "",
                    is_active: dataUser.is_active,
                    buildings: dataUser.buildings
                }
                setUserData(tempUser)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, user])

    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        API.getClients(cancelToken).then(res => {
            setLoading(false)
            setClients(res.data)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })
        API.getRoles(cancelToken, user).then(res => {
            setRoles(res.data)
            return
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            let message = 'Please try again later.'
            if (err.response && err.response.data && err.response.data.includes('error')) {
                message = err.response.data.error
            }
            Swal.fire({
                title: 'Error.',
                text: message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        }).finally(f => {
            setLoading(false)
        })

        API.getBuildings(cancelToken).then(res => {
            let tempDict = {}
            let data = res.data
            for (let building of data) {
                tempDict[building.client_name] = tempDict[building.client_name] || []
                tempDict[building.client_name].push(building)
            }
            setBuildings(tempDict)
            return
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            let message = 'Please try again later.'
            if (err.response && err.response.data && err.response.data.includes('error')) {
                message = err.response.data.error
            }
            Swal.fire({
                title: 'Error.',
                text: message,
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        }).finally(f => {
            setLoading(false)
        })

        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    const updateUser = (e) => {
        const { name, value } = e.target;
        setUserData(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    const postUser = async (e) => {
        if (userData.username === "") {
            setError({ username: "Missing Value" })
        } else {
            let payload = JSON.parse(JSON.stringify(userData))
            delete payload['created_at'];
            delete payload['updated_at'];
            if (getPutPost === 'put') {
                delete payload['username'];
                delete payload['client_id'];
                delete payload['password'];
            }
            payload.buildings = userData.buildings.map(e => e.building_id)
            for (let key in payload) {
                if (payload[key] === null || String(payload[key]).trim() === "") {
                    delete payload[key];
                }
            }
            if ('role' in payload) payload['role'] = parseInt(payload['role'])
            setLoading(true)
            const cancelToken = API.cancelToken();
            
            try {
                if (getPutPost === 'post') {
                    await API.postUser(cancelToken, payload)
                    navigate(`/user?user=${payload.username}`)
                } else {
                    await API.putUser(cancelToken, user, payload)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                }
            } finally {
                setLoading(false)
            }
        }
    }

    const updateBuildings = (building) => {
        let tempData = JSON.parse(JSON.stringify(userData))
        if (building === 'all') {
            tempData.buildings = []
            for (let arr of Object.values(buildings)) {
                tempData.buildings = tempData.buildings.concat(arr)
            }
            setUserData(tempData)
            return
        }
        let inList = userData.buildings.find(e => e.building_id === building.building_id)
        if (!inList) {
            tempData.buildings.push(building)
        } else {
            tempData.buildings = tempData.buildings.filter(e => e.building_id !== building.building_id);
        }
        setUserData(tempData)
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/users">
                    Users
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? userData.username : "Add User"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 flex flex-wrap w-full justify-between items-center w-full items-center pt-3 pb-3 mb-6'>
                    <div className='items-center mb-6 laptop:w-1/2 tablet:w-full mobile:w-full'>
                        <input
                            placeholder='Username'
                            className='text-3xl p-2 outline-none hide-input-background w-full'
                            type="text"
                            name='username'
                            disabled={getPutPost !== 'post'}
                            defaultValue={userData.username ? userData.username : ""}
                            onChange={updateUser} />
                        {
                            error && error.username &&
                            <p className='text-red text-xs p-2'>{error.username}</p>

                        }
                    </div>
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setUserData} options={userData} id="is_active" name="Active" />
                </div>
                {
                    loading &&
                    <Loader />
                }
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="firstname">First Name</label>
                            <input
                                placeholder='First Name'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="firstname" defaultValue={userData.firstname ? userData.firstname : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.firstname &&
                                <p className='text-red text-xs'>{error.firstname}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="lastname">Last Name</label>
                            <input
                                placeholder='Last Name'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="lastname" defaultValue={userData.lastname ? userData.lastname : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.lastname &&
                                <p className='text-red text-xs'>{error.lastname}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="title">Title</label>
                            <input
                                placeholder='Title'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="title" defaultValue={userData.title ? userData.title : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.title &&
                                <p className='text-red text-xs'>{error.title}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="client_id">Client ID</label>
                            <select
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="client_id"
                                value={userData.client_id ? userData.client_id : ""}
                                disabled={getPutPost !== 'post'}
                                onChange={updateUser}
                            >
                                <option value="">Select</option>
                                {
                                    clients && clients.map((e, idx) => (
                                        <option key={idx + 'clientidkey'} value={e.client_id}>{e.client_name}</option>
                                    ))
                                }
                            </select>
                            {
                                error && error.client_id &&
                                <p className='text-red text-xs'>{error.client_id}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="role">Role</label>
                            <select
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="role"
                                value={userData.role ? userData.role : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser}
                            >
                                <option value="">Select</option>
                                {
                                    roles && roles.map((e, idx) => (
                                        <option key={idx + 'clientidkey'} value={e.role_id}>{e.role}</option>
                                    ))
                                }
                            </select>
                            {
                                error && error.role &&
                                <p className='text-red text-xs'>{error.role}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="contact_name">Contact Name</label>
                            <input
                                placeholder='Contact Name'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="contact_name" defaultValue={userData.contact_name ? userData.contact_name : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.contact_name &&
                                <p className='text-red text-xs'>{error.contact_name}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="primary_tel">Primary Phone</label>
                            <input
                                placeholder='Primary Phone'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="primary_tel" defaultValue={userData.primary_tel ? userData.primary_tel : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.primary_tel &&
                                <p className='text-red text-xs'>{error.primary_tel}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="primary_sms_network">Primary Network</label>
                            <input
                                placeholder='Primary Network'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="primary_sms_network" defaultValue={userData.primary_sms_network ? userData.primary_sms_network : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.primary_sms_network &&
                                <p className='text-red text-xs'>{error.primary_sms_network}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap w-full'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="alternate_tel">Alternate Phone</label>
                            <input
                                placeholder='Alternate Phone'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="alternate_tel" defaultValue={userData.alternate_tel ? userData.alternate_tel : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.alternate_tel &&
                                <p className='text-red text-xs'>{error.alternate_tel}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="alternate_sms_network">Alternate Network</label>
                            <input
                                placeholder='Alternate Network'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="alternate_sms_network" defaultValue={userData.alternate_sms_network ? userData.alternate_sms_network : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.alternate_sms_network &&
                                <p className='text-red text-xs'>{error.alternate_sms_network}</p>

                            }
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap w-full'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="email">E-mail</label>
                            <input
                                placeholder='E-mail'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                step="0.1"
                                name="email" defaultValue={userData.email ? userData.email : ""}
                                disabled={getPutPost === 'get'}
                                onChange={updateUser} />
                            {
                                error && error.email &&
                                <p className='text-red text-xs'>{error.email}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="alternate_email">Alternate E-mail</label>
                                <input
                                    placeholder='Alternate E-mail'
                                    className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    step="0.1"
                                    name="alternate_email" defaultValue={userData.alternate_email ? userData.alternate_email : ""}
                                    disabled={getPutPost === 'get'}
                                    onChange={updateUser} />
                                {
                                    error && error.alternate_email &&
                                    <p className='text-red text-xs'>{error.alternate_email}</p>

                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap w-full'>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="password">Password</label>
                            <input
                                placeholder='Password'
                                className='mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                type="text"
                                name="password" defaultValue={['get', 'put'].includes(getPutPost) ? "*********" : userData.password}
                                disabled={getPutPost !== 'post'}
                                onChange={updateUser} />
                            {
                                error && error.sq_footage &&
                                <p className='text-red text-xs'>{error.sq_footage}</p>

                            }
                        </div>
                    </div>
                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap items-center'>
                            <div className='w-full flex flex-wrap items-center'>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap w-full'>
                    <div className='input-border flex flex-wrap laptop:w-full tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                        <div className='w-full flex flex-wrap justify-between items-center'>
                            <p className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="buildings">Linked Buildings</p>
                            <div className='flex flex-wrap w-full text-sm p-2 outline-none overflow-hidden truncate'>
                                {userData.buildings ? userData.buildings.map((e, idx) => (
                                    <div key={idx + "selectedbuildings"} className='flex flex-nowrap items-center w-content'>
                                        <div className={`rouned-full active-user`}></div>
                                        <p className='whitespace-nowrap'>
                                            {e.building}
                                        </p>
                                    </div>

                                )) : ""}
                            </div>
                            {getPutPost !== 'get' &&
                                <div className='flex flex-nowrap tablet:w-2/12 mobile:w-full justify-center items-center cursor-pointer mt-4' onClick={() => setManager(!manager)}>
                                    {GlobalSVG.search()}
                                    <p className='ml-2 text-green'>Manage Buildings</p>
                                </div>}

                            {
                                error && error.sq_footage &&
                                <p className='text-red text-xs'>{error.sq_footage}</p>

                            }
                        </div>
                    </div>
                </div>

                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit User
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && userData.user !== "" && !loading && <button className='submit-button' onClick={postUser}>
                            Save
                        </button>
                    }
                </div>
            </div>
            {
                manager &&
                <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center'>
                    <div className='card laptop:w-2/5 tablet:w-3/5 mobile:w-11/12 m-2 p-4 background-white'>
                        <div className='laptop:h-[400px] tablet:h-[400px] mobile:h-[350px] overflow-auto m-2'>
                            <p className='p-2 cursor-pointer text-[grey]' onClick={() => updateBuildings('all')}>Select All</p>
                            {buildings && Object.keys(buildings).sort().map((e, idx) => (
                                <div key={idx + 'clientbuildings'}>
                                    <p className='p-2 font-bold'>{e}</p>
                                    {
                                        buildings[e].map((b, idx) => (
                                            <div className='flex flex-nowrap w-full truncate p-2 cursor-pointer' key={idx + "buildings"} onClick={() => updateBuildings(b)}>
                                                <input className='mr-3' type="checkbox" checked={userData.buildings.map(i => i.building_id).includes(b.building_id)} />
                                                <p>{b.building}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-nowrap items-center justify-end w-full">
                            <div className='mr-8 text-green3 cursor-pointer' onClick={() => setManager(false)}>
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default User;