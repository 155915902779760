import Axios from "axios";

const { REACT_APP_ENV } = process.env;
var URL = "https://apigatewaydev.greensense.ca";
var MICROSERVICE_URL = "https://microservicedev.greensense.ca"

if (REACT_APP_ENV === "production") {
    URL = "https://apigateway.greensense.ca";
    MICROSERVICE_URL = "https://microservice.greensense.ca"
}

Axios.defaults.timeout = 4 * 60 * 1000
Axios.defaults.withCredentials = true

const API = { 
    logIn: function (source, username, password) {
        return Axios.get(
            `${URL}/auth?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    logOut: function () {
        return Axios.get(
            `${URL}/endauth`,
            { withCredentials: true }
        );
    },
    recoverGet: function (source, username) {
        return Axios.get(
            `${URL}/auth/forgot-password?username=${username}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    recoverPut: function (source, payload) {
        return Axios.put(
            `${URL}/auth/forgot-password`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    changePassword: function (source, payload) {
        return Axios.post(
            `${URL}/auth/change`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getCatConsumption: function (source, start, end, building) {
        return Axios.get(
            `${MICROSERVICE_URL}/categories?start=${start}&end=${end}&building=${building}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getCatConsumptionD: function (source, start, end, building, type) {
        return Axios.get(
            `${MICROSERVICE_URL}/categories/digital?start=${start}&end=${end}&building=${building}&type=${type}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getTemperature: function (source, building_id, frequency, start, end, unit) {
        return Axios.get(
            `${URL}/forecast/weather?building_id=${building_id}&time_step=${frequency}&start_date=${start}&end_date=${end}&units=${unit}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getHierarchyConsumption: function (source, today, hour, building) {
        return Axios.get(
            `${MICROSERVICE_URL}/hierarchy_consumption?today=${today}&hour=${hour}&building=${building}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getHierarchyConsumptionD: function (source, building, today, hour, type) {
        return Axios.get(
            `${MICROSERVICE_URL}/digital/hierarchy_consumption?today=${today}&hour=${hour}&building=${building}&type=${type}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getPeakBatch: function (source, building, timestamp, unit = 'kva') {
        return Axios.get(
            `${MICROSERVICE_URL}/batchpeaks?building=${building}&timestamp=${timestamp}&unit=${unit}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getKwhBatch: function (source, building, start, end) {
        return Axios.get(
            `${MICROSERVICE_URL}/batchconsumption?building=${building}&start=${start}&end=${end}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getBreakerBatch: function (source, building, start, end) {
        return Axios.get(
            `${MICROSERVICE_URL}/batchbreaker?building=${building}&start=${start}&end=${end}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getRuntimeBatch: function (source, building, start, end) {
        return Axios.get(
            `${MICROSERVICE_URL}/batchruntime?building=${building}&start=${start}&end=${end}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getCircuitRuntime: function (source, start, end, device, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/runtime?start=${start}&end=${end}&device=${device}&circuit=${encodeURIComponent(circuit)}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getElectricity: function (source, start, end, device, detail, unit, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/electrical?start=${start}&end=${end}&device=${device}&detail=${detail}&unit=${unit}&circuit=${encodeURIComponent(circuit)}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getSum: function (source, start, end, device, unit, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/e_sum?start=${start}&end=${end}&device=${device}&&unit=${unit}&circuit=${encodeURIComponent(circuit)}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getHarmonics: function (source, start, end, device, detail, unit, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/harmonics?start=${start}&end=${end}&device=${device}&detail=${detail}&unit=${unit}&circuit=${encodeURIComponent(circuit)}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getDigital: function (source, start, end, device, detail, circuit, type, channelId=null) {
        return Axios.get(
            `${MICROSERVICE_URL}/digital?start=${start}&end=${end}&device=${device}&detail=${detail}&circuit=${encodeURIComponent(circuit)}&type=${type}${channelId? "&channel_id="+channelId : ""}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getCurrentReading: function (source, device, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/current?device=${device}&circuit=${encodeURIComponent(circuit)}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getCurrentReadingD: function (source, device, circuit) {
        return Axios.get(
            `${MICROSERVICE_URL}/digital/current?device=${device}&circuit=${encodeURIComponent(circuit)}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelsE: function (source) {
        return Axios.get(
            `${URL}/cost/electricity/models`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelsW: function (source) {
        return Axios.get(
            `${URL}/cost/water/models`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelsG: function (source) {
        return Axios.get(
            `${URL}/cost/gas/models`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelsCo: function (source) {
        return Axios.get(
            `${URL}/cost/co/models`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelE: function (source, model) {
        return Axios.get(
            `${URL}/cost/electricity/model/${model}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelW: function (source, model) {
        return Axios.get(
            `${URL}/cost/water/model/${model}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelG: function (source, model) {
        return Axios.get(
            `${URL}/cost/gas/model/${model}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getModelCo: function (source, model) {
        return Axios.get(
            `${URL}/cost/co/model/${model}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postModelE: function (source, payload) {
        return Axios.post(
            `${URL}/cost/electricity/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postModelW: function (source, payload) {
        return Axios.post(
            `${URL}/cost/water/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postModelG: function (source, payload) {
        return Axios.post(
            `${URL}/cost/gas/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postModelCo: function (source, payload) {
        return Axios.post(
            `${URL}/cost/co/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putModelE: function (source, payload) {
        return Axios.put(
            `${URL}/cost/electricity/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putModelW: function (source, payload) {
        return Axios.put(
            `${URL}/cost/water/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putModelG: function (source, payload) {
        return Axios.put(
            `${URL}/cost/gas/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putModelCo: function (source, payload) {
        return Axios.put(
            `${URL}/cost/co/model`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postValueE: function (source, payload) {
        return Axios.post(
            `${URL}/cost/electricity/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postValueW: function (source, payload) {
        return Axios.post(
            `${URL}/cost/water/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postValueG: function (source, payload) {
        return Axios.post(
            `${URL}/cost/gas/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postValueCo: function (source, payload) {
        return Axios.post(
            `${URL}/cost/co/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putValueE: function (source, payload) {
        return Axios.put(
            `${URL}/cost/electricity/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putValueW: function (source, payload) {
        return Axios.put(
            `${URL}/cost/water/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putValueG: function (source, payload) {
        return Axios.put(
            `${URL}/cost/gas/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putValueCo: function (source, payload) {
        return Axios.put(
            `${URL}/cost/co/values`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPriceE: function (source, start, end, circuit, device_id, kwh = false) {
        return Axios.get(
            `${MICROSERVICE_URL}/cost/electrical?start=${start}&end=${end}&circuit=${encodeURIComponent(circuit)}&device=${device_id}${kwh ? "&kwh=true" : ""}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getPriceW: function (source, start, end, meter, device_id) {
        return Axios.get(
            `${MICROSERVICE_URL}/cost/water?start=${start}&end=${end}&meter=${meter}&device=${device_id}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getPriceG: function (source, start, end, meter, device_id) {
        return Axios.get(
            `${MICROSERVICE_URL}/cost/gas?start=${start}&end=${end}&meter=${meter}&device=${device_id}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getPriceCoElectric: function (source, start, end, circuit, device_id, detail) {
        return Axios.get(
            `${MICROSERVICE_URL}/cost/co/electric?start=${start}&end=${end}&circuit=${encodeURIComponent(circuit)}&device=${device_id}&detail=${detail}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getPriceCoGas: function (source, start, end, circuit, device_id, detail) {
        return Axios.get(
            `${MICROSERVICE_URL}/cost/co/gas?start=${start}&end=${end}&circuit=${encodeURIComponent(circuit)}&device=${device_id}&detail=${detail}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getRoles: function (source) {
        return Axios.get(
            `${URL}/roles`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getRole: function (source, role) {
        return Axios.get(
            `${URL}/role/${role}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postRole: function (source, payload) {
        return Axios.post(
            `${URL}/roles`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putRole: function (source, role, payload) {
        return Axios.put(
            `${URL}/roles/${role}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    updateCorrespondance: function (payload) {
        return Axios.put(
            `${URL}/user/correspondance`,
            payload,
            { withCredentials: true }
        );
    },
    getClients: function (source) {
        return Axios.get(
            `${URL}/clients`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getClient: function (source, client) {
        return Axios.get(
            `${URL}/client/${client}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postClient: function (source, payload) {
        return Axios.post(
            `${URL}/clients`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putClient: function (source, client, payload) {
        return Axios.put(
            `${URL}/clients/${client}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getBuildings: function (source) {
        return Axios.get(
            `${URL}/buildings?full_infomration=false`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getBuilding: function (source, building) {
        return Axios.get(
            `${MICROSERVICE_URL}/building/${building}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    postBuilding: function (source, payload) {
        return Axios.post(
            `${URL}/buildings`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putBuilding: function (source, building, payload) {
        return Axios.put(
            `${URL}/buildings/${building}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getDevices: function (source) {
        return Axios.get(
            `${URL}/devices`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getDigitalChannels: function (source) {
        return Axios.get(
            `${URL}/digital/channels`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getActuatorStatus: function (source, id) {
        return Axios.get(
            `${MICROSERVICE_URL}/actuator?id=${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    comandActuator: function (id, pos) {
        return Axios.post(
            `${MICROSERVICE_URL}/actuator?id=${id}&pos=${pos}`,
            {},
            { withCredentials: true }
        );
    },
    getActuators: function (source) {
        return Axios.get(
            `${URL}/actuators`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getActuatorHours: function (source, actuator) {
        return Axios.get(
            `${URL}/actuator/hours/${actuator}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getActuator: function (source, actuator) {
        return Axios.get(
            `${URL}/actuator/${actuator}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    createActuator: function (source, payload) {
        return Axios.post(
            `${URL}/actuator`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    updateActuator: function (source, actuator_id, payload) {
        return Axios.put(
            `${URL}/actuator/${actuator_id}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putActuatorHours: function (source, actuator_id, payload) {
        return Axios.put(
            `${URL}/actuator/hours/${actuator_id}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getDevicePictures: function (source, channel_id, type) {
        return Axios.get(
            `${URL}/photos?channel_id=${channel_id}&type=${type}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getCircuit: function (source, device, circuit) {
        return Axios.get(
            `${URL}/circuit?device=${device}&circuit=${encodeURIComponent(circuit)}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    updateCircuit: function (source, channel_id, payload) {
        return Axios.put(
            `${URL}/circuit?channel_id=${channel_id}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getDevice: function (source, device) {
        return Axios.get(
            `${URL}/device/${device}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postDevice: function (source, payload) {
        return Axios.post(
            `${URL}/device`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putDevice: function (source, device, payload) {
        return Axios.put(
            `${URL}/device/${device}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postChannel: function (source, payload) {
        return Axios.post(
            `${URL}/channel`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putChannel: function (source, channelId, payload) {
        return Axios.put(
            `${URL}/channel/${channelId}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putAlert: function (source, alert, payload) {
        return Axios.put(
            `${URL}/readalert/${alert}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getUsers: function (source) {
        return Axios.get(
            `${URL}/users`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getUser: function (source) {
        return Axios.get(
            `${MICROSERVICE_URL}/user`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getUserInfo: function (source, user) {
        return Axios.get(
            `${URL}/userinfo/${user}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postUser: function (source, payload) {
        return Axios.post(
            `${URL}/users`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putUser: function (source, user, payload) {
        return Axios.put(
            `${URL}/users/${user}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getBuildingsUsers: function (source) {
        return Axios.get(
            `${URL}/buildings/users`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getBuildingUsers: function (source, building_id) {
        return Axios.get(
            `${URL}/building/users/${building_id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getBuildingOperators: function (source) {
        return Axios.get(
            `${URL}/buildings/operators`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getOperators: function (source) {
        return Axios.get(
            `${URL}/operators`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getOperator: function (source, operator) {
        return Axios.get(
            `${URL}/operator/${operator}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    postOperator: function (source, payload) {
        return Axios.post(
            `${URL}/operators`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putOperator: function (source, operator, payload) {
        return Axios.put(
            `${URL}/operators/${operator}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getHours: function (source, building) {
        return Axios.get(
            `${URL}/ophours/${building}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getOpHoursRange: function (source, building, start, end) {
        return Axios.get(
            `${URL}/ophours/${building}?start=${start}&end=${end}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    putHours: function (source, building, payload) {
        return Axios.put(
            `${URL}/ophours/${building}`,
            payload,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getVersion: function (source) {
        return Axios.get(
            `${URL}/version`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getFaq: function (source) {
        return Axios.get(
            `${URL}/faq`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getActivities: function (source, buildingId) {
        return Axios.get(
            `${URL}/activities/${buildingId}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    createActivity: function (payload) {
        return Axios.post(
            `${URL}/activity`,
            payload,
            { withCredentials: true }
        );
    },
    updateActivity: function (payload, activity) {
        return Axios.put(
            `${URL}/activity/${activity}`,
            payload,
            { withCredentials: true }
        );
    },
    getLinks: function (source, buildingId) {
        return Axios.get(
            `${URL}/links/${buildingId}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    createLink: function (payload) {
        return Axios.post(
            `${URL}/link`,
            payload,
            { withCredentials: true }
        );
    },
    updateLink: function (payload, link) {
        return Axios.put(
            `${URL}/link/${link}`,
            payload,
            { withCredentials: true }
        );
    },
    createImpact: function (payload) {
        return Axios.post(
            `${URL}/impact`,
            payload,
            { withCredentials: true }
        );
    },
    updateImpact: function (payload, id) {
        return Axios.put(
            `${URL}/impact/${id}`,
            payload,
            { withCredentials: true }
        );
    },
    getPublicImpact: function (source, type, level, id) {
        return Axios.get(
            `${URL}/public/impact?type=${type}&level=${level}&id=${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicBuilding: function (source, id) {
        return Axios.get(
            `${URL}/public/building/${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicClient: function (source, id) {
        return Axios.get(
            `${URL}/public/client/${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicGenerationRecent: function (source, id) {
        return Axios.get(
            `${MICROSERVICE_URL}/public/generation/recent?building=${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicGenerationTotal: function (source, id) {
        return Axios.get(
            `${MICROSERVICE_URL}/public/generation/total?building=${id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicImages: function (source) {
        return Axios.get(
            `${URL}/public/images`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicCurrentReading: function (source, building) {
        return Axios.get(
            `${MICROSERVICE_URL}/public/electric/current?building=${building}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getPublicCurrentReadingD: function (source, building, type) {
        return Axios.get(
            `${MICROSERVICE_URL}/public/digital/current?building=${building}&type=${type}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getMaintenanceGroups: function (source, buildingId) {
        return Axios.get(
            `${URL}/maintenance/groups/${buildingId}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getMaintenanceGroup: function (source, buildingId) {
        return Axios.get(
            `${URL}/maintenance/group/${buildingId}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getMaintenanceGroupByCircuit: function (source, device, channel_id) {
        return Axios.get(
            `${URL}/maintenance/circuit?device=${device}&channel_id=${channel_id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    createMaintenanceGroup: function (payload) {
        return Axios.post(
            `${URL}/maintenance/group`,
            payload,
            { withCredentials: true }
        );
    },
    updateMaintenanceGroup: function (payload, group) {
        return Axios.put(
            `${URL}/maintenance/group/${group}`,
            payload,
            { withCredentials: true }
        );
    },
    createMaintenanceActivity: function (payload) {
        return Axios.post(
            `${URL}/maintenance/activity`,
            payload,
            { withCredentials: true }
        );
    },
    updateMaintenanceActivity: function (payload, activity) {
        return Axios.put(
            `${URL}/maintenance/activity/${activity}`,
            payload,
            { withCredentials: true }
        );
    },
    createMaintenanceLog: function (payload) {
        return Axios.post(
            `${URL}/maintenance/log`,
            payload,
            { withCredentials: true }
        );
    },
    updateMaintenanceLog: function (payload, log) {
        return Axios.put(
            `${URL}/maintenance/log/${log}`,
            payload,
            { withCredentials: true }
        );
    },
    createSubscriber: function (payload) {
        return Axios.post(
            `${URL}/alert/subscriber`,
            payload,
            { withCredentials: true }
        )
    },
    updateSubscriber: function (payload, id) {
        return Axios.put(
            `${URL}/alert/subscriber/${id}`,
            payload,
            { withCredentials: true }
        )
    },
    getAlertSeries: function (source, start, end, channel_id, detail, type) {
        return Axios.get(
            `${MICROSERVICE_URL}/alert/series?start=${start}&end=${end}&channel_id=${channel_id}&detail=${detail}&type=${type}`,
            {  withCredentials: true, cancelToken: source.token }
        );
    },
    getAlertsByCirctuit: function (source, building_id) {
        return Axios.get(
            `${URL}/alert/count?building_id=${building_id}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getAlertTypes: function (source) {
        return Axios.get(
            `${URL}/alert/types`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getAlertGroups: function (source, buildingId) {
        return Axios.get(
            `${URL}/alert/groups/${buildingId}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    getAlertGroup: function (groupId) {
        return Axios.get(
            `${URL}/alert/group/${groupId}`,
            { withCredentials: true }
        );
    },
    getAlertGroupByCircuit: function (source, device, channel_id, type) {
        return Axios.get(
            `${URL}/alert/circuit?device=${device}&channel_id=${channel_id}&type=${type}`,
            { withCredentials: true, cancelToken: source.token }
        );
    },
    createAlertGroup: function (payload) {
        return Axios.post(
            `${URL}/alert/group`,
            payload,
            { withCredentials: true }
        );
    },
    updateAlertGroup: function (payload, group) {
        return Axios.put(
            `${URL}/alert/group?id=${group}`,
            payload,
            { withCredentials: true }
        );
    },
    createAlertActivity: function (payload) {
        return Axios.post(
            `${URL}/alert/activity`,
            payload,
            { withCredentials: true }
        );
    },
    updateAlertActivity: function (payload, activity) {
        return Axios.put(
            `${URL}/alert/activity/${activity}`,
            payload,
            { withCredentials: true }
        );
    },
    createWorkflow: function (payload) {
        return Axios.post(
            `${URL}/alert/workflow`,
            payload,
            { withCredentials: true }
        );
    },
    updateWorkflow: function (payload, id){
        return Axios.put(
            `${URL}/alert/workflow/${id}`,
            payload,
            { withCredentials: true }
        );
    },
    updateLog: function (payload, id){
        return Axios.put(
            `${URL}/alert/log/${id}`,
            payload,
            { withCredentials: true }
        );
    },
    cancelToken: function () {
        const cancelToken = Axios.CancelToken;
        return cancelToken.source();
    },
    cancel: function (token) {
        if (token) token.cancel('cancelling');
    }
};

export default API; 