import { useContext, useEffect, useState } from "react";
import GlobalSVG from '../../utils/GlobalSVG';
import MaintenanceActivityLine from "../MaintenanceActivityLine";
import moment from 'moment';
import { AppContext } from '../../utils/ContextProvider';

const MaintenanceActivitiesLines = (props) => {

    const [sorting, setSorting] = useState({ percent: false })
    const [activities, setActivities] = useState(null)
    const [adding, setAdding] = useState(null)
    const [equipmentList, setEquipmentList] = useState(null)
    const context = useContext(AppContext);
    const [groupsList, setGroupsList] = useState(null)

    useEffect(() => {
        if (!context.getBuildingInfo) return;
        let tempEquipment = context.getBuildingInfo.e_channels.filter(e => e.circuit_type === 'E' && !e.virtual)
        let seen = new Set()
        let finalDict = {}
        tempEquipment.forEach(e => {
            if (seen.has(e.channel_name + e.channel_id)) return
            seen.add(e.channel_name + e.channel_id)
            finalDict[e.channel_name] = {channel_id: e.channel_id, device_id: e.device_id, channel_name: e.channel_name}
        })
        setEquipmentList(finalDict)
    }, [context.getBuildingInfo])

    useEffect(() => {
        if (!props.byCircuit || !props.groups) return;
        let tempGroups = JSON.parse(JSON.stringify(props.groups))
        let seen = new Set()
        let finalDict = {}
        tempGroups.forEach(e => {
            e.activities.forEach(a => {
                if (seen.has(e.type + a.id)) return
                seen.add(e.type + a.id)
                finalDict[e.type] = { id: a.id, threshold: a.threshold, tolerance: a.tolerance, timebase: a.timebase }
            })
        })
        setGroupsList(finalDict)
    }, [props.byCircuit, props.groups])

    useEffect(() => {
        if (!props.groups || (!props.group && !props.byCircuit)) return
        let tempGroup = { activities: [] }
        if (props.byCircuit) {
            props.groups.forEach(group => {
                tempGroup.activities.push(...JSON.parse(JSON.stringify(group.activities)))
            })
        } else {
            tempGroup = JSON.parse(JSON.stringify(props.group))
        }
        for (let key in tempGroup) {
            if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                tempGroup[key] = Math.round(tempGroup[key])
            }
        }

        for (let field in sorting) {
            tempGroup.activities.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null) return sorting[field] ? 1 : -1;
                if (b[field] === null) return sorting[field] ? -1 : 1;

                switch (field) {
                    case 'lastActionDate':
                    case 'updated_date':
                        return sorting[field]
                            ? moment(a[field]).diff(moment(b[field]))
                            : moment(b[field]).diff(moment(a[field]));
                    case 'channel_name':
                        return sorting[field]
                            ? a[field].localeCompare(b[field])
                            : b[field].localeCompare(a[field]);
                    default:
                        return sorting[field]
                            ? a[field] - b[field]
                            : b[field] - a[field];
                }
            });
        }

        setActivities(tempGroup.activities)

    }, [props.groups, props.group, props.byCircuit, sorting])

    const handleSort = (field) => {
        setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    return (
        <div>
            <div className="flex flex-nowrap justify-between items-center mt-[30px] mb-[10px]">
                <h2 className="text-xl font-semibold">Maintenance Plan</h2>
                {!props.byCircuit && <div className="flex flex-nowrap justify-end items-center">
                    {GlobalSVG.plus2()}
                    <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setAdding(true)}>Add Activity</p>
                </div>}
            </div>
            <div className='overflow-auto'>
                <div className='flex flex-nowrap justify-between p-4 my-2 w-full'>
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('lastActionDate')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("lastActionDate") ? 'text-[green]' : ''}`}>Last Action Date</span>
                        {sorting.hasOwnProperty("lastActionDate") && sorting.lastActionDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("lastActionDate") && sorting.lastActionDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    {equipmentList && <div className='flex flex-nowrap justify-start items-center w-[150px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('channel_name')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("channel_name") ? 'text-[green]' : ''}`}>Equipment</span>
                        {sorting.hasOwnProperty("channel_name") && sorting.channel_name === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("channel_name") && sorting.channel_name === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>}
                    {!equipmentList && <div className='flex flex-nowrap justify-start items-center w-[150px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('type')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("type") ? 'text-[green]' : ''}`}>Activity</span>
                        {sorting.hasOwnProperty("type") && sorting.type === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("type") && sorting.type === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>}
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('timebase')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("timebase") ? 'text-[green]' : ''}`}>Time base</span>
                        {sorting.hasOwnProperty("timebase") && sorting.timebase === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("timebase") && sorting.timebase === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[140px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('threshold')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("threshold") ? 'text-[green]' : ''}`}>Target Maint. Interval</span>
                        {sorting.hasOwnProperty("threshold") && sorting.threshold === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("threshold") && sorting.threshold === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('tolerance')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("tolerance") ? 'text-[green]' : ''}`}>Tolerance</span>
                        {sorting.hasOwnProperty("tolerance") && sorting.tolerance === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("tolerance") && sorting.tolerance === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[100px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('dueIn')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("dueIn") ? 'text-[green]' : ''}`}>Due In</span>
                        {sorting.hasOwnProperty("dueIn") && sorting.dueIn === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("dueIn") && sorting.dueIn === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[110px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('percent')}>
                        <span className={`mr-2 ${sorting.hasOwnProperty("percent") ? 'text-[green]' : ''}`}>Threshold Used</span>
                        {sorting.hasOwnProperty("percent") && sorting.percent === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            sorting.hasOwnProperty("percent") && sorting.percent === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start w-[75px]'></div>
                </div>
                {
                    adding &&
                    <MaintenanceActivityLine activity={{}} adding={adding} maintenanceId={props.group.id} setAdding={setAdding} equipmentList={equipmentList} group={props.group} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                }
                {
                    activities && activities.map((e, ix) => (
                        <MaintenanceActivityLine key={ix + " " + e.id} activity={e} equipmentList={equipmentList} groupsList={groupsList} group={props.group} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                    ))
                }
            </div>
        </div>
    )
}

export default MaintenanceActivitiesLines;