import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';

const Users = () => {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [buildingUsers, setBuildingUsers] = useState([])
    const [selectedBuildings, setSelectedBuildings] = useState([])
    const [loading, setLoading] = useState(true)
    const [show, setShow] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_users_list", "create_users", "update_users"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/operators')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        API.getUsers(cancelToken).then(res => {
            setLoading(false)
            setData(res.data)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })

        API.getBuildingsUsers(cancelToken).then(res => {
            setLoading(false)
            setBuildingUsers(res.data)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])

    useEffect(() => {
        if (data.length === 0 || Object.keys(buildingUsers).length === 0) return
        if (selectedBuildings.length === 0) setFilteredData(data)
        else {
            let usersList = []
            for (let building of selectedBuildings) {
                usersList = usersList.concat(buildingUsers[building])
            }
            let tempFiltered = data.filter(e => usersList.includes(e.username))
            setFilteredData(tempFiltered)
        }
    }, [data, buildingUsers, selectedBuildings])

    const selectBuilding = (elem) => {
        let tempSelected = [...selectedBuildings];
        if (elem.target.value && elem.target.value !== "all") {
            if (elem.target.checked) {
                tempSelected.push(elem.target.value);
                tempSelected = Array.from(new Set(tempSelected));
            } else {
                const index = tempSelected.indexOf(elem.target.value);
                if (index > -1) {
                    tempSelected.splice(index, 1);
                }
            }
            setSelectedBuildings(tempSelected);
        }
    };

    const sortOptions = (a, b)=> {
        var titleA = String(a).toUpperCase();
        var titleB = String(b).toUpperCase();
        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0;
    }

    return (
        <div>
            {buildingUsers && Object.keys(buildingUsers).length > 0 ? (
                <div className="relative card w-full py-2 px-4 background-white flex flex-nowrap justify-end items-center">
                    <div className="flex itens-center pl-2 justify-center items-center">
                        {GlobalSVG.menuExport()}
                        <p className='mr-2'>Filter</p>
                    </div>
                    <div className='ml-6 cursor-pointer w-[200px] h-[36px]  flex items-center justify-center border border-1 rounded transparent border-[#C1C1C1] text-base text-normal text-[#AFAAB1]' onClick={() => setShow(!show)}>
                        <span className="inline-block ">Select Buildings</span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className={`ml-12 mr-0 inline-block w-3.5 h-3.5 transform transition duration-150 ${show ? "rotate-180" : ""
                                }`}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            />
                        </svg>
                    </div>
                    {show && <div
                        className={`absolute top-[10%] mt-9 w-[200px] h-[200px] overflow-auto flex flex-col p-2 whitespace-nowrap border border-[#C1C1C1] z-20 rounded bg-[#FFF]`}>
                        <div className="flex flex-row " key={"all"}>
                            <button
                                key={"all"}
                                onClick={(e) => selectBuilding(e)}
                                value={"all"}
                                className="inline-block mr-2 cursor-pointer"
                            >
                                <p className="text-grey text-sm text-ellipsis overflow-hidden" onClick={() => setSelectedBuildings([])}>
                                    Reset selections
                                </p>
                            </button>
                        </div>
                        {Object.keys(buildingUsers).sort((a,b)=>sortOptions(a,b)).map((elem, i) => {
                            return (
                                <div className="flex flex-row p-1" key={i} onClick={(e) => selectBuilding(e)}>
                                    <input
                                        type="checkbox"
                                        key={i}
                                        name={"building"}
                                        value={elem}
                                        checked={selectedBuildings.includes(elem)}
                                        className="inline-block mr-2"
                                    />
                                    <p className="text-ellipsis overflow-hidden text-sm">{elem}</p>
                                </div>
                            );
                        })}
                    </div>}
                </div>
            ) : (
                <Loader />
            )}
            <div className='tablet:p-8 mobile:p-3 min-h-[90vh]'>
                <div className='card tablet:p-8 mobile:p-3 min-h-[80vh]'>
                    <div className='h-5/6 w-full border-light-green'>
                        <div className='h-[60vh] overflow-y-auto'>
                            {
                                loading === true ?
                                    <Loader />
                                    :
                                    filteredData.map((e, index) => (
                                        <Link key={index + "tableitem"} className='w-full p-4 input-border flex flex-wrap justify-between items-center' to={`/user?user=${e.username}`}>
                                            <p>{e.firstname} {e.lastname}</p>
                                            <div className='flex flex-nowrap justify-end items-center gap-10'>
                                                <div className='mr-8'>
                                                    <span className='text-base text-grey font-medium'>Last Login</span>
                                                    <p className='text-sm' text-grey>{e.last_login ? new Date(e.last_login + " UTC").toLocaleString() : " - "}</p>
                                                </div>
                                                <div className='flex flex-nowrap jusify-center items-center'>
                                                    <p className='ml-6'>{e.is_active ? "Active" : "Inactive"}</p>
                                                    <div className={`rouned-full ${e.is_active ? "active-user" : "inactive-user"}`}></div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                            }
                        </div>
                    </div>
                    <Link to={`/user/create`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                        <span className='text-lg mr-2'>+</span>
                        Add new user
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Users;