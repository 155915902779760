import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import SlideToggle from '../../components/SlideToggle'
import FilterableSelect from '../../components/FilterableSelect';
import CalendarModal from '../../components/CalendarModal';

const Actuator = () => {

    const location = useLocation();
    const [actuator, setActuator] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [deviceOptions, setDeviceOptions] = useState(null)
    const [ports, setPorts] = useState({ input_1: null, input_2: null, output_1: null, output_2: null })
    const [inputs, setInputs] = useState({})
    const [relays, setRelays] = useState({})
    const [viewCalendar, setViewCalendar] = useState(false)
    const [availablePorts, setAvailablePorts] = useState([])
    const [actuatorData, setActuatorData] = useState({
        device_id: "",

    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();

        API.getDigitalChannels(cancelToken).then(res => {
            setLoading(false)
            setDeviceOptions(res.data.map(e => ({ id: e.id, building: e.building, building_id: e.building_id, device_id: e.device_id, channel_name: e.channel_name })))
            setAvailablePorts(Array.from({ length: 18 }, (_, i) => i + 1))
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["actuator_settings"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/buildings')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (['put', 'post'].includes(getPutPost)) return
        if (location.pathname === '/actuator/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            let tempActuator = queryParams.get('actuator')
            if (!tempActuator) navigate('/settings/actuators')
            setGetPutPost('get')
            setActuator(tempActuator)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();

        if (getPutPost === 'get' && actuator !== null) {
            setLoading(true)
            API.getActuator(cancelToken, actuator).then(res => {
                setLoading(false)
                setActuatorData(res.data)
                let portsDict = { 0: "input", 1: "output" }
                let positionDict = { 0: "closed", 1: "half", 2: "partial", 3: "open" }
                const tempPorts = res.data.ports.reduce((acc, item) => {
                    acc[`${portsDict[item.port_type]}_${item.port_number}`] = item;
                    return acc;
                }, {});
                const chanPort = res.data.ports.reduce((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {});
                setPorts(tempPorts)
                const tempInputs = res.data.positions.reduce((acc, item) => {
                    if (item.type === 0) {
                        acc[`${positionDict[item.actuator_status]}_${portsDict[item.type]}_${chanPort[item.actuator_channel_id].port_number}`] = item;
                    }
                    return acc;
                }, {});
                setInputs(tempInputs)
                const tempRelays = res.data.positions.reduce((acc, item) => {
                    if (item.type === 1) {
                        acc[`${positionDict[item.actuator_status]}_${portsDict[item.type]}_${chanPort[item.actuator_channel_id].port_number}`] = item;
                    }
                    return acc;
                }, {});
                setRelays(tempRelays)
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A'
                });

            })
        }

        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, actuator, context.getUserInfo])

    const updateDevice = (e) => {
        const { name, value } = e.target;
        setActuatorData(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    const portTypeDict = { "input": "0", "output": "1" }

    const updatePorts = (e) => {
        const { name, value } = e.target;
        let tempPorts = JSON.parse(JSON.stringify(ports))
        if (tempPorts[name]) {
            tempPorts[name].channel = value
        } else {
            let port_type = portTypeDict[name.split("_")[0]]
            let port_number = name.split("_")[1]
            tempPorts[name] = { channel: value, port_type: port_type, port_number: port_number }
        }
        setPorts(tempPorts)
    }

    const actuatorStatusDict = { "closed": "0", "half": "1", "partial": "2", "open": "3" }

    const updateInputs = (e) => {
        const { name, value } = e.target;
        let tempInputs = JSON.parse(JSON.stringify(inputs))
        if (tempInputs[name]) {
            tempInputs[name].port_position = value
        } else {
            let actuator_status = actuatorStatusDict[name.split("_")[0]]
            let port_type = name.split("_")[1]
            let port_number = name.split("_")[2]
            tempInputs[name] = { type: 0, actuator_status: actuator_status, port_position: value, channel: ports[port_type + "_" + port_number].channel }
        }
        setInputs(tempInputs)
    }

    const updateRelays = (e) => {
        const { name, value } = e.target;
        let tempRelays = JSON.parse(JSON.stringify(relays))
        if (tempRelays[name]) {
            tempRelays[name].port_position = value
        } else {
            let actuator_status = actuatorStatusDict[name.split("_")[0]]
            let port_type = name.split("_")[1]
            let port_number = name.split("_")[2]
            tempRelays[name] = { type: 1, actuator_status: actuator_status, port_position: value, channel: ports[port_type + "_" + port_number].channel }
        }
        setRelays(tempRelays)
    }

    useEffect(() => {
        if (actuatorData.id) return
        if (!actuatorData.device_id || !actuatorData.channel_id) return
        setActuatorData(prev => ({ ...prev, id: `${actuatorData.device_id}_a_${actuatorData.channel_id}` }))
    }, [actuatorData])

    const validateKeys = (payload, keys, errors, relevant_key = null) => {
        for (let key of keys) {
            if (!(key in payload) || !payload[key] || `${payload[key]}`.trim() === '' || (relevant_key && `${payload[key][relevant_key]}`.trim() === '')) {
                errors[key] = 'Invalid Value'
            }
        }
    }

    const validateDuplicates = (payload, errors) => {
        let inputs = Object.values(payload).map(e => e.port_type === '0' ? parseInt(e.channel) : null);
        let outputs = Object.values(payload).map(e => e.port_type === '1' ? parseInt(e.channel) : null);
        let inputsDuplicates = [...new Set(inputs.filter((num, i) => inputs.indexOf(num) !== i && !isNaN(num)))];
        let outputsDuplicates = [...new Set(outputs.filter((num, i) => outputs.indexOf(num) !== i && !isNaN(num)))];
        if (inputsDuplicates.length > 0) {
            for (const [key, value] of Object.entries(payload)) {
                if (inputsDuplicates.includes(parseInt(value.channel)) && value.port_type === '0') {
                    errors[key] = 'Duplicate Value'
                }
            }
        }
        if (outputsDuplicates.length > 0) {
            for (const [key, value] of Object.entries(payload)) {
                if (outputsDuplicates.includes(parseInt(value.channel)) && value.port_type === '1') {
                    errors[key] = 'Duplicate Value'
                }
            }
        }
    }

    const postActuator = async (e) => {
        let tempErrors = {}

        setLoading(true)

        const cancelToken = API.cancelToken();

        if (getPutPost === 'post') {

            let payload = JSON.parse(JSON.stringify(actuatorData))
            let mandatoryActuator = ['id', 'device_id', 'channel_id', 'name']
            validateKeys(payload, mandatoryActuator, tempErrors)

            let portsPayload = JSON.parse(JSON.stringify(ports))
            let mandatoryPorts = ['input_1', 'input_2', 'output_1']
            validateKeys(portsPayload, mandatoryPorts, tempErrors, 'channel')
            validateDuplicates(portsPayload, tempErrors)

            let inputsPayload = JSON.parse(JSON.stringify(inputs))
            let mandatoryInputs = ['open_input_1', 'open_input_2', 'closed_input_1', 'closed_input_2', 'partial_input_1', 'partial_input_2']
            validateKeys(inputsPayload, mandatoryInputs, tempErrors, 'port_position')

            let relaysPayload = JSON.parse(JSON.stringify(relays))
            let mandatoryRelays = ['open_output_1', 'closed_output_1', 'partial_output_1']
            validateKeys(relaysPayload, mandatoryRelays, tempErrors, 'port_position')

            if (Object.values(tempErrors).length > 0) {
                setError(tempErrors)
                setLoading(false)
                return
            }

            payload.ports = Object.values(portsPayload).map(e => ({ ...e, actuator_id: payload.id })).filter(e => `${e.channel}`.trim() !== '')
            payload.positions = [...Object.values(inputsPayload).filter(e => `${e.port_position}`.trim() !== ''), ...Object.values(relaysPayload).filter(e => `${e.port_position}`.trim() !== '')]

            API.createActuator(cancelToken, payload).then(res => {
                setGetPutPost("get")
                navigate(`/actuator?actuator=${payload.id}&building=`)
                if (payload.auto_enabled) setViewCalendar(true)
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Oops!',
                        text: "An error occurred, please contact us.",
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }).finally(e => {
                setLoading(false)
            })
        } else {
            let payload = JSON.parse(JSON.stringify(actuatorData))
            let allowed = ['name', 'notes', 'description']
            payload = Object.fromEntries(Object.entries(payload).filter(([key]) => allowed.includes(key)));

            API.updateActuator(cancelToken, actuatorData.id, payload).then(res => {
                setGetPutPost('get')
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                        
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Oops!',
                        text: "An error occurred, please contact us.",
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }).finally(e => {
                setLoading(false)
            })
        }
    }

    return (
        <div className='device min-w-[1230px]'>
            <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                    <Link to={queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
                        `/settings/actuators?building=${queryParams.get('building')}` : `/settings/actuators`}>
                        Actuators
                    </Link>
                    {
                        GlobalSVG.rightArrow()
                    }
                    <p className='font-medium'>
                        {
                            ['get', 'put'].includes(getPutPost) ? actuatorData.name : "Add Actuator"

                        }
                    </p>
                    {loading &&
                        <Loader />}
                </div>
                <div className='device'>
                    <div className='min-h-[80vh]'>
                        <div className='card tablet:p-8 mobile:p-3 min-h-[70vh] mb-6'>
                            <div className='input-border pb-8 items-center mb-6'>
                                <div className='flex flex-wrap w-full items-center justify-between'>
                                    <input
                                        placeholder='Actuator Name'
                                        className='p-1 text-3xl p-2 outline-none hide-input-background laptop:w-5/12 tablet:w-full mobile:w-full'
                                        type="text"
                                        name='name'
                                        disabled={getPutPost === 'get'}
                                        onChange={updateDevice}
                                        defaultValue={actuatorData.name ? actuatorData.name : ""} />
                                    <div className="flex flex-nowrap items-center justify-between">
                                        <div className="flex flex-nowrap items-center justify-center mr-4">
                                            Auto-Enable Actuator
                                            <SlideToggle disabled={getPutPost !== 'post'} setOptions={setActuatorData} options={actuatorData} id={"auto_enabled"} />
                                        </div>
                                        {getPutPost !== 'post' &&
                                            <div className="flex flex-nowrap items-center justify-center">
                                                <p className='mr-4'>Time Range</p>
                                                <div className='cursor-pointer' onClick={() => setViewCalendar(!viewCalendar)}>
                                                    {GlobalSVG.calendar()}
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                {
                                    error && error.name &&
                                    <p className='text-red text-xs'>{error.name}</p>

                                }
                            </div>
                            {
                                loading &&
                                <Loader />
                            }
                            <div className='input-border py-3'>
                                <div className='flex flex-wrap'>
                                    <div className='flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center mb-6'>
                                        <div className='w-10/12 flex flex-nowrap items-center'>
                                            <label className='w-1/3 text-base input-label-color' htmlFor="id">Actuator ID</label>
                                            <input
                                                placeholder='Actuator ID'
                                                className='p-1 w-full mx-2 text-base input-color outline-none hide-input-background p-2'
                                                type="text"
                                                name="id"
                                                value={actuatorData.id ? actuatorData.id : ""}
                                                disabled={true}
                                                onChange={updateDevice} />
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap laptop:w-1/2 mobile:w-full items-center mb-6'>
                                        <div className='w-10/12 flex flex-nowrap items-center'>
                                            <label className='w-1/3 text-base input-label-color' htmlFor="building_id">Building</label>
                                            <input
                                                placeholder='Building'
                                                className='p-1 w-full mx-2 text-base input-color outline-none hide-input-background p-2'
                                                type="text"
                                                value={actuatorData.device_id && deviceOptions ? deviceOptions.find(e => e.device_id === actuatorData.device_id).building : ""}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-wrap'>
                                    <div className='flex flex-wrap laptop:w-1/2 mobile:w-full items-center mb-6'>
                                        <div className='w-10/12 flex flex-nowrap items-center'>
                                            <label className='w-1/3 text-base input-label-color' htmlFor="device_id">Linked Device</label>
                                            <FilterableSelect
                                                options={[...new Set(deviceOptions && deviceOptions.map(e => e.device_id))]}
                                                disabled={getPutPost !== 'post'}
                                                placeholder={actuatorData.device_id ? actuatorData.device_id : "Linked Device"}
                                                name='device_id'
                                                onChange={updateDevice} />
                                            {
                                                error && error.device_id &&
                                                <p className='text-red text-xs'>{error.device_id}</p>

                                            }
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center mb-6'>
                                        <div className='w-10/12 flex flex-nowrap items-center'>
                                            <label className='w-1/3 text-base input-label-color' htmlFor="channel_id">Utility Channel</label>
                                            <select
                                                className='p-1 w-full mx-2 text-base input-color p-2 outline-none hide-input-background'
                                                name="channel_id"
                                                disabled={getPutPost !== 'post' || !actuatorData.device_id}
                                                value={actuatorData.channel_id ? actuatorData.channel_id : ""}
                                                onChange={updateDevice}>
                                                <option value="">No Selection</option>
                                                {deviceOptions && deviceOptions.filter(e => actuatorData.device_id ? e.device_id === actuatorData.device_id : true).map((e, idx) => (
                                                    <option key={idx + 'utilitychanneloption'} value={e.id}>{e.channel_name}</option>
                                                ))}
                                            </select>
                                            {
                                                error && error.channel_id &&
                                                <p className='text-red text-xs'>{error.channel_id}</p>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='input-border py-6'>
                                <p className='font-medium'>Digital Input</p>
                                <div className='flex flex-wrap justify-center items-center'>
                                    <div className='flex flex-wrap w-11/12 justify-center items-center pt-3 pb-3 mb-6'>
                                        <div className='laptop:w-1/4 tablet:w-1/4 mobile:w-full flex flex-wrap items-center'>
                                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="input_1">Input 1</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name="input_1"
                                                value={ports.input_1 ? ports.input_1.channel : ""}
                                                disabled={getPutPost !== 'post'}
                                                onChange={updatePorts}>
                                                <option value="">No Selection</option>
                                                {availablePorts.map((e, idx) => (
                                                    <option key={idx + 'availablechannels'} value={e}>{e}</option>
                                                ))}
                                            </select>
                                            {
                                                error && error.input_1 &&
                                                <p className='text-red text-xs'>{error.input_1}</p>

                                            }
                                        </div>
                                        <div className='laptop:w-1/4 tablet:w-1/4 mobile:w-full  flex flex-wrap items-center'>
                                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="input_2">Input 2</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name="input_2"
                                                value={ports.input_2 ? ports.input_2.channel : ""}
                                                disabled={getPutPost !== 'post'}
                                                onChange={updatePorts}>
                                                <option value="">No Selection</option>
                                                {availablePorts.map((e, idx) => (
                                                    <option key={idx + 'availablechannels'} value={e}>{e}</option>
                                                ))}
                                            </select>
                                            {
                                                error && error.input_2 &&
                                                <p className='text-red text-xs'>{error.input_2}</p>

                                            }
                                        </div>
                                        <div className='laptop:w-1/4 tablet:w-1/4 mobile:w-full  flex flex-wrap items-center'>
                                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="output_1">Output 1</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name="output_1"
                                                value={ports.output_1 ? ports.output_1.channel : ""}
                                                disabled={getPutPost !== 'post'}
                                                onChange={updatePorts}>
                                                <option value="">No Selection</option>
                                                {availablePorts.map((e, idx) => (
                                                    <option key={idx + 'availablechannels'} value={e}>{e}</option>
                                                ))}
                                            </select>
                                            {
                                                error && error.output_1 &&
                                                <p className='text-red text-xs'>{error.output_1}</p>

                                            }
                                        </div>
                                        <div className='laptop:w-1/4 tablet:w-1/4 mobile:w-full  flex flex-wrap items-center'>
                                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="output_2">Output 2</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name="output_2"
                                                value={ports.output_2 ? ports.output_2.channel : ""}
                                                disabled={getPutPost !== 'post'}
                                                onChange={updatePorts}>
                                                <option value="">No Selection</option>
                                                {availablePorts.map((e, idx) => (
                                                    <option key={idx + 'availablechannels'} value={e}>{e}</option>
                                                ))}
                                            </select>
                                            {
                                                error && error.output_2 &&
                                                <p className='text-red text-xs'>{error.output_2}</p>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='input-border py-6'>
                                <div className='flex flex-nowrap justify-center items-center'>
                                    <div className='w-1/12 mt-[35px]'>
                                        <p className='h-[55px] font-bold text-[#686276]'>Open</p>
                                        <p className='h-[55px] font-bold text-[#686276]'>Closed</p>
                                        <p className='h-[55px] font-bold text-[#686276]'>Half</p>
                                        <p className='h-[55px] font-bold text-[#686276]'>Partial</p>
                                    </div>
                                    <div className='w-5/12 px-10'>
                                        <p className='font-medium'>Input Position</p>
                                        <div className='w-full pt-3 pb-3 mb-6'>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="open_input_1">Input 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="open_input_1"
                                                        value={inputs.open_input_1 ? inputs.open_input_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_1}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.open_input_1 &&
                                                        <p className='text-red text-xs'>{error.open_input_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="open_input_2">Input 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="open_input_2"
                                                        value={inputs.open_input_2 ? inputs.open_input_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_2}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.open_input_2 &&
                                                        <p className='text-red text-xs'>{error.open_input_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="closed_input_1">Input 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="closed_input_1"
                                                        value={inputs.closed_input_1 ? inputs.closed_input_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_1}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.closed_input_1 &&
                                                        <p className='text-red text-xs'>{error.closed_input_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="closed_input_2">Input 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="closed_input_2"
                                                        value={inputs.closed_input_2 ? inputs.closed_input_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_2}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.closed_input_2 &&
                                                        <p className='text-red text-xs'>{error.closed_input_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="half_input_1">Input 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="half_input_1"
                                                        value={inputs.half_input_1 ? inputs.half_input_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_1}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.half_input_1 &&
                                                        <p className='text-red text-xs'>{error.half_input_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="half_input_2">Input 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="half_input_2"
                                                        value={inputs.half_input_2 ? inputs.half_input_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_2}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.half_input_2 &&
                                                        <p className='text-red text-xs'>{error.half_input_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="partial_input_1">Input 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="partial_input_1"
                                                        value={inputs.partial_input_1 ? inputs.partial_input_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_1}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.partial_input_1 &&
                                                        <p className='text-red text-xs'>{error.partial_input_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="partial_input_2">Input 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="partial_input_2"
                                                        value={inputs.partial_input_2 ? inputs.partial_input_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.input_2}
                                                        onChange={updateInputs}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.partial_input_2 &&
                                                        <p className='text-red text-xs'>{error.partial_input_2}</p>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-5/12 pl-10 border-l border-[#C1C1C1]'>
                                        <p className='font-medium'>Relay Position</p>
                                        <div className='w-full pt-3 pb-3 mb-6'>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="open_output_1">Output 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="open_output_1"
                                                        value={relays.open_output_1 ? relays.open_output_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_1}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.open_output_1 &&
                                                        <p className='text-red text-xs'>{error.open_output_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="open_output_2">Output 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="open_output_2"
                                                        value={relays.open_output_2 ? relays.open_output_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_2}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.open_output_2 &&
                                                        <p className='text-red text-xs'>{error.open_output_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="closed_output_1">Output 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="closed_output_1"
                                                        value={relays.closed_output_1 ? relays.closed_output_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_1}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.closed_output_1 &&
                                                        <p className='text-red text-xs'>{error.closed_output_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="closed_output_2">Output 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="closed_output_2"
                                                        value={relays.closed_output_2 ? relays.closed_output_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_2}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.closed_output_2 &&
                                                        <p className='text-red text-xs'>{error.closed_output_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="half_output_1">Output 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="half_output_1"
                                                        value={relays.half_output_1 ? relays.half_output_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_1}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.half_output_1 &&
                                                        <p className='text-red text-xs'>{error.half_output_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="half_output_2">Output 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="half_output_2"
                                                        value={relays.half_output_2 ? relays.half_output_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_2}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.half_output_2 &&
                                                        <p className='text-red text-xs'>{error.half_output_2}</p>

                                                    }
                                                </div>
                                            </div>
                                            <div className="flex flex-nowrap items-center justify-center h-[55px]">
                                                <div className='w-1/2 flex flex-wrap items-center mr-6'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="partial_output_1">Output 1</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="partial_output_1"
                                                        value={relays.partial_output_1 ? relays.partial_output_1.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_1}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.partial_output_1 &&
                                                        <p className='text-red text-xs'>{error.partial_output_1}</p>

                                                    }
                                                </div>
                                                <div className='w-1/2 flex flex-wrap items-center'>
                                                    <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="partial_output_2">Output 2</label>
                                                    <select
                                                        className='p-1 mobile:w-1/2 tablet:w-8/12 text-base input-color p-2 outline-none hide-input-background'
                                                        name="partial_output_2"
                                                        value={relays.partial_output_2 ? relays.partial_output_2.port_position : ""}
                                                        disabled={getPutPost !== 'post' || !ports.output_2}
                                                        onChange={updateRelays}>
                                                        <option value="">No Selection</option>
                                                        <option value="1">High</option>
                                                        <option value="0">Low</option>
                                                    </select>
                                                    {
                                                        error && error.partial_output_2 &&
                                                        <p className='text-red text-xs'>{error.partial_output_2}</p>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pt-6'>
                                <p className='font-medium'>Notes</p>
                                <div className='flex flex-wrap justify-center items-center'>
                                    <div className='flex flex-wrap w-full justify-center items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <textarea
                                                className='p-1 w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name='notes'
                                                value={actuatorData.notes}
                                                disabled={getPutPost === 'get'}
                                                onChange={updateDevice}
                                            />
                                            {
                                                error && error.notes &&
                                                <p className='text-red text-xs'>{error.notes}</p>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pb-6'>
                                <p className='font-medium'>Description</p>
                                <div className='flex flex-wrap justify-center items-center'>
                                    <div className='flex flex-wrap w-full justify-center items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <textarea
                                                className='p-1 w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                                name='description'
                                                value={actuatorData.description}
                                                disabled={getPutPost === 'get'}
                                                onChange={updateDevice}
                                            />
                                            {
                                                error && error.description &&
                                                <p className='text-red text-xs'>{error.description}</p>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer capitalize' onClick={() => setGetPutPost('put')}>
                            Edit
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put', 'post'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && actuatorData.building !== "" && !loading && <button className='submit-button' onClick={postActuator}>
                            Save
                        </button>
                    }
                </div>
            </div>
            {viewCalendar && <CalendarModal building={deviceOptions && actuatorData.device_id && deviceOptions.find(e => e.device_id === actuatorData.device_id).building_id} actuator={actuatorData} setViewCalendar={setViewCalendar}/>}
        </div>
    );
};

export default Actuator;