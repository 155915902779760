import './style.css';

export default function Tooltip(props) {

    return (
        <div className="tooltip">
            {props.children}
            {
                props.show &&
                <span
                    className="tooltiptext"
                    style={{ right: props.right ? props.right : "-150%" , 
                             bottom: props.bottom ? props.bottom : "", 
                             left: props.left ? props.left : "", 
                             top: props.top ? props.top : "", 
                             width: props.width ? props.width : "",
                             textAlign: props.textAlign ? props.textAlign : "",
                             height: props.height ? props.height : ""}}
                    dangerouslySetInnerHTML={{ __html: props.text }}></span>
            }
        </div>
    );
}

