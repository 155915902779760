import LoaderWheel from "../LoaderWheel"
import { useContext, useEffect, useState } from "react"
import { AppContext } from '../../utils/ContextProvider'
import API from "../../utils/API"
import Swal from 'sweetalert2'
import GlobalSVG from "../../utils/GlobalSVG"
import { Link } from "react-router-dom"
import moment from 'moment'
import AlertWorkflowLine from "../AlertWorkflowLine"
import CircuitParamLine from "../CircuitParamLine"

const AlertLogLine = (props) => {

    const context = useContext(AppContext)
    const [saving, setSaving] = useState(false)
    const [assigning, setAssigning] = useState(false)
    const [assignee, setAssignee] = useState()
    const [buildingUsers, setBuildingUsers] = useState()
    const [closing, setClosing] = useState(false)
    const scheduleTypeDict = { "0": 'Custom', "1": 'Operating Hours', "2": 'Off-Hours' }
    const [log, setLog] = useState(null)
    const [viewDetails, setViewDetails] = useState(false)
    const statusColor = { '0': '#0538A0', '1': '#F9941E', '2': '#F75D5F' }
    const months1 = [{ "0": 'JAN' }, { "1": 'FEB' }, { "2": 'MAR' }, { "3": 'APR' }, { "4": 'MAY' }, { "5": 'JUN' }]
    const months2 = [{ "6": 'JUL' }, { "7": 'AUG' }, { "8": 'SEP' }, { "9": 'OCT' }, { "10": 'NOV' }, { "11": 'DEC' }, { "all": "All" }]
    const days1 = [{ "1": 'MON' }, { "2": 'TUE' }, { "3": 'WED' }, { "4": 'THU' }]
    const days2 = [{ "5": 'FRI' }, { "6": 'SAT' }, { "0": 'SUN' }, { "all": "All" }]
    const hours1 = [{ "0": '00' }, { "1": '01' }, { "2": '02' }, { "3": '02' }, { "4": '04' }, { "5": '05' }, { "6": '06' },
    { "7": '07' }, { "8": '08' }, { "9": '09' }, { "10": '10' }, { "11": '11' }]
    const hours2 = [{ "12": '12' }, { "13": '13' }, { "14": '14' }, { "15": '15' }, { "16": '16' }, { "17": '17' }, { "18": '18' },
    { "19": '19' }, { "20": '20' }, { "21": '21' }, { "22": '22' }, { "23": '23' }, { "all": "All" }]
    const severityDict = { '0': 'Info', '1': 'Caution', '2': 'Critical' }

    useEffect(() => {
        if (!props.log) return
        let tempLog = JSON.parse(JSON.stringify(props.log))
        tempLog.parameters.forEach(p=>{
            if(p.type === 'circuit'){
                p.value = JSON.parse(p.value)
            }
        })
        setLog(tempLog)
    }, [props.log])

    useEffect(() => {
        if (!context.getUserInfo || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getUserInfo.manage_others_alert_subscription) {
            ;
            API.getBuildingUsers(cancelToken, context.getBuildingInfo.building_id).then(res => {
                setBuildingUsers(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo, context.getBuildingInfo])

    useEffect(() => {
        if (assignee) {
            handleAssign()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignee])

    const handleAssign = () => {
        Swal.fire({
            title: 'Assign Alert.',
            text: `Would you like to assign this alert to ${assignee}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, assign it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.createWorkflow({ alert_log_id: log.id, assigned_to: assignee }).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === log.alert_group_id) {
                            let tempLog = e.logs.find(e => e.id === log.id);
                            if (tempLog) {
                                tempLog = { ...tempLog, workflow: [...tempLog.workflow, res.data] }
                            }
                            let finalLogs = e.logs.map(log => log.id === tempLog.id ? tempLog : log)
                            props.setGroup && props.setGroup({ ...e, logs: finalLogs })
                            return { ...e, logs: finalLogs };
                        }
                        return e
                    })
                    props.setGroups(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setAssignee(null)
                    setAssigning(false)
                })
            } else {
                setSaving(false)
                setAssignee(null)
                setAssigning(false)
            }
        })
    }

    const handleClose = (type) => {
        Swal.fire({
            title: 'Close Alert.',
            text: `Are you sure you want to close this alert?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.updateLog({ false_alert: type }, log.id).then(res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === log.alert_group_id) {
                            let tempLog = e.logs.find(e => e.id === log.id);
                            if (tempLog) {
                                tempLog = { ...tempLog, ...res.data }
                            }
                            let finalLogs = e.logs.map(log => log.id === tempLog.id ? tempLog : log)
                            props.setGroup && props.setGroup({ ...e, logs: finalLogs })
                            return { ...e, logs: finalLogs };
                        }
                        return e
                    })
                    props.setGroups(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setClosing(false)
                })

            } else {
                setSaving(false)
                setClosing(false)
            }
        })
    }

    return (
        <div>
            {log &&
                <div style={{ color: log.closed_at ? "#B8BBB9" : statusColor[log.severity] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full bg-[#ededed] rounded-md relative'>
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[100px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px]`}>
                                {severityDict[log.severity]}
                            </p>
                        </div>
                    }
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[120px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px]`}>
                                {log.type_name}
                            </p>
                        </div>
                    }
                    {
                        props.byCircuit && context && context.getBuildingInfo &&
                        <Link className="text-left" to={`/alerts?building=${context.getBuildingInfo.building_id}&group=${log.alert_group_id}`} target="_blank">
                            <div className="flex flex-nowrap justify-start items-center w-[120px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px] underline`}>
                                    {log.name}
                                </p>
                            </div>
                        </Link>
                    }
                    {props.equipmentList &&
                        ((log.channel_type !== 3 && props.equipmentList[log.channel_type] && props.equipmentList[log.channel_type][log.channel_id]) ?
                            <Link className="text-left" to={`/circuit?device=${props.equipmentList[log.channel_type][log.channel_id].device_id}&circuit=${encodeURIComponent(props.equipmentList[log.channel_type][log.channel_id].channel_name)}&tab=3`} target="_blank">
                                <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                    <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap underline w-[135px]`}>
                                        {props.equipmentList[log.channel_type][log.channel_id].channel_name}
                                    </p>
                                    {GlobalSVG.edit2()}
                                </div>
                            </Link>
                            :
                            <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[135px]`}>
                                    {log[log.channel_type === 3 ? 'device_name' : 'channel_name']}
                                </p>
                            </div>)
                    }
                    {context.getBuildingInfo && log.timestamp && log.channel_id && (log.channel_type !== 3 && props.equipmentList[log.channel_type] && props.equipmentList[log.channel_type][log.channel_id]) ?
                        <Link to={`/distribution?building=${context.getBuildingInfo.building_id}&start=${moment(log.timestamp).format('YYYY-MM-DD')}&end=${moment(log.timestamp).format('YYYY-MM-DD')}&channel=${encodeURIComponent(props.equipmentList[log.channel_type][log.channel_id].channel_name)}&type=${log.channel_type}`} target="_blank">
                            <div className={`underline w-[120px] text-[12px] ${log.timestamp ? "" : "pl-[25px]"}`}>{log.timestamp ? moment(log.timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                        </Link>
                        :
                        <div className={`w-[120px] text-[12px] ${log.timestamp ? "" : "pl-[25px]"}`}>{log.timestamp ? moment(log.timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                    }
                    <div className={`w-[100px] text-[12px] ${log.triggeredSince || log.triggeredSince === 0 ? "" : "pl-[25px]"}`}>{log.triggeredSince || log.triggeredSince === 0 ? parseInt(log.triggeredSince / 24) + " days, " + parseInt(log.triggeredSince) % 24 + " hours" : " - "}</div>
                    <div className={`w-[100px] text-[12px] ${log.lastAssignedTo ? "" : "pl-[25px]"}`}>{log.lastAssignedTo ? log.lastAssignedTo : " - "}</div>
                    <div className={`w-[100px] text-[12px] ${log.closed_by ? "" : "pl-[25px]"}`}>{log.closed_by ? log.closed_by : " - "}</div>
                    <div className={`w-[120px] text-[12px] ${log.closed_at ? "" : "pl-[25px]"}`}>{log.closed_at ? moment(log.closed_at).format('MMM D, YYYY h:mma') : " - "}</div>
                    <div className="">
                        <div className="flex flex-nowrap justify-end items-center">
                            <div className='cursor-pointer w-[21px] mr-[4px]' >
                                <div onClick={() => setViewDetails(!viewDetails)}>{GlobalSVG.info('black')}</div>
                            </div>

                            <div className='cursor-pointer w-[25px] relative' onClick={() => setAssigning(!assigning)}>
                                {context.getUserInfo && context.getUserInfo.manage_alert_logs && GlobalSVG.assign('black')}
                                {
                                    assigning &&
                                    < div className={`absolute top-[120%] right-[30px] w-[200px] h-[180px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                        {buildingUsers && buildingUsers.map((e, idx) => (
                                            <div key={idx + "assignees"} className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black]" onClick={() => setAssignee(e.username)} >
                                                {e.name}
                                            </div>
                                        ))
                                        }
                                    </div>
                                }
                            </div>
                            {
                                saving && assigning &&
                                <LoaderWheel />
                            }
                            {
                                !assigning &&
                                (!saving ?
                                    <div onClick={() => setClosing(!closing)} className="relative cursor-pointer">
                                        {GlobalSVG.check2()}
                                    </div>
                                    :
                                    <LoaderWheel />
                                )
                            }
                            <div className="relative">
                                {closing &&
                                    <div className={`rounded-md absolute top-[-20px] right-[24px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                        <div className="flex flex-nowrap rounded-md">
                                            <div className="flex flex-nowrap justify-center items-center p-2 text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(true)} >
                                                {GlobalSVG.false()}
                                                <div className="ml-2 mt-1">
                                                    False Alert
                                                </div>
                                            </div>
                                            <div className="flex flex-row p-2 border-l border-[#B8BBB9] text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(false)} >
                                                {GlobalSVG.check2()}
                                                <div className="ml-2 mt-1">
                                                    Complete
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                viewDetails &&
                <div className="w-full flex flex-nowrap justify-between cursor-pointer px-[20px]">
                    <p>Log Details</p>
                    <div onClick={() => setViewDetails(false)} className="mr-[10px]">
                        {GlobalSVG.close('black')}
                    </div>
                </div>
            }
            {
                log && log.schedule_type === 0 && viewDetails &&
                <div className="flex flex-nowrap items-center justify-between p-[20px] mb-[10px]">
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Months</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                months1.map(e => (
                                    <div key={'months1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.months && log.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                months2.map(e => (
                                    <div key={'months2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.months && log.months.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Days</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                days1.map(e => (
                                    <div key={'days1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.days && log.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                days2.map(e => (
                                    <div key={'days2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.days && log.days.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mr-[20px]">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Applicable Hours</p>
                        <div className="flex flex-nowrap justify-start items-center mb-1">
                            {
                                hours1.map(e => (
                                    <div key={'hours1' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.hours && log.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                        <div className="flex flex-nowrap justify-start items-center">
                            {
                                hours2.map(e => (
                                    <div key={'hours2' + Object.keys(e)[0]} className={`px-1 border rounded-sm text-[10px] mr-2 w-[33px] text-center cursor-pointer${log.hours && log.hours.split(',').includes(Object.keys(e)[0]) ? " bg-[#619E7B] text-[white] border-[#619E7B]" : " bg-[white] text-[#B8BBB9] border-[#B8BBB9]"}`}>{e[Object.keys(e)[0]]}</div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            }
            {
                log && viewDetails &&
                <div className="flex flex-nowrap items-center justify-start p-[20px] mb-[10px]">
                    <div className="mr-[20px] w-1/12 ">
                        <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Schedule Type</p>
                        <div
                            className='text-xs input-color outline-none'>
                            {[0, 1, 2].includes(log.schedule_type) ? scheduleTypeDict[log.schedule_type] : " - "}
                        </div>
                    </div>
                    {log.group_parameters.filter(e => e.type !== 'circuit').map((groupParam, idx) => (
                        <div key={'param' + idx} className="mr-[20px] w-1/12 ">
                            <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>{groupParam.name}</p>
                            <div
                                className='text-xs input-color outline-none'>
                                {log.parameters && (log.parameters.find(logParam => logParam.alert_param_type_id === groupParam.id) || { value: null }).value}
                            </div>
                        </div>
                    ))}
                </div>
            }
            {
                log && viewDetails && props.equipmentList &&
                log.parameters && log.parameters.filter(e => e.type === 'circuit').map((param, idx) => (
                    <CircuitParamLine
                        key={'paramcircuitlog' + idx}
                        param={param}
                        idx={idx}
                        edit={false}
                        activity={log}
                        equipmentList={props.equipmentList}
                    />
                ))
            }

            {log && viewDetails && <p className="px-[20px]">Log Workflow</p>}
            {log && viewDetails && log.workflow && log.workflow.map((w, idx) => (
                <AlertWorkflowLine workflow={w} log={log} groups={props.groups} group={props.group} setGroups={props.setGroups} setGroup={props.setGroup} />
            ))}
        </div >
    )
}
export default AlertLogLine