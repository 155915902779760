import { useEffect, useState } from "react";
import AlertLogLine from "../AlertLogLine";

const AlertLogsSection = (props) => {


    const [logs, setLogs] = useState(null)


    useEffect(() => {
        if (!props.groups || (!props.group && !props.byCircuit)) return
        let tempGroup = { logs: [] }
        if (props.byCircuit) {
            props.groups.forEach(group => {
                tempGroup.logs.push(...JSON.parse(JSON.stringify(group.logs)))
            })
        } else {
            tempGroup = JSON.parse(JSON.stringify(props.group))
        }
        for (let key in tempGroup) {
            if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                tempGroup[key] = Math.round(tempGroup[key])
            }
        }
        setLogs(tempGroup.logs)

    }, [props.groups, props.group, props.byCircuit])

    return (
        <div>
            <div className="flex flex-nowrap justify-between items-center mt-[30px] mb-[10px]">
                <h2 className="text-xl font-semibold">Alert Logs</h2>
            </div>
            <div className="flex flex-nowrap items-center justify-between px-4 py-2 w-full text-[12px] text-[#B8BBB9] font-semibold">
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[100px] text">Severity</p>}
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[120px] text">Type</p>}
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[120px] text">Group</p>}
                {!props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[220px] text">Monitored Point</p>}
                <p className="flex flex-nowrap justify-start items-center w-[120px] text">Triggered At</p>
                <p className="flex flex-nowrap justify-start items-center w-[100px] text">Triggered Since</p>
                <p className="flex flex-nowrap justify-start items-center w-[100px] text">Assigned To</p>
                <p className="flex flex-nowrap justify-start items-center w-[100px] text">Closed By</p>
                <p className="flex flex-nowrap justify-start items-center w-[120px] text">Closed On</p>
                <p className="flex flex-nowrap justify-start items-center w-[75px] text"></p>
            </div>
            <div className="overflow-y-auto max-h-[250px]">
                {
                    logs && logs.map((e, idx) => (
                        <AlertLogLine byCircuit={props.byCircuit} key={idx + "logs"} log={e} equipmentList={props.equipmentList} group={props.group} setGroup={props.setGroup} groups={props.groups} setGroups={props.setGroups} />
                    ))
                }
            </div>


        </div>
    )
}

export default AlertLogsSection;