import React from 'react';
import Factors from '../../components/Factors';
import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css'
import { AppContext } from '../../utils/ContextProvider';
import Roles from '../../components/Roles';
import Clients from '../../components/Clients';
import Buildings from '../../components/Buildings';
import Users from '../../components/Users';
import Operators from '../../components/Operators';
import Devices from '../../components/Devices';
import Actuators from '../../components/Actuators';

const Admin = () => {

    const location = useLocation();
    const [currentTab, setCurrentTab] = useState()
    const factors = ['costsfactors', 'users', 'clients', 'buldings', 'roles']
    const navigate = useNavigate();
    const context = useContext(AppContext)

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities",
            "update_utilities", "access_users_list", "create_users", "update_users",
            "access_roles_list", "create_roles", "update_roles", "access_clients_list",
            "create_clients", "update_clients", "access_buildings_and_operators_list",
            "create_buildings_and_operators", "update_buildings_and_operators", "actuator_settings"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/maps')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        context.resetBuilding()
        if (factors.includes(location.pathname.split("/")[1] === 'settings' &&
            !factors.includes(location.pathname.split("/")[2]))) {
            navigate('/settings/costsfactors/electricity')
        }
        setCurrentTab(location.pathname.split("/")[2])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return (
        <div className=''>
            {
                currentTab === 'costsfactors' &&
                <Factors />
            }
            {
                currentTab === 'roles' &&
                <Roles />
            }
            {
                currentTab === 'clients' &&
                <Clients />
            }
            {
                currentTab === 'buildings' &&
                <Buildings />
            }
            {
                currentTab === 'users' &&
                <Users />
            }
            {
                currentTab === 'operators' &&
                <Operators />
            }
            {
                currentTab === 'ophours' &&
                <Buildings />
            }
            {
                currentTab === 'devices' &&
                <Devices />
            }
            {
                currentTab === 'actuators' &&
                <Actuators />
            }
        </div>
    );
};

export default Admin;