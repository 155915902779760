import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highcharts";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import GlobalFuncs from "../../utils/GlobalFuncs";
import API from "../../utils/API";
import Loader from "../Loader";
import GlobalSVG from "../../utils/GlobalSVG";

export default function BarChart(props) {

    const context = useContext(AppContext);
    const [eOptions, setEOptions] = useState(null)
    const [wOptions, setWOptions] = useState(null)
    const [gOptions, setGOptions] = useState(null)
    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const [type, setType] = useState('e')
    const chartRef = useRef(null);
    const [unitDict, setUnitDict] = useState({ e: 'kWh', g: '', w: '' })
    const [hightContrast, setHighContrast] = useState(false)

    const today = new Date();
    const lastYear = new Date();
    today.setDate(today.getDate());
    lastYear.setDate(lastYear.getDate() - 365);

    useEffect(() => {
        if (!props.mainCircuitE || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();

        const get_bar_data = async () => {
            try {
                const currentDate = new Date();
                const currentYear = currentDate.getFullYear();
                const lastYear = currentDate.getFullYear() - 1;
                let startThisYear = `${currentYear}-01-01`
                let endThisYear = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
                let startLastYear = `${lastYear}-01-01`
                let endLastYear = `${lastYear}-12-31`

                
                let lastYearData = await API.getElectricity(cancelToken, startLastYear, endLastYear, props.mainCircuitE.device_id, 60, 'kw', props.mainCircuitE.circuit_name)
                let thisYearData = await API.getElectricity(cancelToken, startThisYear, endThisYear, props.mainCircuitE.device_id, 60, 'kw', props.mainCircuitE.circuit_name)
                let thisYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                let lastYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                for (let timestamp in lastYearData.data.series) {
                    let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                    lastYearBars[dateMonth] += lastYearData.data.series[timestamp]
                }
                for (let timestamp in thisYearData.data.series) {
                    let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                    thisYearBars[dateMonth] += thisYearData.data.series[timestamp]
                }

                let finalThisYear = []
                let finalLastYear = []
                let monthDict = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                for (let month of Object.keys(thisYearBars)) {
                    finalThisYear.push({
                        name: monthDict[month - 1],
                        y: thisYearBars[month],
                        label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                    })
                    finalLastYear.push({
                        name: monthDict[month - 1],
                        y: lastYearBars[month],
                        label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                    })
                }

                setEOptions([finalThisYear, finalLastYear])
                context.setFixed30DayData(e => ({ ...e, e_bar: [finalThisYear, finalLastYear] }))
            } catch (e) {
                if (e.message === 'cancelling') return
            }
        }
        if (context.getBuildingInfo.e_channels.length !== 0) {
            if (context.getFixed30DayData === null || context.getFixed30DayData.e_bar === null) {
                get_bar_data()
            } else {
                setEOptions(context.getFixed30DayData.e_bar)
            }
        } else {
            setEOptions([])
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitE, context.getBuildingInfo])

    useEffect(() => {
        if (!props.mainCircuitW || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.w_channels.length !== 0) {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const lastYear = currentDate.getFullYear() - 1;
            let startThisYear = `${currentYear}-01-01`
            let endThisYear = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
            let startLastYear = `${lastYear}-01-01`
            let endLastYear = `${lastYear}-12-31`

            const get_bar_data = async () => {
                try {
                    setUnitDict(prev => ({ ...prev, w: props.mainCircuitW.unit === 'l.' ? "L" : 'ft³' }))
                    
                    let lastYearData = await API.getDigital(cancelToken, startLastYear, endLastYear, props.mainCircuitW.device_id, 60, props.mainCircuitW.channel_name, 'Water')
                    let thisYearData = await API.getDigital(cancelToken, startThisYear, endThisYear, props.mainCircuitW.device_id, 60, props.mainCircuitW.channel_name, 'Water')

                    let thisYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                    let lastYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                    for (let timestamp in lastYearData.data.series) {
                        let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                        lastYearBars[dateMonth] += lastYearData.data.series[timestamp]
                    }
                    for (let timestamp in thisYearData.data.series) {
                        let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                        thisYearBars[dateMonth] += thisYearData.data.series[timestamp]
                    }

                    let finalThisYear = []
                    let finalLastYear = []
                    let monthDict = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    for (let month of Object.keys(thisYearBars)) {
                        finalThisYear.push({
                            name: monthDict[month - 1],
                            y: thisYearBars[month],
                            label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                        })
                        finalLastYear.push({
                            name: monthDict[month - 1],
                            y: lastYearBars[month],
                            label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                        })
                    }

                    setWOptions([finalThisYear, finalLastYear])
                    context.setFixed30DayData(e => ({ ...e, w_bar: [finalThisYear, finalLastYear] }))

                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            }
            if (context.getFixed30DayData === null || context.getFixed30DayData.w_bar === null) {
                get_bar_data()
            } else {
                setWOptions(context.getFixed30DayData.w_bar)
            }
        } else {
            setWOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitW, context.getBuildingInfo])

    useEffect(() => {
        if (!props.mainCircuitG || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.g_channels.length !== 0) {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const lastYear = currentDate.getFullYear() - 1;
            let startThisYear = `${currentYear}-01-01`
            let endThisYear = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
            let startLastYear = `${lastYear}-01-01`
            let endLastYear = `${lastYear}-12-31`

            const get_bar_data = async () => {
                try {
                    
                    setUnitDict(prev => ({ ...prev, g: props.mainCircuitG.unit === 'l.' ? "L" : 'ft³' }))
                    let lastYearData = await API.getDigital(cancelToken, startLastYear, endLastYear, props.mainCircuitG.device_id, 60, props.mainCircuitG.channel_name, 'Natural Gas')
                    let thisYearData = await API.getDigital(cancelToken, startThisYear, endThisYear, props.mainCircuitG.device_id, 60, props.mainCircuitG.channel_name, 'Natural Gas')

                    let thisYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                    let lastYearBars = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }
                    for (let timestamp in lastYearData.data.series) {
                        let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                        lastYearBars[dateMonth] += lastYearData.data.series[timestamp]
                    }
                    for (let timestamp in thisYearData.data.series) {
                        let dateMonth = parseInt(timestamp.split(' ')[0].split("-")[1])
                        thisYearBars[dateMonth] += thisYearData.data.series[timestamp]
                    }

                    let finalThisYear = []
                    let finalLastYear = []
                    let monthDict = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    for (let month of Object.keys(thisYearBars)) {
                        finalThisYear.push({
                            name: monthDict[month - 1],
                            y: thisYearBars[month],
                            label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                        })
                        finalLastYear.push({
                            name: monthDict[month - 1],
                            y: lastYearBars[month],
                            label: `<b>${currentYear}: ${parseInt(thisYearBars[month]).toLocaleString()}</b><br/><b>${lastYear}: ${parseInt(lastYearBars[month]).toLocaleString()}</b>`
                        })
                    }

                    setGOptions([finalThisYear, finalLastYear])
                    context.setFixed30DayData(e => ({ ...e, g_bar: [finalThisYear, finalLastYear] }))

                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            }
            if (context.getFixed30DayData === null || context.getFixed30DayData.g_bar === null) {
                get_bar_data()
            } else {
                setGOptions(context.getFixed30DayData.g_bar)
            }
        } else {
            setGOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitG, context.getBuildingInfo])


    useEffect(() => {
        switch (type) {
            case 'e': default:
                if (eOptions === null) return
                setCurrentData(eOptions)
                break;

            case 'w':
                if (wOptions === null) return
                setCurrentData(wOptions)
                break;

            case 'g':
                if (gOptions === null) return
                setCurrentData(gOptions)
                break;
        }
    }, [type, eOptions, wOptions, gOptions, unitDict])

    const handleType = (e) => {
        setCurrentData(null)
        setType(e.currentTarget.value)
    }

    useEffect(()=>{
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: unitDict[type],
                align: 'left',
                font: {
                    size: 16,
                    weight: 'medium',
                },
    
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                crosshair: true,
                accessibility: {
                    description: 'Month'
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -10
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }    
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            tooltip: {
                formatter: function () {
                    return this.point.label
                },
            },
    
            series: [
                {
                    name: new Date().getFullYear() - 1,
                    data: currentData && currentData[1],
                    color: "#ADD4BE"
                },
                {
                    name: new Date().getFullYear(),
                    data: currentData && currentData[0],
                    color: hightContrast ? "#DD6B20" : "#619E7B"
                }
            ],
            exporting: { enabled: false }
        })
    }, [hightContrast, currentData, type, unitDict])

    return (
        <div className=''>
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">Monthly Usage</h2>
                            <div className="flex laptop:justify-center tablet:justify-start">
                                <div className='flex justify-end items-center'>
                                    <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed">Utility:</p>
                                </div>
                                <div className='flex justify-start '>
                                    <select value={type} onChange={handleType} className="outline-none mx-2 font-sans w-[134px] text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold">
                                        <option value='e'>Electricity</option>
                                        <option value='w'>Water</option>
                                        <option value='g'>Gas</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />
                        </div>
                        <div className='w-full flex flex-nowrap justify-end items-center cursor-pointer' onClick={() => setHighContrast(!hightContrast)}>
                            {
                                GlobalSVG.colorPalette()
                            }
                            <p className='my-4 mr-4 ml-2 text-[#F75D5F]'>High Contrast {hightContrast ? "Off" : ""}</p>
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}