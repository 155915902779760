import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import ToolTip from "../../components/ToolTip";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import moment from 'moment'

const ActuatorControl = () => {

    const unitDict = { 'l.': "L", "ft3": "ft³" }
    const positionDict = { 0: "Closed", 1: "Half", 2: "Partial", 3: "Open" }
    const location = useLocation();
    const [actuator, setActuator] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [currentStatus, setCurrentStatus] = useState(null)
    const [relays, setRelays] = useState({})
    const [channelInfo, setChannelInfo] = useState(null)
    const [actuatorData, setActuatorData] = useState({
        device_id: "",

    })
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const statusColorDict = { 0: "#B53838", 1: "#DCB052", 2: "#DCB052", 3: "#46775A" }
    const statusDict = { 0: "Closed", 1: "Half", 2: "Partial", 3: "Open" }
    const [options, setOptions] = useState({
        chart: {
            // backgroundColor: 'rgba(255, 255, 255, 0.7)',
            type: "line",
            zoomType: null,
            panning: false,
            panKey: null,
            height: 400
        },
        credits: {
            enabled: false,
        },
        navigator: {
            enabled: false
        },
        tooltip: {
            shared: true,
            useHTML: true,
            borderWidth: 1,
            borderColor: '#ccc',
            formatter: function (data) {
                let tooltipContent = '<div style="border-bottom: 1px solid #ccc;margin-bottom: 5px; padding-bottom: 5px;font-size:12px">Time Stamp: <b>' + Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + '</b></div>';

                // Loop through each series in the hovered category
                this.points.forEach(function (point) {
                    let tempName = point.series.name.split(" in ")[0]
                    let tempUnit = point.series.name.split(" in ")[1] || ""
                    tooltipContent += '<div style="color:' + point.color + ';font-size: 12px; padding-bottom: 5px">' + tempName + ': <b>' + point.y.toLocaleString() + " " + tempUnit + '</b></div>';
                });

                return tooltipContent;
            },
            positioner: function (boxWidth, boxHeight, point) {
                return { x: 50, y: 0 }; // Adjust the coordinates as needed
            },

        },
        legend: {
            enabled: false,
            align: 'left',
            layout: 'horizontal',
            verticalAlign: 'bottom',
            itemMarginBottom: 0,
            itemMarginTop: 0,
        },
        rangeSelector: {
            enabled: false
        },
        series: [],
        plotOptions: {
            series: {
                stacking: "overlap",
                dataGrouping: {
                    enabled: false
                },
                lineWidth: 1.5,
                color: "#cccccc",
            }
        },
        accessibility: {
            enabled: false
        },
        yAxis: [{
            lineColor: "#FFD44D",
            gridLineWidth: 0,
            lineWidth: 0.1,
            tickWidth: 0.1,
            opposite: false,
            visible: true,
            min: 0,
            max: 100,
            type: "linear",
            title: {
                align: 'high',
                offset: 0,
                rotation: 0,
                y: 10,
                style: {
                    color: "#000000"
                }
            },
            labels: {
                align: "left",
                style: {
                    color: "#000000"
                }
            }
        }],
        xAxis: [{
            type: 'datetime',
            tickInterval: 60 * 1000,
            minRange: 60 * 1000,
            tickPixelInterval: 100,
            ordinal: false,
            crosshair: true,
            visible: true,
            min: null,
            max: null,
            lineColor: '#000000',
            tickColor: '#000000',
            labels: {
                style: {
                    color: '#000000'
                }
            },
            internalType: "main",
            dateTimeLabelFormats: {
                second: '%d %b %Y <br/> %H:%M:%S %P',
            },
            zoomEnabled: false
        }],
    })

    useEffect(() => {
        if (!actuatorData || !actuatorData.channel_id) return
        const cancelToken = API.cancelToken();
        getReadings(cancelToken)

        const interval = setInterval(() => {
            getReadings(cancelToken)
        }, 60000);

        return () => {
            clearInterval(interval)
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actuatorData]);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["actuator_control"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/actuators')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (['put', 'post'].includes(getPutPost)) return
        if (location.pathname === '/actuator/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            let tempActuator = queryParams.get('actuator')
            if (!tempActuator) navigate('/settings/actuators')
            setGetPutPost('get')
            setActuator(tempActuator)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();

        if (getPutPost === 'get' && actuator !== null) {
            getCurrentStatus(actuator)
            setLoading(true)
            API.getActuator(cancelToken, actuator).then(res => {
                setLoading(false)
                setActuatorData(res.data)
                let portsDict = { 0: "input", 1: "output" }
                let positionDict = { 0: "closed", 1: "half", 2: "partial", 3: "open" }
                const chanPort = res.data.ports.reduce((acc, item) => {
                    acc[item.id] = item;
                    return acc;
                }, {});
                const tempRelays = res.data.positions.reduce((acc, item) => {
                    if (item.type === 1) {
                        acc[`${positionDict[item.actuator_status]}_${portsDict[item.type]}_${chanPort[item.actuator_channel_id].port_number}`] = item;
                    }
                    return acc;
                }, {});
                setRelays(tempRelays)
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A'
                });

            })
        }

        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, actuator, context.getUserInfo])

    const getCurrentStatus = async (cancelToken) => {
        try {
            if (!actuator) return
            if (!cancelToken) cancelToken = API.cancelToken();
            let currenStat = await API.getActuatorStatus(cancelToken, actuator)
            setCurrentStatus({ status: currenStat.data, timestamp: moment().format('YYYY-MM-DD HH:mm:ss') })
        } catch (e) {
            if (e.message === 'cancelling') return
            if (e.response && e.response.data) {
                Swal.fire({
                    title: 'Error.',
                    text: e.response.data,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            } else {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: "An error occurred, please contact us.",
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }


    const commandActuator = async (position) => {
        let pos_dict = {0: "Closed", 1: "Half", 3: "Open"}
        let result = await Swal.fire({
            title: 'Changing Actuator Status',
            text: `You are about to change the position of the actuator to ${pos_dict[position]}, continue?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        })
        if (result.isConfirmed) {
            API.comandActuator(actuator, position).then(res => {
                Swal.fire({
                    title: 'Sending command...',
                    text: 'Please wait while the operation completes.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                setTimeout(() => {
                    Swal.close();
                    getCurrentStatus()
                }, 5000);
            }).catch(err => {
                if (err.response && err.response.data) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                } else {
                    Swal.fire({
                        title: 'Oops!',
                        text: "An error occurred, please contact us.",
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            })
        } else {
            return false
        }
    }

    const getReadings = async (cancelToken) => {
        try {
            if (!actuatorData || !actuatorData.channel_id) return
            // TODO change device and channel id to variables
            let reduction = await API.getDigital(cancelToken, moment().subtract(1, 'days').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"), "ehm21120205", 1, "Water Meter", "Water")
            setChannelInfo(reduction.data.channel)
            let tempKeys = Object.keys(reduction.data.series).splice(-150)
            let series = tempKeys.reduce((acc, key, index) => {
                acc[key] = reduction.data.series[key];
                return acc;
            }, {});

            let tempArr = Object.values(series)
            tempArr[tempArr.length - 1] = {
                y: tempArr[tempArr.length - 1],
                marker: {
                    enabled: true,
                    fillColor: '#000000',
                    radius: 5
                },
                dataLabels: {
                    enabled: true,
                    format: tempArr[tempArr.length - 1].toFixed(1).toLocaleString() + unitDict[reduction.data.channel.input_unit],
                    align: 'left',
                    style: {
                        fontSize: '18px',
                        color: '#000000'
                    },
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    padding: 10,
                    verticalAlign: 'top',
                    alight: 'center',
                    x: -40,
                    y: 20
                }
            }
            for (let i = 0; i < 55; i++) {
                tempArr.push(null)
            }
            if (Object.keys(series).length === 0) return
            let tempOptions = JSON.parse(JSON.stringify(options))
            tempOptions.yAxis[0].unit = unitDict[reduction.data.channel.input_unit]
            tempOptions.yAxis[0].title.text = unitDict[reduction.data.channel.input_unit]
            tempOptions.yAxis[0].max = (Math.max(...Object.values(series).filter(value => value !== null))) * 1.8
            let startDate = moment.tz(Object.keys(series)[0].replaceAll(" ", "T"), "Etc/GMT")
            startDate = startDate.valueOf();
            let tempESeries = {
                data: tempArr,
                visible: true,
                pointStart: startDate,
                pointInterval: 60 * 1000,
                color: reduction.data.channel.utility_type === "Water" ? "#17AEEE" : '#DD6B20',
                fillColor: reduction.data.channel.utility_type === "Water" ? "#17AEEE" : '#DD6B20',
                lineWidth: 1,
                name: `Flow`,
                type: 'area',
                labels: {
                    style: {
                        color: '#000000'
                    }
                },
                yAxis: 0,
                xAxis: 0,
                internalId: 'e',
                toggle_checked: true,
                zIndex: 0
            }
            tempOptions.series = tempESeries
            setOptions(tempOptions)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    return (
        <div className='device min-w-[1230px]'>
            <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                    <Link to={queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
                        `/settings/actuators?building=${queryParams.get('building')}` : `/settings/actuators`}>
                        Actuators
                    </Link>
                    {
                        GlobalSVG.rightArrow()
                    }
                    <p className='font-medium'>
                        {actuatorData.name}
                    </p>
                    {loading &&
                        <Loader />}
                </div>
                <div className='device'>
                    <div className='min-h-[80vh]'>
                        <div className='card tablet:p-8 mobile:p-3 min-h-[70vh] mb-6'>
                            <div className='border-t border-[#D9D9D9] py-[40px] items-center'>
                                <div className='flex flex-nowrap w-full items-center justify-between'>
                                    <div>
                                        <div className='flex flex-nowrap items-center jusitfy-start'>
                                            <input
                                                placeholder='Actuator Name'
                                                className='text-3xl py-2 outline-none hide-input-background'
                                                type="text"
                                                name='name'
                                                disabled={true}
                                                defaultValue={actuatorData.name ? actuatorData.name : ""} />
                                            <ToolTip
                                                text={actuatorData.description}
                                                right="0"
                                                left="400%"
                                                top="0"
                                                width="300px"
                                                height="90px"
                                                textAlign="left"
                                                show={true}>
                                                <div className="ml-2">
                                                    {GlobalSVG.info('black', '15', '15')}
                                                </div>
                                            </ToolTip>
                                        </div>
                                        <p className='text-[18px] font-semibold text-[#7B7884]'>Control Interface</p>
                                    </div>
                                    <div className="flex flex-nowrap items-center justify-center">
                                        <div className="flex flex-nowrap items-center justify-center">
                                            <div className='cursor-pointer' onClick={getCurrentStatus}>{GlobalSVG.refresh()}</div>
                                            <div className='ml-[20px]'>
                                                <p className='text-[16px] font-semibold text-[#615E66]'>Last Status</p>
                                                <p className='text-[16px] text-[#7B7884]'>{currentStatus && currentStatus.timestamp}</p>
                                            </div>
                                        </div>
                                        <div className='ml-[20px]'>
                                            <p className='text-[16px] font-semibold text-[#615E66]'>Position</p>
                                            <p style={{ color: currentStatus ? statusColorDict[currentStatus.status] : "black" }} className='text-[16px]'>{currentStatus && statusDict[currentStatus.status]}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                loading &&
                                <Loader />
                            }
                            <div className='border-t border-[#D9D9D9]'>
                                <div className='flex flex-nowrap items-center justify-between p-[40px]'>
                                    <div onClick={() => commandActuator(3)} className='flex flex-nowrap justify-center items-center w-[200px] h-[56px] bg-[#38B543] text-[#fff] rounded cursor-pointer'>
                                        OPEN
                                    </div>
                                    <div onClick={() => 'half_output_1' in relays ? commandActuator(1) : null} style={{ backgroundColor: 'half_output_1' in relays ? "#DCB052" : "grey", cursor: 'half_output_1' in relays ? "pointer" : "default" }} className='flex flex-nowrap justify-center items-center w-[200px] h-[56px] text-[#fff] rounded cursor-pointer'>
                                        HALF
                                    </div>
                                    <div onClick={() => commandActuator(0)} className='flex flex-nowrap justify-center items-center w-[200px] h-[56px] bg-[#B53838] text-[#fff] rounded cursor-pointer'>
                                        CLOSE
                                    </div>
                                </div>
                            </div>
                            <div className='border-t border-[#D9D9D9] py-[40px]'>
                                <div className='flex flex-nowrap justify-center items-start'>
                                    <div className="w-1/2 relative pr-[30px] border-r border-[#D9D9D9]">
                                        <div className='w-full'>
                                            <p className='text-[20px] font-semibold ml-[10px]'>{channelInfo && channelInfo.channel_name}</p>
                                            <p className='text-[15px] font-semibold text-[#7B7884] mb-[15px] ml-[10px]'>Live Data</p>
                                            <HighchartsReact
                                                highcharts={Highcharts}
                                                constructorType={"stockChart"}
                                                options={options}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-auto pl-[30px]">
                                        <div className='w-auto'>
                                            <p className='text-[20px] font-semibold mb-[30px]'>Command Log</p>
                                            <div className="flex flex-nowrap items-center justify-start w-auto">
                                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Action Date</p>
                                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[140px]'>Performed By</p>
                                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>From</p>
                                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>To</p>
                                                <p className='text-[12px] font-semibold text-[#B8BBB9] w-[80px]'>Status</p>
                                            </div>
                                            <div className="overflow-auto max-h-[350px] h-[350px]">
                                                {actuatorData.logs && actuatorData.logs.map((e, ix) => (
                                                    <div key={ix + "actuatorlog"} className="flex flex-nowrap items-center justify-start py-[10px] border-b border-[#B8BBB9] w-auto">
                                                        <p className='text-[12px] w-[140px]'>{moment(e.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                                                        <p className='text-[12px] w-[140px]'>{e.created_by}</p>
                                                        <p className='text-[12px] w-[80px]'>{positionDict[e.from_position]}</p>
                                                        <p className='text-[12px] w-[80px]'>{positionDict[e.to_position]}</p>
                                                        <p className='text-[12px] w-[80px]'>{e.status === 200 ? "Success" : "Failed"}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ActuatorControl;