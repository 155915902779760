import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';

const Buildings = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext)
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_buildings_and_operators_list", "create_buildings_and_operators", "update_buildings_and_operators"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/map')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        setCurrentTab(location.pathname.split("/")[2])
    }, [location.pathname])

    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        API.getBuildings(cancelToken).then(res => {
            setLoading(false)
            let clientsDict = {}
            res.data.forEach(e => {
                clientsDict[e.client_name] = clientsDict[e.client_name] || []
                clientsDict[e.client_name].push(e)
            })
            setData(clientsDict)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A'
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])

    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[90vh]'>
            <div className='card tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='h-5/6 w-full border-light-green mt-8'>
                    <div className='h-[60vh] overflow-y-auto'>
                        {
                            loading === true ?
                                <Loader />
                                :
                                Object.keys(data).sort().map((i, ix) => (
                                    <div>
                                        <p className='mt-4 font-bold' key={i + "clientid"}>{i}</p>
                                       { data[i].map((e, index) => (
                                        <Link key={index + "tableitem"} className='w-full p-4 input-border flex flex-wrap' to={`/${currentTab}?building=${e.building_id}`}>
                                            <p>{e.building}</p>
                                        </Link>
                                        ))}
                                    </div>
                                ))
                        }
                    </div>
                </div>
                {currentTab !== 'ophours' && <Link to={`/building/create`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                    <span className='text-lg mr-2'>+</span>
                    Add new building
                </Link>}
            </div>
        </div>
    );
};

export default Buildings;