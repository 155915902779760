const CircuitParamLine = (props) => {
    return (<div  className="flex flex-nowrap items-center justify-between p-[10px] mb-[10px] bg-[#F3F3F3] w-full">
        <div className="flex flex-nowrap items-start justify-between w-1/2" >
            <div className="mr-[20px] w-[300px]" >
                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Dependent Circuit</p>
                <select
                    onChange={(e) => props.updateParams(e, props.param.tempId)}
                    name="channel_id"
                    className="text-[12px] font-semibold outline-none p-2 w-full "
                    disabled={!props.edit}
                    value={props.param && props.param.value && props.param.value.channel_id}>
                    <option value="">Select Circuit</option>
                    {
                        Object.values(props.equipmentList['0']).map((e, id) => (
                            <option key={id + e} value={e.channel_id}>{e.channel_name}</option>
                        ))
                    }
                </select>
            </div>
            <div className="mr-[20px] w-[300px]" >
                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Threshold</p>
                <input
                    key={props.idx + 'groupparam'}
                    type="number"
                    onChange={(e) => props.updateParams(e, props.param.tempId)}
                    name="threshold"
                    disabled={!props.edit}
                    className="text-[12px] font-semibold outline-none p-2 w-full "
                    value={props.param && props.param.value && props.param.value.threshold} />
            </div>
        </div>
        <div className="mr-[20px] w-1/2 flex flex-nowrap items-center justify-end" >
            {props.edit && props.activity.parameters && (props.activity.parameters.filter(e => e.type === 'circuit').length - 1 === props.idx) &&
                <div className="w-content py-[2px] text-[12px] px-2 bg-[#107048] text-[white] rounded-[4px] mr-[12px] cursor-pointer"
                    onClick={props.addCircuitParam}
                >+</div>}
            {props.edit && props.activity.parameters && (props.activity.parameters.filter(e => e.type === 'circuit').length > 1) &&
                <div className="w-content py-[2px] text-[12px] px-2 bg-[#107048] text-[white] rounded-[4px] mr-[12px] cursor-pointer"
                    onClick={() => props.removeCircuitParam(props.param.tempId)}
                >-</div>}
        </div>
    </div>)
}
export default CircuitParamLine