import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highcharts";
import { useRef, useEffect, useState } from 'react';
import Loader from "../Loader";

export default function AlertBarChart(props) {

    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const chartRef = useRef(null);

    useEffect(() => {
        if (!props.data) return;
    
        const tempData = Object.keys(props.data).map(item => ({
            name: item,
            y: props.data[item],
            tooltip: `<b>${item}: ${props.data[item]} alert</b>`
        }));
    
        tempData.sort((a, b) => b.y - a.y);
        const top5Data = tempData.slice(0, 5);
        setCurrentData(top5Data);
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);


    useEffect(() => {
        if (!currentData) return
        setOptions({
            chart: {
                type: 'bar',
                height: 200
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: null,
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                categories: currentData.map(item => item.name),
                crosshair: true,
            },
            yAxis: {
                min: 0,
                max: currentData.length > 0 ? Math.max(...currentData.map(item => item.y)) + 2 : 5,
                title: {
                    text: '',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -10
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            plotOptions: {
                bar: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            tooltip: {
                formatter: function (e) {
                    return `${this.point.category}: ${this.point.y} alerts`;
                },
            },
            series: [
                {
                    name: "",
                    data: currentData.map(item => item.y),
                    color: "#8f1ef8"
                }
            ],
            exporting: { enabled: false }
        });
    }, [currentData])

    return (
        <div className=''>
            {
                currentData ?
                    <div className="w-full">
                        <div className="">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}