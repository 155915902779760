import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';

const Factors = () => {

    const location = useLocation();
    const [currentTab, setCurrentTab] = useState()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const factors = ['electricity', 'gas', 'water', 'co']
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities", "update_utilities"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/roles')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (factors.includes(location.pathname.split("/")[1] === 'settings' &&
            !factors.includes(location.pathname.split("/")[3]))) {
            navigate('/settings/costsfactors/electricity')
        }
        setCurrentTab(location.pathname.split("/")[3])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();
        
        if (currentTab === 'electricity') {
            API.getModelsE(cancelToken).then(res => {
                setData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A'
                });

            }).finally(f => {
                setLoading(false)
            })
        }

        if (currentTab === 'water') {
            API.getModelsW(cancelToken).then(res => {
                setData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }

        if (currentTab === 'gas') {
            API.getModelsG(cancelToken).then(res => {
                setData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }

        if (currentTab === 'co') {
            API.getModelsCo(cancelToken).then(res => {
                setData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }


        return () => {
            API.cancel(cancelToken);
        }
    }, [currentTab, context.getUserInfo])

    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[90vh]'>
            <div className='card tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='flex flex-nowrap gap-8'>
                    <Link to='/settings/costsfactors/electricity'>
                        <p className={`font-normal text-base leading-5 pb-1 font-bold ${currentTab === 'electricity' ? 'active-factor' : 'inactive-factor'}`}>
                            Electricity
                        </p>
                    </Link>
                    <Link to='/settings/costsfactors/water'>
                        <p className={`font-normal text-base leading-5 pb-1 font-bold ${currentTab === 'water' ? 'active-factor' : 'inactive-factor'}`}>
                            Water
                        </p>
                    </Link>
                    <Link to='/settings/costsfactors/gas'>
                        <p className={`font-normal text-base leading-5 pb-1 font-bold ${currentTab === 'gas' ? 'active-factor' : 'inactive-factor'}`}>
                            Gas
                        </p>
                    </Link>
                    <Link to='/settings/costsfactors/co'>
                        <p className={`font-normal text-base leading-5 pb-1 font-bold ${currentTab === 'co' ? 'active-factor' : 'inactive-factor'}`}>
                            CO2
                        </p>
                    </Link>
                </div>
                <div className='h-5/6 w-full border-light-green mt-8'>
                    <div className='h-[60vh] overflow-y-auto'>
                        {
                            loading === true ?
                                <Loader />
                                :
                                data.map((e, index) => (
                                    <Link key={index + "tableitem"} className='w-full p-4 input-border flex flex-wrap' to={`/model/${currentTab}?model=${e.model_id}`}>
                                        <p>{e.model_name}</p>
                                    </Link>
                                ))
                        }
                    </div>
                </div>
                <Link to={`/model/create/${currentTab}`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                    <span className='text-lg mr-2'>+</span>
                    Add new model
                </Link>
            </div>
        </div>
    );
};

export default Factors;