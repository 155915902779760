import LoaderWheel from "../LoaderWheel";
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../utils/ContextProvider';
import API from "../../utils/API";
import Swal from 'sweetalert2';
import GlobalSVG from "../../utils/GlobalSVG";
import { Link } from "react-router-dom";

const MaintenanceActivityLine = (props) => {
    const context = useContext(AppContext);
    const [deleting, setDeleting] = useState(false)
    const [saving, setSaving] = useState(false)
    const [activity, setActivity] = useState()
    const [showNote, setShowNote] = useState(false)
    const [edit, setEdit] = useState(false)
    const statusColor = { 'Upcoming': '#107048', 'Due': '#F9941E', 'Set': '#B8BBB9', 'Overdue': '#F75D5F' }


    useEffect(() => {
        if (!props.activity) return
        setActivity(props.activity)
        if (props.adding) setEdit(true)
    }, [props])

    const handleSave = () => {
        
        setSaving(true)
        if (activity.channel_name && !activity.channel_id) activity.channel_id = props.equipmentList[activity.channel_name].channel_id
        if (activity.channel_id && !activity.channel_name) activity.channel_name = Object.values(props.equipmentList).find(e=>e.channel_id === parseInt(activity.channel_id)).channel_name
        if (props.adding) {
            activity.maintenance_id = props.maintenanceId
            API.createMaintenanceActivity(activity).then(async res => {
                res.data.channel_name = activity.channel_name
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === props.group.id) {
                        let activities = [...e.activities] || []
                        activities.push(res.data)
                        return { ...e, activities: JSON.parse(JSON.stringify(activities)) };
                    }
                    return e;
                });
                props.setGroups(updatedGroups);
                props.updateNumbers(updatedGroups)
                setEdit(false)
                props.setAdding(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        } else {
            let allowed = ["channel_id", "threshold", "tolerance", "notes", "timebase"]
            let payload = JSON.parse(JSON.stringify(activity))
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateMaintenanceActivity(payload, activity.id).then(async res => {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === activity.maintenance_id) {
                        let activities = e.activities.filter(e => e.id !== activity.id)
                        activities.push(activity)
                        return { ...e, activities: JSON.parse(JSON.stringify(activities)) };
                    }
                    else return e
                });
                props.setGroups(updatedGroups);
                props.updateNumbers(updatedGroups)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setSaving(false)
            })
        }
    }

    const updateActivity = (event) => {
        setActivity(e => ({ ...e, [event.target.name]: event.target.value }))
    }

    const handleDelete = (id) => {
        
        setDeleting(true)
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateMaintenanceActivity({ deleted: true }, id).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === props.group.id) {
                            let activities = e.activities.filter(e => e.id !== activity.id)
                            return { ...e, activities: JSON.parse(JSON.stringify(activities)) };
                        }
                        else return e
                    });
                    props.setGroups(updatedGroups);
                    props.updateNumbers(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(false)
            }
        });
    }

    return (
        <div>
            {
                !edit && showNote &&
                <div className="absolute top-[25vh] left-[25vw] w-[50vw] h-[50vh] z1000 overflow-auto bg-[#f9f9f9] p-[15px] rounded card">
                    <div className="w-full flex flex-nowrap justify-end cursor-pointer">
                        <div onClick={() => setShowNote(false)}>
                            {GlobalSVG.close('black')}
                        </div>
                    </div>
                    <div className="text-[#b9b9b9] overflow-auto">
                        {activity.notes ? activity.notes : "No notes."}
                    </div>
                </div>
            }
            {activity &&
                <div style={{ color: statusColor[activity.currentStatus] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full bg-[#ededed] rounded-md relative'>
                    <div className={`w-[130px] text-[12px] font-semibold`}>{activity.lastActionDate ? activity.lastActionDate : " - "}</div>
                    {edit && props.equipmentList && <select
                        onChange={updateActivity}
                        className={`w-[150px] text-[12px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit}
                        name="channel_id"
                        value={activity.channel_id && activity.channel_id}>
                        <option value=""> - </option>
                        {
                            Object.keys(props.equipmentList).map((e, id) => (
                                <option key={id + e} value={props.equipmentList[e].channel_id}>{e}</option>
                            ))
                        }
                    </select>}
                    {props.groupsList && <select
                        onChange={updateActivity}
                        className={`w-[150px] text-[12px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit}
                        name="type"
                        value={activity.type && activity.type}>
                        <option value=""> - </option>
                        {
                            Object.keys(props.groupsList).map((e, id) => (
                                <option key={id + e} value={e}>{e}</option>
                            ))
                        }
                    </select>}
                    {!edit && props.equipmentList && <Link className="text-left" to={`/circuit?device=${props.equipmentList[activity.channel_name].device_id}&circuit=${encodeURIComponent(activity.channel_name)}&tab=2`} target="_blank">
                        <div className="flex flex-nowrap justify-start items-center w-[150px]">
                            <p className={`text-[12px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap underline w-[135px]`}>
                                {activity.channel_name && activity.channel_name}
                            </p>
                            {GlobalSVG.edit2()}
                        </div>
                    </Link>}
                    <select
                        onChange={updateActivity}
                        className={`w-1/12 text-[12px] font-semibold overflow-hidden overflow-ellipsis whitespace-nowrap ${!edit ? "bg-[transparent]" : "bg-[white]"}`}
                        disabled={!edit}
                        name="timebase"
                        value={activity.timebase && activity.timebase}>
                        <option value=""> - </option>
                        <option value="runtime">Runtime</option>
                        <option value="days">Days</option>
                    </select>
                    <input
                        type="text"
                        onChange={updateActivity}
                        name="threshold"
                        disabled={!edit}
                        className="text-[12px] font-semibold w-[140px] outline-none hide-input-background"
                        defaultValue={activity.threshold && activity.threshold} />
                    <input
                        type="text"
                        onChange={updateActivity}
                        name="tolerance"
                        disabled={!edit}
                        className="text-[12px] font-semibold w-1/12 outline-none hide-input-background"
                        defaultValue={activity.tolerance && activity.tolerance} />
                    <div className={`w-[100px] text-[12px] font-semibold ${activity.dueIn || activity.dueIn === 0 ? "" : "pl-[25px]"}`}>{activity.dueIn || activity.dueIn === 0 ? parseInt(activity.dueIn).toLocaleString() + (activity.timebase === 'runtime' ? "h" : " days") : " - "}</div>
                    <div className={`w-[110px] text-[12px] font-semibold ${activity.percent ? "" : "pl-[25px]"}`}>{activity.percent ? parseInt(activity.percent * 100).toLocaleString() + "%" : " - "}</div>
                    <div className="">
                        <div className="flex flex-nowrap justify-end items-center">
                            {edit &&
                                (saving ?
                                    <LoaderWheel /> :
                                    <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[10px] rounded-[4px] mr-[12px] cursor-pointer" onClick={handleSave}>Save</div>)
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[21px] mr-[4px]' >
                                    <div onClick={() => setShowNote(true)}>{GlobalSVG.info('black')}</div>
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]' onClick={() => setEdit(true)}>
                                    {context.getUserInfo && context.getUserInfo.update_buildings_and_operators && GlobalSVG.edit('black')}
                                </div>
                            }
                            {
                                !edit &&
                                <div className='cursor-pointer w-[25px]'>
                                    {context.getUserInfo && context.getUserInfo.update_buildings_and_operators &&
                                        (deleting ?
                                            <LoaderWheel /> :
                                            <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(activity.id)}>{GlobalSVG.delete()}</div>)
                                    }
                                </div>
                            }
                        </div>

                    </div>

                </div>}
            {
                edit &&
                <textarea
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false"
                    type="text"
                    name="notes"
                    disabled={!edit}
                    placeholder="Notes"
                    className="text-sm text-[#332D41] mb-[10px] w-full outline-none hide-input-background p-[10px]"
                    defaultValue={activity.notes}
                    onChange={updateActivity}></textarea>
            }
        </div>
    )
}
export default MaintenanceActivityLine