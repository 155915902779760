import { useContext, useEffect, useMemo, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import { useLocation } from 'react-router-dom';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import Loader from "../../components/Loader";
import GlobalFuncs from '../../utils/GlobalFuncs';
import moment from 'moment';
import './style.css'
import AlertBarChart from "../../components/AlertBarChart";
import AlertGroups from "../../components/AlertGroups";
import AlertDetailsModal from "../../components/AlertDetailsModal";
import SlideToggle from '../../components/SlideToggle';
import AlertPieChart from "../../components/AlertPieChart";

const Alerts = () => {

    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [showDetails, setShowDetails] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [showCreate, setShowCreate] = useState(false)
    const [groups, setGroups] = useState([])
    const [rawGroups, setRawGroups] = useState(null)
    const [equipmentList, setEquipmentList] = useState(null)
    const [filteredGroups, setFilteredGroups] = useState([])
    const [frequencyList, setFrequencyList] = useState("equipmentFrequency")
    const [filters, setFilters] = useState({ isSubscribed: false })
    const [sorting, setSorting] = useState({ original: true })
    const [requestedGroup, setRequestedGroup] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alertTypes, setAlertTypes] = useState(null)
    const context = useContext(AppContext);
    const [groupSummary, setGroupSummary] = useState({
        severity: { 0: 0, 1: 0, 2: 0 },
        categories: {},
        daysOpen: { 0: { sumHours: 0, count: 0, closed: 0 }, 1: { sumHours: 0, count: 0, closed: 0 }, 2: { sumHours: 0, count: 0, closed: 0 } },
        equipmentFrequency: {},
    })
    // const [showBuildings, setShowBuildings] = useState(false)

    useEffect(() => {
        if (queryParams.get('building') === 'all') return
        context.setBuilding(queryParams.get('building'))
        let modalId = queryParams.get('group')
        if (queryParams.get('tsstart') && queryParams.get('tsend')) setFilters({ lastTriggered: [queryParams.get('tsstart'), queryParams.get('tsend')] })
        if (queryParams.get('clstart') && queryParams.get('clend')) setFilters({ closedAt: [queryParams.get('clstart'), queryParams.get('clend')] })
        setRequestedGroup(modalId)
        // setShowBuildings(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    useEffect(() => {
        if (!context.getBuildingInfo) return;
        let tempEquipmentE = context.getBuildingInfo.e_channels.filter(e => true)
        let finalDictE = {}
        tempEquipmentE.map(e => (
            finalDictE[e.id] = { channel_id: e.channel_id, channel_name: e.channel_name, device_id: e.device_id, poles: e.poles, source: e.source, circuit_type: e.circuit_type, virtual: e.virtual }
        ))

        let tempEquipmentG = context.getBuildingInfo.g_channels.filter(e => !e.virtual)
        let finalDictG = {}
        tempEquipmentG.map(e => (
            finalDictG[e.id] = { channel_id: e.id, channel_name: e.channel_name, device_id: e.device_id }
        ))

        let tempEquipmentW = context.getBuildingInfo.w_channels.filter(e => !e.virtual)
        let finalDictW = {}
        tempEquipmentW.map(e => (
            finalDictW[e.id] = { channel_id: e.id, channel_name: e.channel_name, device_id: e.device_id }
        ))

        let tempDevices = context.getBuildingInfo.devices.filter(e => e.device_type !== 'virtual')
        let finalDictD = {}
        tempDevices.map(e => (
            finalDictD[e.device_id] = { device_id: e.device_id, device_name: e.device_name }
        ))

        setEquipmentList({ 0: finalDictE, 1: finalDictG, 2: finalDictW, 3: finalDictD })

    }, [context.getBuildingInfo])


    useEffect(() => {
        if (!context.getBuildingInfo) return;
        const cancelToken = API.cancelToken()

        API.getAlertTypes(cancelToken).then(res => {
            setAlertTypes(Object.fromEntries(res.data.map(({ id, ...rest }) => [id, rest])))
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })
        API.getAlertGroups(cancelToken, context.getBuildingInfo.building_id).then(async res => {
            setRawGroups(res.data)
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuildingInfo])

    useEffect(() => {
        if (!rawGroups || !equipmentList) return
        let calculatedData = GlobalFuncs.getCalculatedAlertValues(rawGroups, context, filters.lastTriggered, filters.closedAt)
        setLoading(false)
        setGroups(calculatedData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawGroups, equipmentList, filters.lastTriggered, filters.closedAt])

    useEffect(() => {
        if (!context || !equipmentList || !groups) return
        let tempGroups = JSON.parse(JSON.stringify(groups))

        for (let field in filters) {
            if (filters[field] === null || ['lastTriggered', 'closedAt'].includes(field)) continue
            if (field === 'lastActionDate') {
                tempGroups = tempGroups.filter(group => moment(group.lastActionDate) >= moment(filters[field]))
            } else if (field === 'equipment') {
                tempGroups = tempGroups.filter(group => group.activities.map(activity => activity.channel_name).includes(filters[field]))
            } else if (['severity', 'alert_type_id', 'active'].includes(field)) {
                tempGroups = tempGroups.filter(group => group[field] === parseInt(filters[field]))
            } else if (['isSubscribed'].includes(field)) {
                if (filters[field]) tempGroups = tempGroups.filter(group => group[field] === filters[field])
            } else {
                tempGroups = tempGroups.filter(group => group[field].trim().toLowerCase() === filters[field].trim().toLowerCase())
            }
        }
        for (let field in sorting) {
            tempGroups.sort((a, b) => {
                if (a[field] === null && b[field] === null) return 0;
                if (a[field] === null) return sorting[field] ? 1 : -1;
                if (b[field] === null) return sorting[field] ? -1 : 1;

                switch (field) {
                    case 'lastTriggered':
                        return sorting[field]
                            ? moment(a[field]).diff(moment(b[field]))
                            : moment(b[field]).diff(moment(a[field]));
                    case 'alert_type_id':
                    case 'name':
                        return sorting[field]
                            ? a[field].localeCompare(b[field])
                            : b[field].localeCompare(a[field]);
                    case 'original':
                        if (a.openCount == null && b.openCount != null) return 1;
                        if (b.openCount == null && a.openCount != null) return -1;
                    
                        if (a.openCount && b.openCount && b.severity !== a.severity) return b.severity - a.severity;
                    
                        return b.openCount - a.openCount;
                    default:
                        return sorting[field]
                            ? a[field] - b[field]
                            : b[field] - a[field];
                }
            });
        }
        let calculatedData = GlobalFuncs.getCalculatedAlertValues(tempGroups, context)
        setFilteredGroups(calculatedData)
    }, [groups, filters, sorting, context, equipmentList])

    useEffect(() => {
        let tempGroupSummary = {
            severity: { 0: 0, 1: 0, 2: 0 },
            categories: {},
            daysOpen: { 0: { sumHours: 0, count: 0, closed: 0 }, 1: { sumHours: 0, count: 0, closed: 0 }, 2: { sumHours: 0, count: 0, closed: 0 } },
            equipmentFrequency: {},
            groupFrequency: {},
            typeFrequency: {},
            inactive: 0,
        }
        for (let group of filteredGroups) {
            tempGroupSummary.groupFrequency[group.name] = tempGroupSummary.groupFrequency[group.name] || 0
            tempGroupSummary.groupFrequency[group.name] += group.logs.length
            tempGroupSummary.typeFrequency[group.type_name] = tempGroupSummary.groupFrequency[group.type_name] || 0
            tempGroupSummary.typeFrequency[group.type_name] += group.logs.length
            if (!group.active) {
                tempGroupSummary.inactive += group.activities.length
            } else {
                tempGroupSummary.severity[group.severity] += group.activities.filter(e => e.active).length
                tempGroupSummary.inactive += group.activities.filter(e => !e.active).length
            }
            tempGroupSummary.categories[group.category] = tempGroupSummary.categories[group.category] || 0
            tempGroupSummary.categories[group.category] += group.activities.length
            tempGroupSummary.daysOpen[group.severity] = {
                sumHours: tempGroupSummary.daysOpen[group.severity].sumHours += group.daysOpen,
                count: tempGroupSummary.daysOpen[group.severity].count += group.triggerCount,
                closed: tempGroupSummary.daysOpen[group.severity].closed += group.logs.filter(e => !!e.closed_at).length
            }
            Object.keys(group.equipmentFrequency).forEach(e => {
                tempGroupSummary.equipmentFrequency[e] = (tempGroupSummary.equipmentFrequency[e] || 0) + group.equipmentFrequency[e]
            });
        }
        setGroupSummary(tempGroupSummary)
    }, [filteredGroups])

    useEffect(() => {
        if (!filteredGroups.length || !requestedGroup) {
            setShowDetails(null)
            return
        }
        setShowDetails(parseInt(requestedGroup))
    }, [filteredGroups, requestedGroup])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);

    return (
        <div className='h-[85vh]'>
            {/* <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/conservation?building=all`} className='active-reports-tab'>Building View</Link>
                    <Link to={`/conservation/portfolio`}>Portfolio View</Link>
                </div>
                <div className="relative flex flex-nowrap justify-center items-center w-3/12">
                    <div onClick={() => setShowBuildings(!showBuildings)} className='p-1 border border-2 rounded-[8px] transparent  border-[#E5E7EB] h-[43px] cursor-pointer flex flex-nowrap justify-between items-center w-10/12'>
                        <p className='font-sans font-normal text-sans text-[#C1C1C1]'>{context.getBuilding ? context.getBuilding : "Select building"}</p>
                        {showBuildings && GlobalSVG.sortArrowDesc('#C1C1C1')}
                        {!showBuildings && GlobalSVG.sortArrowAsc('#C1C1C1')}
                    </div>
                    <div className={`${showBuildings ? 'block' : 'hidden'} z-10 h-[300px] absolute laptop:top-[110%] left-0 tablet:top-[100%] mobile:top-[100%] w-full flex items-start justify-center `}>
                        <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-2 right-0'>
                            <Accordion onSelect={() => null} compact={true} linkTo='conservation' />
                        </div>
                    </div>
                </div>
            </div> */}
            {context.getBuilding ?
                <div>
                    <div className={`tablet:pl-12 tablet:pr-6 ${showDetails || showCreate ? 'blur-sm' : ''}`}>
                        <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[40px]'>
                            <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Alerting Summary</h2>
                            <SlideToggle titleClass="input-label-color" disabled={false} setOptions={setFilters} options={filters} id="isSubscribed" name="Subscribed Only" />
                        </div>
                        <div className='w-full'>
                            {
                                loading ?
                                    <div className='w-full h-[300px] flex flex-nowrap justify-center items-center'>
                                        <Loader />
                                    </div> :
                                    <div className='overflow-x-auto h-[325px]'>
                                        <div className="flex flex-nowrap justify-start items-center overflow-x-auto ">
                                            <div className='mr-[15px] min-w-[190px] w-[190px] h-[304px] bg-[white] rounded-md'>
                                                <div className="flex flex-nowrap justify-start items-center px-[30px] pt-[30px] mb-[10px]">
                                                    <p className='font-semibold'>Alerts <br />Set</p>
                                                </div>
                                                <div className="flex flex-nowrap items-center justify-between">
                                                    <div className="w-[190px] mt-4">
                                                        <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                            <p className='text-[16px] text-[#F75D5F] mr-2'>{groupSummary.severity[2] || 0}</p>
                                                            <p className='text-[12px] text-[#F75D5F]'>Critical</p>
                                                        </div>
                                                        <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                            <p className='text-[16px] text-[#F9941E] mr-2'>{groupSummary.severity[1] || 0}</p>
                                                            <p className='text-[12px] text-[#F9941E]'>Caution</p>
                                                        </div>
                                                        <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                            <p className='text-[16px] text-[#0538A0] mr-2'>{groupSummary.severity[0] || 0}</p>
                                                            <p className='text-[12px] text-[#0538A0]'>Info</p>
                                                        </div>
                                                        <div className="flex flex-nowrap justify-start items-center px-[30px] py-1">
                                                            <p className='text-[16px] text-[#C1C1C1] mr-2'>{groupSummary.inactive || 0}</p>
                                                            <p className='text-[12px] text-[#C1C1C1]'>Inactive</p>
                                                        </div>
                                                        <div className="flex flex-nowrap justify-start items-center px-[30px] py-4 bg-[#F2F8F5] mt-4">
                                                            <p className='text-[16px] text-[#000000] mr-2'>{Object.values(groupSummary.severity).reduce((a, c) => a + c, 0)}</p>
                                                            <p className='text-[12px] text-[#000000]'>Total</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mr-[15px] min-w-[350px] w-[350px] h-[305px] bg-[white] rounded-md'>
                                                <div className="flex flex-nowrap justify-start items-center">
                                                    <div className="pl-[30px] pt-[30px] mr-4">
                                                        {
                                                            GlobalSVG.alertTypes()
                                                        }
                                                    </div>
                                                    <p className='w-[180px] font-semibold pr-[30px] pt-[30px]'>Alert <br />By Category</p>
                                                </div>
                                                <div className=''>
                                                    <AlertPieChart data={groupSummary.categories} />
                                                </div>
                                            </div>
                                            <div className='mr-[15px] min-w-[320px] w-[320px] h-[305px] bg-[white] rounded-md'>
                                                <div className="flex flex-nowrap justify-start items-center">
                                                    <div className="pl-[30px] pt-[30px] mr-4">
                                                        {
                                                            GlobalSVG.alertClosing()
                                                        }
                                                    </div>
                                                    <p className='w-[150px] font-semibold pr-[30px] pt-[30px]'>Alert <br />Closing Time</p>
                                                </div>
                                                <div className='px-[30px] mt-[10px]'>
                                                    <div className="flex flex-nowrap justify-between items-center">
                                                        <div className="text-[#F75D5F] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]"></div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <p className="text-[10px] leading-[8px] text-[#B8BBB9] font-bold w-[60px]">Alerts <br />Triggered</p>
                                                        </div>
                                                        <div className="h-[20px] mt-[10px]"></div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <p className="text-[10px] leading-[8px] text-[#B8BBB9] font-bold w-[60px]">Alerts <br />Closed</p>
                                                        </div>
                                                        <div className="h-[20px] mt-[10px]"></div>
                                                        <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                            <p className="text-[10px] leading-[8px] text-[#B8BBB9] font-bold w-[60px]">Avg. <br />Days Open </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-nowrap justify-between items-center border-b border-1 border-[#B8BBB9] py-[7px]">
                                                        <div className="text-[#F75D5F] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">Critical</div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#F75D5F] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[2].count || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#F75D5F] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[2].closed || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] font-medium text-center text-[16px] leading-[18px] w-content">
                                                            <div className="text-[#F75D5F] text-[12px] font-semibold w-[60px]">{parseFloat(((groupSummary.daysOpen[2].sumHours / 24) / groupSummary.daysOpen[2].count || 0).toFixed(2))}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-nowrap justify-between items-center border-b border-1 border-[#B8BBB9] py-[7px]">
                                                        <div className="text-[#F9941E] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">Caution</div>
                                                        <div className="text-[black] font-medium text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#F9941E] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[1].count || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] font-medium text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#F9941E] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[1].closed || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] font-medium text-center text-[16px] leading-[18px] w-content">
                                                            <div className="text-[#F9941E] text-[12px] font-semibold w-[60px]">{parseFloat(((groupSummary.daysOpen[1].sumHours / 24) / groupSummary.daysOpen[1].count || 0).toFixed(2))}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-nowrap justify-between items-center border-b border-1 border-[#B8BBB9] py-[7px]">
                                                        <div className="text-[#0538A0] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">Info</div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#0538A0] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[0].count || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#0538A0] text-[12px] font-semibold w-[60px]">{groupSummary.daysOpen[0].closed || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] text-center text-[16px] leading-[18px] w-content">
                                                            <div className="text-[#0538A0] text-[12px] font-semibold w-[60px]">{parseFloat(((groupSummary.daysOpen[0].sumHours / 24) / groupSummary.daysOpen[0].count || 0).toFixed(2))}</div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-nowrap justify-between items-center py-[7px]">
                                                        <div className="text-[#000000] text-[12px] font-semibold flex flex-nowrap items-center w-[90px]">Total</div>
                                                        <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#000000] text-[12px] font-semibold w-[60px]">{Object.values(groupSummary.daysOpen).reduce((a, c) => a + c.count, 0) || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px]">
                                                            <div className="text-[#000000] text-[12px] font-semibold w-[60px]">{Object.values(groupSummary.daysOpen).reduce((a, c) => a + c.closed, 0) || 0}</div>
                                                        </div>
                                                        <div className="h-[20px] mt-[5px] border-r border-1 border-[#B8BBB9]"></div>
                                                        <div className="text-[black] text-[12px] font-medium text-center text-[16px] leading-[18px] w-content">
                                                            <div className="text-[#000000] text-[12px] font-semibold w-[60px]">{parseFloat(((Object.values(groupSummary.daysOpen).reduce((a, c) => a + c.sumHours, 0) / 24) / Object.values(groupSummary.daysOpen).reduce((a, c) => a + c.count, 0) || 0).toFixed(2))}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mr-[15px] min-w-[600px] w-[600px] h-[300px] bg-[white] rounded-md'>
                                                <div className="flex flex-nowrap justify-between items-center w-full">
                                                    <div className="flex flex-nowrap justify-start items-center w-full">
                                                        <div className="mb-[10px] pl-[30px] pt-[30px] mr-4">
                                                            {
                                                                GlobalSVG.alertCount()
                                                            }
                                                        </div>
                                                        <p className='w-[180px] font-semibold mb-[10px] pr-[30px] pt-[30px]'>Most Frequent Alerts</p>
                                                    </div>
                                                    <select name="mostFrequent" onChange={(e) => setFrequencyList(e.target.value)}>
                                                        <option value="equipmentFrequency">Monitoring Point</option>
                                                        <option value="typeFrequency">Alert Type</option>
                                                        <option value="groupFrequency">Alert Group</option>
                                                    </select>
                                                </div>
                                                <div className='px-[30px]'>
                                                    <AlertBarChart data={groupSummary[frequencyList]} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        <div className='flex tablet:flex-nowrap mobile:flex-wrap justify-between items-center my-[30px]'>
                            <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Alert Groups</h2>
                            <div className="flex flex-nowrap justify-end items-center">
                                <div className={`flex flex-nowrap justify-end items-center tablet:items-end gap-2.5 rounded-md`}>
                                    <div className={`relative flex laptop:justify-end laptop:items-start tablet:items-end flex-col multi-select`}>
                                        <div className="relative flex items-center justify-start text-sm text-normal text-[grey] cursor-pointer">
                                            <div onClick={() => setShowFilters(!showFilters)} className="w-full mx-3 flex flex-nowrap items-center justify-center">
                                                {GlobalSVG.filter()}
                                                <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2 mr-6'>Filter</p>
                                            </div>
                                            {
                                                showFilters &&
                                                <div className={`absolute top-[120%] right-[30px] w-[130px] h-[180px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, active: null }))} >
                                                        Active
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, severity: null }))} >
                                                        Severity
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, status: null }))} >
                                                        Status
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, alert_type_id: null }))} >
                                                        Type
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, name: null }))} >
                                                        Alert Group
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, equipment: null }))} >
                                                        Monitored Points
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, lastTriggered: null }))} >
                                                        Triggered At
                                                    </div>
                                                    <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => setFilters(e => ({ ...e, closedAt: null }))} >
                                                        Closed At
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {GlobalSVG.plus2()}
                                <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setShowCreate(true)}>Add Group</p>
                            </div>
                        </div>
                        {
                            loading ?
                                <div className='w-full h-[90px] flex flex-nowrap justify-center items-center'>
                                    <Loader />
                                </div> :
                                <AlertGroups
                                    sorting={sorting}
                                    setSorting={setSorting}
                                    setGroups={setGroups}
                                    filteredGroups={filteredGroups}
                                    groups={groups}
                                    equipmentList={equipmentList}
                                    filters={filters}
                                    alertTypes={alertTypes}
                                    setFilters={setFilters} />
                        }
                    </div>

                    <div>
                        {
                            (showDetails || showCreate) &&
                            <AlertDetailsModal
                                setGroups={setRawGroups}
                                setShowCreate={setShowCreate}
                                groups={groups}
                                equipmentList={equipmentList}
                                group={showDetails}
                                alertTypes={alertTypes}
                                create={showCreate ? true : false} />
                        }
                    </div>
                </div>
                :
                <div className='h-[70vh]'>
                    <p className='p-8 text-center'>Please select a building to begin.</p>
                </div>
            }
        </div >
    )

};

export default Alerts;