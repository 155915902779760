import API from '../../utils/API';
import './style.css'
import { useState, useEffect, useContext, useCallback } from 'react';
import Loader from "../Loader";
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import MultipleSelect from "../MultipleSelect";
import { AppContext } from "../../utils/ContextProvider";
import GraphFuncs from '../../utils/GraphFuncs';
import GlobalFuncs from '../../utils/GlobalFuncs';
import moment from 'moment';

export default function BreakerGraph(props) {

    const [options, setOptions] = useState()
    const [circuitOptions, setCircuitOptions] = useState()
    const [selectedCircuits, setSelectedCircuits] = useState()
    const [currentData, setCurrentData] = useState(null)
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext);


    useEffect(() => {
        if (!props.startEnd || !context.getBuilding) return
        setLoading(true)

        let mainCircuit = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)

        const getPeakBatchAsync = async () => {
            try {
                const cancelToken = API.cancelToken();
                
                let peak = await GraphFuncs.getPeakKva(cancelToken, mainCircuit, props.startEnd[0], props.startEnd[1])
                let data = {}
                if (Object.keys(peak)[0] !== 'null') {
                    let peakBatch = await API.getPeakBatch(cancelToken, context.getBuilding, Object.keys(peak)[0], 'a')
                    peakBatch = peakBatch.data
                    for (let circuit of context.getBuildingInfo.e_channels) {
                        data[circuit.channel_name] = { breaker: circuit.breaker_amps * circuit.poles, max: peakBatch[circuit.channel_name] ? peakBatch[circuit.channel_name] : 0 }
                    }
                }

                let sortedList = Object.keys(data)

                sortedList.sort(function (a, b) {
                    var itemA = data[a];
                    var itemB = data[b];
                    itemA = itemA.max && itemA.breaker ? itemA.max / itemA.breaker : 0
                    itemB = itemB.max && itemB.breaker ? itemB.max / itemB.breaker : 0
                    if (itemA > itemB) {
                        return -1;
                    }
                    if (itemA < itemB) {
                        return 1;
                    }
                    return 0;
                });

                let tempOptions = []
                let tempReached = []
                let tempRemainder = []
                for (let item of sortedList) {
                    if (!data[item].breaker) continue
                    tempOptions.push(item)
                    let r = parseFloat(((data[item].max / data[item].breaker) * 100).toFixed(2)) < 100 ? 100 - parseFloat(((data[item].max / data[item].breaker) * 100).toFixed(2)) : 0
                    tempReached.push({ name: item, y: parseFloat(((data[item].max / data[item].breaker) * 100).toFixed(2)), timestamp: data[item].timestamp, amps: parseFloat(data[item].max.toFixed(2)), breaker: data[item].breaker, color: parseFloat(((data[item].max / data[item].breaker) * 100).toFixed(2)) >= 80 ? "#BC302F" : "green" })
                    tempRemainder.push({ name: item, y: r, max: parseFloat(((data[item].max / data[item].breaker) * 100).toFixed(2)), timestamp: data[item].timestamp, amps: parseFloat(data[item].max.toFixed(2)), breaker: data[item].breaker })
                }

                setCircuitOptions(tempOptions)
                setSelectedCircuits(tempOptions)

                let reached = {
                    name: "% Reached",
                    data: tempReached,
                    dataLabels: {
                        enabled: false
                    }
                }
                let remainder = {
                    name: "Breaker Size",
                    data: tempRemainder,
                    color: "#D8D8D8",
                    dataLabels: {
                        enabled: false
                    }
                }
                setCurrentData([reached, remainder, []])


            } catch (e) {
                if (e.message === 'cancelling') return
                console.log("Failed to get Op hours")
                console.log(e);
            }
        }
        getPeakBatchAsync()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuilding, props.startEnd])


    useEffect(() => {
        if (!props.compare || (props.compare && !props.compareStartEnd) || !context.getBuilding || !currentData) return
        setLoading(true)

        const cancelToken = API.cancelToken();
        
        let mainCircuit = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)

        const getPeakBatchAsync = async () => {
            try {
                let peak = await GraphFuncs.getPeakKva(cancelToken, mainCircuit, props.compareStartEnd[0], props.compareStartEnd[1])
                let data = {}
                if (Object.keys(peak)[0] !== 'null') {
                    let peakBatch = await API.getPeakBatch(cancelToken, context.getBuilding, Object.keys(peak)[0], 'a')
                    peakBatch = peakBatch.data
                    for (let circuit of context.getBuildingInfo.e_channels) {
                        data[circuit.channel_name] = { breaker: circuit.breaker_amps * circuit.poles, max: peakBatch[circuit.channel_name] ? peakBatch[circuit.channel_name] : 0 }
                    }
                }

                let tempReached = []
                let tempRemainder = []
                for (let item of currentData[1].data) {
                    tempReached.push({ name: item.name, y: parseFloat(((item.amps / item.breaker) * 100).toFixed(2)), amps: item.amps, timestamp: item.timestamp, breaker: item.breaker, color: parseFloat(((item.amps / item.breaker) * 100).toFixed(2)) >= 80 ? "#BC302F" : "green" })
                    tempRemainder.push({ name: item.name, y: item.y, max: item.max, amps: item.amps, timestamp: item.timestamp, breaker: item.breaker })
                    let tempData = data[item.name] ? parseFloat(((data[item.name].max / data[item.name].breaker)).toFixed(2)) : 0
                    tempReached.push({ name: "Compare " + item.name, y: parseFloat((tempData * 100).toFixed(2)), amps: data[item.name] ? parseFloat(data[item.name].max.toFixed(2)) : 0, timestamp: data[item.name] ? data[item.name].timestamp : "", breaker: item.breaker, color: parseFloat((tempData * 100).toFixed(2)) >= 80 ? "#BC302F" : "green" })
                    let r2 = tempData && (tempData * 100 < 100) ? parseFloat(100 - (tempData * 100).toFixed(2)) : 0
                    tempRemainder.push({ name: "Compare " + item.name, y: r2, max: item.max, amps: data[item.name] ? parseFloat(data[item.name].max.toFixed(2)) : 0, timestamp: data[item.name] ? data[item.name].timestamp : "", breaker: item.breaker })
                }

                let reached = {
                    name: "Capacity Reached",
                    data: tempReached,
                    dataLabels: {
                        enabled: false
                    }
                }

                let remainder = {
                    name: "Breaker Limit",
                    data: tempRemainder,
                    color: "#D8D8D8",
                    dataLabels: {
                        enabled: false
                    }
                }
                setCurrentData([reached, remainder])

            } catch (e) {
                if (e.message === 'cancelling') return
                console.log("Failed to get Op hours")
                console.log(e);
            }
        }
        getPeakBatchAsync()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuilding, props.compareStartEnd, props.compare])


    useEffect(() => {
        if (!currentData || currentData.length === 0) return

        let series1 = JSON.parse(JSON.stringify(currentData[0]))
        let series2 = JSON.parse(JSON.stringify(currentData[1]))

        let temps1 = []
        let temps2 = []
        for (let i = 0; i < series1.data.length; i++) {
            if (selectedCircuits.includes(series1.data[i].name.replace("Compare ", ""))) {
                temps1.push(series1.data[i])
                temps2.push(series2.data[i])
            }
            if (series1.data[i].name.includes('Compare')) {
                temps1.push({ name: "!@#$DFWEG%T" + i, y: null })
                temps2.push({ name: "!@#$DFWEG%T" + i, y: null })
            }
        }

        series1.data = [...temps1]
        series2.data = [...temps2]

        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: false,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                type: 'category',
                max: currentData[1].data.length < 20 ? currentData[1].data.length - 1 : 20,
                accessibility: {
                    description: 'Circuits'
                },
                scrollbar: {
                    enabled: true
                },
                events: {
                    setExtremes: function (e) {
                        if (e.max >= this.dataMax || !e.max) {
                            return false
                        }
                        if (e.min < 1 && e.max < 1) return false
                    },
                    afterSetExtremes: function (e) {
                        if (e.min < 0 && e.max < 5) this.setExtremes(0, 5)
                        else if (e.min < 0) this.setExtremes(0, e.max)
                    }
                },
                labels: {
                    enabled: true, // Enable labels for other points
                    formatter: function (e) {
                        return `${e.value}`.includes("!@#$DFWEG%T") ? "" : e.value
                    },
                },
            },
            yAxis: {
                title: {
                    min: 0,
                    max: 2,
                    align: 'high',
                    offset: 0,
                    text: '',
                    rotation: 0,
                    y: 10,
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return `<b>${this.key}</b></br><b>Breaker Size: </b>${this.point.breaker.toLocaleString()}A</br><b>Peak Current: </b>${parseInt(this.point.amps).toLocaleString()}A<br/><b>% Reached: </b>${parseInt((this.point.amps / this.point.breaker) * 100).toLocaleString()}%`
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    stacking: 'normal',
                },
            },
            series: [series2, series1],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
                sourceWidth: 1500,
                sourceHeight: 1000,
                scale: 1,
                chartOptions: {
                    chart: {
                        spacingBottom: 100,
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.circle(30, this.chartHeight - 40, 6) // x, y, radius
                                    .attr({
                                        fill: '#008000'
                                    })
                                    .add();

                                renderer.text('Under 80%', 40, this.chartHeight - 37) // x, y
                                    .css({
                                        fontSize: '12px'
                                    })
                                    .add();

                                renderer.circle(120, this.chartHeight - 40, 6) // x, y, radius
                                    .attr({
                                        fill: '#BC302F'
                                    })
                                    .add();

                                renderer.text('Over 80%', 130, this.chartHeight - 37) // x, y
                                    .css({
                                        fontSize: '12px'
                                    })
                                    .add();
                            }

                        }
                    }
                }
            }
        })
        setLoading(false)
        props.setComplete(e => e + "3")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData, selectedCircuits])

    const handleSelectCircuits = (e) => {
        let val = e.target.value
        if (val === 'all') {
            setSelectedCircuits(circuitOptions)
            return
        }
        if (val === 'none') {
            setSelectedCircuits([])
            return
        }
        if (selectedCircuits.includes(val)) {
            setSelectedCircuits(prev => prev.filter(v => v !== val))
        } else {
            setSelectedCircuits(prev => [...prev, val])

        }
    }

    const handleReact = useCallback((chart) => {
        if (!chart || !chart.chart) return
        chart = chart.chart
        Highcharts.addEvent(chart, 'exportData', function (e) {
            if (e.dataRows[0].includes('Peak Current')) return
            e.dataRows[0].push('Peak Current');
            currentData[0].data.forEach((item, index) => {
                if (e.dataRows[index + 1]) e.dataRows[index + 1].push(item.amps);
            });
            currentData[1].data.forEach((item, index) => {
                if (e.dataRows[index + 1]) e.dataRows[index + 1][1] = item.breaker;
            });
        });
    }, [currentData]);

    return (
        <div className=''>
            {
                Array.isArray(currentData) && !loading ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <div>
                                <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">Breaker Capacity</h2>
                                {props.startEnd && <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>}
                                {props.compare && props.compareStartEnd.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compareStartEnd[0]).format("MMM DD, YYYY")} - {moment(props.compareStartEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compareStartEnd[0], props.compareStartEnd[1])} days)</span></p>}
                            </div>
                            <div className="flex laptop:justify-center tablet:justify-start">
                                <div>
                                    <div className=" grid grid-col justify-self-center">
                                        {
                                            circuitOptions && circuitOptions.length > 0 &&
                                            <MultipleSelect reports={true} data={circuitOptions} dataType='circuits' selectItem={handleSelectCircuits} selectedList={selectedCircuits} placeholder="Select Circuits" deselectAll={true} />
                                        }
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            {props.realPeak && <h2 className='mb-2 font-medium mt-4'>Building Peak</h2>}
                            {props.realPeak && <p>{props.compare && props.realPeakCompare ? parseInt(Object.values(props.realPeak)[0] - Object.values(props.realPeakCompare)[0]).toLocaleString() : parseInt(Object.values(props.realPeak)[0]).toLocaleString()} kVA</p>}
                            {props.realPeak && <h2 className='mb-2 font-medium mt-4'>Reached At</h2>}
                            {props.realPeak && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeak)[0])} - {Object.keys(props.realPeak)[0].split(" ")[1]}</p>}
                            {props.realPeak && props.compare && <h2 className='mb-2 font-medium mt-4'>And</h2>}
                            {props.realPeak && props.compare && props.realPeakCompare && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeakCompare)[0])} - {Object.keys(props.realPeakCompare)[0].split(" ")[1]}</p>}
                            <br />
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={handleReact}
                            />
                            <div className="flex flex-nowrap items-center">
                                <div className="flex flex-nowrap items-center">
                                    <div className="rounded-full bg-[green] w-3 h-3"></div>
                                    <p className='text-xs ml-2'>Under 80%</p>
                                </div>
                                <div className="flex flex-nowrap items-center ml-8">
                                    <div className="rounded-full bg-[#BC302F] w-3 h-3"></div>
                                    <p className='text-xs ml-2'>Over 80%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div >
    )
};