import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import Loader from "../../components/Loader";
import LoaderWheel from "../LoaderWheel";

const LinksModal = (props) => {

    const context = useContext(AppContext);
    const [loading, setLoading] = useState(true)
    const [links, setLinks] = useState([])
    const [adding, setAdding] = useState(false)
    const [deleting, setDeleting] = useState([])
    const [newLink, setNewLink] = useState({building_id: context.getBuildingInfo.building_id})
    const [creating, setCreating] = useState(false)
    
    useEffect(() => {
        if (!context.getBuildingInfo || !context.getUserInfo) return;
        const cancelToken = API.cancelToken();
        
        const base = 'https://greensense.ca'

        API.getLinks(cancelToken, context.getBuildingInfo.building_id).then(res => {
            let tempLinks = [
                { description: 'Electricity, Water & Gas Live Usage', scale: 'Building Level', link: `${base}/public/utilities/usage?id=${context.getBuildingInfo.building_id}` },
                { description: 'Electricity Usage & Category Breakdown', scale: 'Building Level', link: `${base}/public/electric/history?id=${context.getBuildingInfo.building_id}` },              
                { description: 'Emissions Reduction & Equivalencies (cars)', scale: 'Building Level', link: `${base}/public/emissions/building/car?id=${context.getBuildingInfo.building_id}` },
                { description: 'Emissions Reduction & Equivalencies (households)', scale: 'Building Level', link: `${base}/public/emissions/building/house?id=${context.getBuildingInfo.building_id}` },
                { description: 'Electrical Generation Historical', scale: 'Building Level', link: `${base}/public/generation/history?id=${context.getBuildingInfo.building_id}` },
                { description: 'Electrical Generation Total (cars)', scale: 'Building Level', link: `${base}/public/generation/total/car?id=${context.getBuildingInfo.building_id}` },
                { description: 'Electrical Generation Total (households)', scale: 'Building Level', link: `${base}/public/generation/total/house?id=${context.getBuildingInfo.building_id}` },
                { description: 'Water Reduction & Equivalencies (tub)', scale: 'Building Level', link: `${base}/public/water/building/tub?id=${context.getBuildingInfo.building_id}` },
                { description: 'Water Reduction & Equivalencies (pool)', scale: 'Building Level', link: `${base}/public/water/building/pool?id=${context.getBuildingInfo.building_id}` },
                { description: 'Emissions Reduction & Equivalencies (cars)', scale: 'Portfolio Level', link: `${base}/public/emissions/portfolio/car?id=${context.getBuildingInfo.client_id}` },
                { description: 'Emissions Reduction & Equivalencies (households)', scale: 'Portfolio Level', link: `${base}/public/emissions/portfolio/house?id=${context.getBuildingInfo.client_id}` },
                { description: 'Water Reduction & Equivalencies (tub)', scale: 'Portfolio Level', link: `${base}/public/water/portfolio/tub?id=${context.getBuildingInfo.client_id}` },
                { description: 'Water Reduction & Equivalencies (pool)', scale: 'Portfolio Level', link: `${base}/public/water/portfolio/pool?id=${context.getBuildingInfo.client_id}` },
                { description: 'Emissions Reduction & Equivalencies (cars)', scale: 'Greenwave Level', link: `${base}/public/emissions/greenwave/car?id=${context.getBuildingInfo.building_id}` },
                { description: 'Emissions Reduction & Equivalencies (households)', scale: 'Greenwave Level', link: `${base}/public/emissions/greenwave/house?id=${context.getBuildingInfo.building_id}` },
                { description: 'Water Reduction & Equivalencies (tub)', scale: 'Greenwave Level', link: `${base}/public/water/greenwave/tub?id=${context.getBuildingInfo.building_id}` },
                { description: 'Water Reduction & Equivalencies (pool)', scale: 'Greenwave Level', link: `${base}/public/water/greenwave/pool?id=${context.getBuildingInfo.building_id}` },
            ]
            tempLinks.push(...res.data)
            tempLinks = tempLinks.filter(e => !e.deleted)
            setLinks(tempLinks)
            setLoading(false)
        }).catch(e => {
            console.log(e)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        })
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getBuildingInfo, context.getUserInfo])

    const handleDelete = (id) => {
        
        setDeleting(e => [...e, id])
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateLink({ deleted: true }, id).then(async res => {
                    setLinks(e => e.filter(item => item.id !== id));
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(e => e.filter(item => item !== id))
            }
        });
    }

    const handleCreate = () => {
        
        setCreating(true)
        API.createLink(newLink).then(async res => {
            setLinks(e => [...e, res.data]);
            setCreating(false)
            setAdding(false)
        }).catch(e => {
            console.log(e)
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });
        }).finally(f => {
            setCreating(false)
        })

    }

    return (
        <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center' onClick={() => props.setShowLinks(false)}>
            <div className='card w-[600px] m-2 p-4 background-white rounded-md' onClick={(e) => { e.stopPropagation() }}>
                <div className='laptop:h-[400px] tablet:h-[400px] mobile:h-[350px] m-2'>
                    <h2 className="tablet:text-[36px] mobile:text-xl font-bold text-[#332D41] mb-[20px]">Public-Facing Screens</h2>
                    <p className='text-[12px]'>The following links can be copied and pasted into the screen management software used to display on screens within client buildings.</p>
                    <div className='flex flex-nowrap justify-between mt-4 mb-2 w-full'>
                        <div className='flex flex-nowrap justify-start w-1/4 text-[12px] text-[#B8BBB9] font-semibold ml-2'>
                            Description
                        </div>
                        <div className='flex flex-nowrap justify-start w-1/4 text-[12px] text-[#B8BBB9] font-semibold ml-2'>
                            Scale
                        </div>
                        <div className='flex flex-nowrap justify-start w-1/4 text-[12px] text-[#B8BBB9] font-semibold ml-2'>
                            Link
                        </div>
                        <div className='flex flex-nowrap justify-start w-1/4 text-[12px] text-[#B8BBB9] font-semibold ml-2'>
                        </div>
                    </div>
                    <div className="overflow-auto h-[220px]">
                        {loading ?
                            <Loader /> :
                            links.map(e => (
                                <div className='flex flex-nowrap justify-between py-[10px] w-full border-b border-[#B8BBB9]'>
                                    <div className='w-1/4 text-[12px] font-medium ml-2'>{e.description}</div>
                                    <div className='w-1/4 text-[12px] font-medium ml-2'>{e.scale}</div>
                                    <div className='w-1/4 text-[12px] font-medium ml-2 truncate'>
                                        <a href={e.link} target="_blank" rel="noreferrer">{e.link}</a>
                                    </div>
                                    <div className="w-1/4 pl-4 cursor-pointer" onClick={() => navigator.clipboard.writeText(e.link)}>
                                        <div className='flex flex-nowrap justify-start items-center'>
                                            <div className='flex flex-nowrap justify-center items-center bg-[#107048] w-[70px] rounded-[3px] mr-2'>
                                                {GlobalSVG.link()}
                                                <div className='text-[12px] font-medium text-[white] ml-2 font-medium'>Copy</div>
                                            </div>
                                            {context.getUserInfo.update_buildings_and_operators &&
                                                (deleting.includes(e.id) ?
                                                    <LoaderWheel /> :
                                                    <div className="cursor-pointer" onClick={() => handleDelete(e.id)}>
                                                        {e.id && GlobalSVG.delete()}
                                                    </div>)
                                            }

                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                    {
                        adding &&
                        <div className="flex flex-nowrap justify-between items-center">
                            <input className="text-[12px] p-1 mt-2 mr-2 rounded-[4px] border border-[#B8BBB9]" type="text" name="description" placeholder="Description" onChange={(event) => setNewLink(e => ({ ...e, [event.target.name]: event.target.value }))} />
                            <input className="text-[12px] p-1 mt-2 mr-2 rounded-[4px] border border-[#B8BBB9]" type="text" name="scale" placeholder="Scale" onChange={(event) => setNewLink(e => ({ ...e, [event.target.name]: event.target.value }))} />
                            <input className="text-[12px] p-1 mt-2 mr-2 rounded-[4px] border border-[#B8BBB9]" type="text" name="link" placeholder="Link" onChange={(event) => setNewLink(e => ({ ...e, [event.target.name]: event.target.value }))} />
                            {creating ?
                                <LoaderWheel /> :
                                <div className="text-[12px] mt-2 mr-2 font-semibold cursor-pointer" onClick={handleCreate}>Save</div>
                            }
                            {!creating && <div className="text-[12px] mt-2 font-semibold cursor-pointer text-[#F75D5F]" onClick={() => setAdding(false)}>Cancel</div>}
                        </div>
                    }
                    {!adding &&
                        <div className="w-full flex flex-nowrap justify-center items-center">
                            <div className="px-2 mt-4 flex flex-nowrap justify-center items-center border border-[#3F5A47] text-[#3F5A47] text-[12px] rounded-[4px] cursor-pointer"
                                onClick={() => setAdding(true)}>
                                + Add New Link
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default LinksModal;