import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExporting from 'highcharts/modules/exporting';
import './style.css'

export default function ReportPie1(props) {

    const context = useContext(AppContext);
    const [currentData, setCurrentData] = useState(null)
    const [options, setOptions] = useState(null)
    const [total, setTotal] = useState(0)
    const [selectedCat, setSelectedCat] = useState(null)
    const chartRef = useRef(null);
    HighchartsAccessibility(Highcharts);
    HighchartsExporting(Highcharts);
    let unitsDict = { kva: 'kVA', kwh: 'kWh' }

    useEffect(() => {
        if (!props.circuits || !context.getBuildingInfo || props.view === 'generation') return
        // let colors = ["#58826A", "#619E7B", "#75B08E", "#92C4A7", "#B2D8C3", "#377745", "#D2CECE", "#638971"]
        let colors = ['#58826A', '#DD6B20', '#A22', '#17AEEE', '#FFD233', '#BFDA45', '#F75D5F', '#A6ABAD']
        let colorIndex = 0

        let total = 0
        switch (props.unit) {
            case "price": default:
                total = props.totalPrice
                total = parseInt(total).toLocaleString()
                break
            case "kva":
                total = parseInt(Object.values(props.realPeak)[0]).toLocaleString()
                break
            case "kwh":
                total = Object.values(props.circuits).reduce((sum, value) => sum + value.kwh.on + value.kwh.off, 0)
                total = parseInt(total).toLocaleString()
                break
        }

        setTotal(total)

        let catDict = {}
        for (const key in props.circuits) {
            let item = props.circuits[key]
            catDict[item.category] = catDict[item.category] || 0
            switch (props.unit) {
                case "price": default:
                    catDict[item.category] += item.price.on.total + item.price.off.total
                    break
                case "kva":
                    catDict[item.category] += (item.realPeakOn ? item.kva.on : 0) + (!item.realPeakOn ? item.kva.off : 0)
                    break
                case "kwh":
                    catDict[item.category] += item.kwh.on + item.kwh.off
                    break
            }
        }

        let tempPieOpt = []
        for (const key in catDict) {
            if (['Source', 'Building Distribution', 'Capacitor Bank', null].includes(key)) continue;
            let tempObj = {}
            tempObj.name = key
            tempObj.y = parseInt(Math.round(catDict[key]))
            tempObj.color = colors[colorIndex]
            colorIndex++
            if (colorIndex === colors.length) colorIndex = 0
            tempPieOpt.push(tempObj)
        }

        setCurrentData(tempPieOpt)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits, context.getBuildingInfo, props.unit, props.view])


    useEffect(() => {
        if (!props.generationCircuits || !context.getBuildingInfo || props.view !== 'generation') return
        // let colors = ["#58826A", "#619E7B", "#75B08E", "#92C4A7", "#B2D8C3", "#377745", "#D2CECE", "#638971"]
        let colors = ['#58826A', '#DD6B20', '#A22', '#17AEEE', '#FFD233', '#BFDA45', '#F75D5F', '#A6ABAD']
        let colorIndex = 0

        let total = 0
        total = Object.values(props.generationCircuits).reduce((sum, value) => sum + value.kwh.on + value.kwh.off, 0)

        setTotal(total)

        let circuitDict = {}
        for (const key in props.generationCircuits) {
            let item = props.generationCircuits[key]
            circuitDict[key] = circuitDict[key] || 0
            circuitDict[key] += item.kwh.on + item.kwh.off
        }

        let tempPieOpt = []
        for (const key in circuitDict) {
            let tempObj = {}
            tempObj.name = key
            tempObj.y = parseInt(circuitDict[key])
            tempObj.color = colors[colorIndex]
            colorIndex++
            if (colorIndex === colors.length) colorIndex = 0
            tempPieOpt.push(tempObj)
        }

        setCurrentData(tempPieOpt)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generationCircuits, context.getBuildingInfo, props.unit, props.view])


    useEffect(() => {
        setOptions({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                formatter: function () {
                    const formattedY = Highcharts.numberFormat(this.point.y, 0, '.', ',');
                    return `${props.unit === 'price' ? "$" : ''}${formattedY}${props.unit !== 'price' ? unitsDict[props.unit] : ''} (${parseInt(this.point.percentage)}%)`;
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function (event) {
                                if (event.point.state === 'select') {
                                    props.filter([])
                                    setSelectedCat(null)
                                } else {
                                    props.filter([this.name])
                                    setSelectedCat({ [this.name]: this.y })
                                }
                            },
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: props.unit !== 'price' ? unitsDict[props.unit] : props.unit,
                colorByPoint: true,
                data: currentData
            }],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                formatter: function () {
                                    const formattedY = Highcharts.numberFormat(this.point.y, 0, '.', ',');
                                    return `${props.unit === 'price' ? "$" : ''}${formattedY}${props.unit !== 'price' ? unitsDict[props.unit] : ''} (${parseInt(this.point.percentage)}%)`;
                                }
                            },
                        },
                    },
                    chart: {
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.text(`Total: ${props.unit === 'price' ? "$" : ''}${total}${props.unit !== 'price' ? unitsDict[props.unit] : ''}`, 20, 20).add();
                            }
                    
                        }
                    }
                },
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData])

    return (
        <div className='' >
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <h2 className="capitalize w-full laptop:text-2xl tablet:text-xl font-bold text-[#332D41] md:decoration-dashed">Breakdown by Category {props.compare && "(Comparative)"}</h2>
                        </div>
                        <p>Total : {props.unit === 'price' ? "$" : ''}{total} {props.unit !== 'price' && unitsDict[props.unit]}</p>
                        {selectedCat && <p>{Object.keys(selectedCat)[0]} : {props.unit === 'price' ? "$" : ''}{parseInt(Object.values(selectedCat)[0]).toLocaleString()}{props.unit !== 'price' && unitsDict[props.unit]}</p>}
                        <div className="w-full">
                            {(Highcharts && currentData && chartRef) && <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />}
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}